<template>
  <div class="home-screen d-flex align-items-center justify-content-center">
    <div class="home-logo">
      <img src="@/assets/images/logo.svg" alt="">
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script  setup lang="ts">
import { ref, onMounted } from "vue";

import {useRouter} from "vue-router";

const router = useRouter();

onMounted(() => {
  const isReturningUser = localStorage.getItem('returningUser');
  if(isReturningUser){
    setTimeout(() => {
    router.push({ name: 'Buy Electricity' });
  }, 1800); 
    
  }
   else if(!isReturningUser){
    router.push('/landing-page');
  }

});
  


</script>

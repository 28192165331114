<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import landingNav from "@/components/Dashboard/LandingPageNav.vue"
const store = useStore();

const loading = ref(false);
const router = useRouter();

const meter_no = ref("");
const meter_number = ref("");

const errors = ref({
  meter_no: false,
  meter_number: false,
});
const eMsg = ref({
  meter_no: "Please enter your meter number",
  meter_number: "Please enter your meter number",
});
const verifyMeter = () => {
  if (meter_no.value == "") {
    errors.value.meter_no = true;
    loading.value = false;
    return;
  } else if (!meter_no.value.match(/^\d{11}$/)) {
    errors.value.meter_no = true;
    eMsg.value.meter_no = " Invalid Meter Number";
    loading.value = false;
    return;
  } else {
    errors.value.meter_no = false;
  }
  loading.value = true;

  localStorage.setItem("meter_no", meter_no.value);
  localStorage.setItem("returningUser", "true");
  router.push({ name: "Home" });
};
const verifyMeterNumber = () => {
  if (meter_number.value == "") {
    errors.value.meter_number = true;
    return;
  } else if (!meter_number.value.match(/^\d{11}$/)) {
    errors.value.meter_number = true;
    eMsg.value.meter_number = "Invalid Meter Number";
    return;
  } else {
    errors.value.meter_number = false;
  }
  loading.value = true;

  localStorage.setItem("meter_no", meter_no.value);
  localStorage.setItem("returningUser", "true");
  router.push({ name: "Home" });
};



onMounted(() => {
  const isReturningUser = localStorage.getItem("returningUser");
  if (isReturningUser) {
    router.push("/buy-energy-app");
  }
});
</script>

<template>
  <div class="l-wrapper">
   <landingNav></landingNav>
    <div class="all-section">
      <section class="my-2" id="home">
        
        <div class="home-section">
          <div class="content">
            <div class="hero-text">
              <h1 class="fw-bold">Minimum Energy,</h1>
              <h1 class="fw-bold">Maximum Productivity</h1>
              <p class="mt-4">
                Smarter, Cheaper, Cleaner Energy. Experience the convenience of
                buying electricity anytime, anywhere
              </p>
              <div class="mt-4">
                <label class="mb-3 text-secondary"
                  >Enter your meter number to buy electricity</label
                >
                <div class="c-flex">
                  <input
                    v-model="meter_no"
                    type="text"
                    class="bg-white me-3 form-control"
                    placeholder="Meter Number"
                  />
                  <div>
                    <button
                      @click.prevent="verifyMeter()"
                      class="nav-b btn-primary"
                      :disabled="loading"
                    >
                      {{ loading ? "Loading" : "Buy Electricity" }}
                    </button>
                  </div>
                </div>
                <div v-if="errors.meter_no" class="ms-3 error-msg">
                  {{ eMsg.meter_no }}
                </div>
              </div>
            </div>
            <div class="hero-images">
              <div class="hero-img">
                <img src="@/assets/images/phone.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="mt-3" id="about">
        <div class="about-section bg-white">
          <div class="content">
            <div class="hero-text">
              <h4 class="fw-bold">About Us</h4>
              <p class="fs-16 mt-4">
                At TAO Inc, our mission is to help our customers reduce energy
                while maximising productivity at work or at home.
              </p>
              <p class="fs-16 mt-4">
                We are passionate about helping our customers reduce
                costs—either currently expended in burning expensive fossil
                fuels or using physical energies, wherever possible.
              </p>
              <p class="fs-16 mt-4">
                At our core, we are effective altruists using the tools of
                commerce and technology to do the most good we can with our
                talents, resources and time
              </p>
            </div>
            <div class="abt-img">
              <div class="hero-img">
                <img src="@/assets/images/about.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="mt-3" id="whatweoffer">
        <div class="offer py-2 pad bg-white">
          <h4 class="fw-bold mb-3">What We Offer</h4>
          <div class="row g-4">
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="each-card">
                <div class="i-bg c-flex">
                  <Iconify
                    class="text-primary"
                    icon="streamline:database-server-2-solid"
                  />
                </div>
                <p class="fw-600 my-3">Energy Data</p>
                <span class="text-secondary"
                  >We prepare businesses, individuals and governments for the
                  energy revolution—smart grids, electric vehicles, hydrogen,
                  solar, storage.</span
                >
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="each-card">
                <div class="i-bg c-flex">
                  <Iconify
                    class="icons text-primary"
                    icon="mdi:home-lightbulb"
                    style="font-size: 26px"
                  />
                </div>
                <p class="fw-600 my-3">Smart Energy Devices/Appliance</p>
                <span class="text-secondary"
                  >We provide intelligent devices that not only track your
                  consumption, but also make prudent energy utilization
                  decisions on your behalf.</span
                >
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="each-card">
                <div class="i-bg c-flex">
                  <Iconify
                    class="text-primary"
                    style="color: #ff6600"
                    icon="icon-park:solar-energy"
                  />
                </div>
                <p class="fw-600 my-3">Solar and Storage Installation</p>
                <span class="text-secondary"
                  >We install and maintain solar and storage technologies, which
                  will save you money over the long term.</span
                >
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="each-card">
                <div class="i-bg c-flex">
                  <Iconify
                    class="icons text-primary"
                    style="font-size: 24px"
                    icon="ic:round-electric-car"
                  />
                </div>
                <p class="fw-600 my-3">Electric Vehicles</p>
                <span class="text-secondary"
                  >We prepare businesses, individuals and governments for the
                  energy revolution—smart grids, electric vehicles, hydrogen,
                  solar, storage.</span
                >
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
              <div class="each-card">
                <div class="i-bg c-flex">
                  <Iconify
                    class="icons text-primary"
                    style="font-size: 26px"
                    icon="mdi:head-flash"
                  />
                </div>
                <p class="fw-600 my-3">Advisory</p>
                <span class="text-secondary"
                  >We help our clients make sense of data and insights and
                  solutions for their business and public management goals</span
                >
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="mt-3" id="whychooseus">
        <div class="pad choose-section py-5 pad">
          <h4 class="fw-bold text-center text-white mb-3">Why Choose Us?</h4>
          <div class="choose-container">
            <div class="row g-3">
              <div class="col-lg-6 col-md-12">
                <div class="each-choose bg-white rounded">
                  <div class="choose-img">
                    <img src="@/assets/images/choose-1.png" alt="" />
                  </div>
                  <div class="">
                    <p class="fw-600 mb-3">Our Value Proposition</p>
                    <span class="text-secondary"
                      >We aim to not only satisfy but delight our customers by
                      being thorough in our service delivery and responsive to
                      our clients’ needs. We are big on research and innovation,
                      to provide you the best technology possible</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="each-choose bg-white rounded">
                  <div class="choose-img">
                    <img src="@/assets/images/choose-2.png" alt="" />
                  </div>
                  <div class="">
                    <p class="fw-600 mb-3">Innovation and Research</p>
                    <span class="text-secondary"
                      >We are dedicated to innovating on products and our
                      business model. Our goal is to lead the way in smart
                      energy devices and technologies across the automotive,
                      electricity and technology sectors</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="each-choose bg-white rounded">
                  <div class="choose-img">
                    <img src="@/assets/images/choose-3.png" alt="" />
                  </div>
                  <div class="">
                    <p class="fw-600 mb-3">Service Excellence</p>
                    <span class="text-secondary"
                      >We provide round the clock service availability. In
                      addition to deploying best in class technologies, we
                      provide full manufacturer warrantees and one year product
                      warrantees</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="each-choose bg-white rounded">
                  <div class="choose-img">
                    <img src="@/assets/images/choose-4.png" alt="" />
                  </div>
                  <div>
                    <p class="fw-600 mb-3">Continuous Improvement</p>
                    <span class="text-secondary"
                      >We continually improve on our technical abilities and
                      also listen to you for feedback on how we can improve our
                      services. We are committed to ending energy poverty for
                      good</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="last-page pad py-4">
          <div class="first-card c-flex mb-4">
            <div class="form-side">
              <h5 class="mb-4 fw-600">
                Buy Electricity instantly with TAO inc.
              </h5>
              <div class="">
                <label class="mb-3 text-secondary"
                  >Enter your meter number to buy electricity</label
                >
                <div class="buy-btns c-flex">
                  <input
                    v-model="meter_number"
                    type="text"
                    class="bg-white me-3 form-control"
                    placeholder="Meter Number"
                  />
                  <div class="buy-btn">
                    <button
                      @click.prevent="verifyMeterNumber()"
                      class="nav-b btn-primary"
                      :disabled="loading"
                    >
                      {{ loading ? "Loading" : "Buy Electricity " }}
                    </button>
                  </div>
                </div>
                <div v-if="errors.meter_number" class="ms-3 error-msg">
                  {{ eMsg.meter_number }}
                </div>
              </div>
            </div>
            <div class="image-side hero-images">
              <img src="@/assets/images/footer.png" alt="" />
            </div>
          </div>
          <div class="second-card">
            <div class="one py-4">
              <div class="border-bottom row">
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <div class="dab each-space">
                    <div class="logo my-4">
                      <img src="@/assets/images/logo.svg" alt="" />
                    </div>
                    <p>
                      We aim to simplify your life and boost productivity, at
                      home or at work.
                    </p>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-12">
                  <div class="dab foot-links">
                    <ul class="p-0 m-0">
                      <li class="my-3 f-link">
                        <a class="text-secondary" href="#home">Home</a>
                      </li>
                      <li class="my-3 f-link">
                        <a class="text-secondary" href="#about">
                          Why Choose Us</a
                        >
                      </li>
                      <li class="my-3 f-link">
                        <a class="text-secondary" href="#whatweoffer"
                          >What We Offer</a
                        >
                      </li>
                      <li class="my-3 f-link">
                        <a class="text-secondary" href="#whychooseus">
                          About Us</a
                        >
                      </li>
                      <li class="my-3 f-link">
                        <router-link class="text-secondary" to="/policy">
                          Pivacy Policy
                        </router-link>
                       
                      </li>
                      <li class="my-3 f-link">
                        <router-link class="text-secondary" to="/contact">
                          Contact Us
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12">
                  <div class="dab each-space">
                    <ul class="p-0">
                      <li class="my-3">
                        <Iconify
                          class="icons me-3 text-primary"
                          icon="zondicons:location"
                        />
                        <span>1 Nairobi Street, Wuse 2, Abuja</span>
                      </li>

                      <li class="my-3">
                        <Iconify
                          icon="ic:round-email"
                          class="text-primary icons me-3"
                        /><span> <a class="text-dark" href="info@tao.ng"> info@tao.ng</a> </span>
                      </li>
                      <li class="my-3">
                        <Iconify
                          icon="solar:phone-bold"
                          class="text-primary icons me-3"
                        /><span>+234 908 485 7430 | +13109013903 </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="two s-flex py-4 px-5">
              <div class="d-flex align-items-center social-icons">
                <div class="me-2 i-bg-pry">
                  <Iconify
                    style="font-size: 22px"
                    class="text-primary"
                    icon="mingcute:linkedin-fill"
                  />
                </div>
                <div class="me-2 i-bg-pry">
                  <Iconify
                    style="font-size: 16px"
                    icon="streamline:facebook-1-solid"
                    class="text-primary"
                  />
                </div>
                <div class="me-2 i-bg-pry">
                  <Iconify
                    style="font-size: 18px"
                    icon="streamline:instagram-solid"
                    class="icons text-primary"
                  />
                </div>
              </div>
              <p class="copyright text-secondary">Copyright 2024 TAO Energy</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style lang="scss"></style>

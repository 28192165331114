import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/logo.svg'


const _hoisted_1 = { class: "top" }
const _hoisted_2 = { class: "menu-items" }
const _hoisted_3 = { class: "each-item" }
const _hoisted_4 = { class: "each-item" }
const _hoisted_5 = { class: "bottom" }
const _hoisted_6 = { class: "menu-items mt-5" }
const _hoisted_7 = { class: "items each-item d-flex" }
const _hoisted_8 = {
  key: 0,
  class: "menu-body border-0 menu-items text-end"
}
const _hoisted_9 = { class: "each-item" }
const _hoisted_10 = { class: "each-item" }
const _hoisted_11 = { class: "each-item" }
const _hoisted_12 = { class: "each-item" }

import { computed, ref } from "vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'Sidebar',
  setup(__props) {

const store = useStore();
const menudrop = ref(false);

const toggleDropdown = () => {
  menudrop.value = !menudrop.value;
};

const showsidebar = computed(() => {
  return store.state.showsidebar;
});

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["sidebar-wrapper", showsidebar.value ? 'showSB' : 'noSB'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "brand" }, [
        _createElementVNode("div", { class: "brand-logo d-flex align-items-center justify-content-center" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          })
        ])
      ], -1)),
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["nav-link", { active: _ctx.$route.name === 'Buy Electricity' }]),
            to: "/buy-electricity"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Iconify, { icon: "ion:flash" }),
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "menu-text" }, "Buy Electricity", -1))
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _createElementVNode("li", _hoisted_4, [
          _createVNode(_component_router_link, {
            class: _normalizeClass(["nav-link", { active: _ctx.$route.name === 'Buy Solar' }]),
            to: "/track"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Iconify, { icon: "ph:solar-panel-light" }),
              _cache[2] || (_cache[2] = _createElementVNode("span", { class: "menu-text" }, "Buy Solar", -1))
            ]),
            _: 1
          }, 8, ["class"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("ul", _hoisted_6, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (toggleDropdown()), ["prevent"])),
          class: "c-flex"
        }, [
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "dropdown-toggle" }, null, -1)),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_router_link, {
              class: _normalizeClass(["nav-link", { active: _ctx.$route.name === 'My Account' }]),
              to: "#"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Iconify, { icon: "solar:user-linear" }),
                _cache[4] || (_cache[4] = _createElementVNode("span", { class: "menu-text" }, "My Account", -1))
              ]),
              _: 1
            }, 8, ["class"])
          ])
        ]),
        (menudrop.value)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
              _createElementVNode("li", _hoisted_9, [
                _createVNode(_component_router_link, {
                  class: _normalizeClass(["nav-link", { active: _ctx.$route.name === 'My Profile' }]),
                  to: "/my-profile"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Iconify, { icon: "solar:user-linear" }),
                    _cache[6] || (_cache[6] = _createElementVNode("span", { class: "menu-text" }, "My Profile", -1))
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _createElementVNode("li", _hoisted_10, [
                _createVNode(_component_router_link, {
                  class: _normalizeClass(["nav-link", { active: _ctx.$route.name === 'Beneficiary' }]),
                  to: "/beneficiary"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Iconify, { icon: "solar:users-group-rounded-linear" }),
                    _cache[7] || (_cache[7] = _createElementVNode("span", { class: "menu-text" }, "Beneficiary", -1))
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _createElementVNode("li", _hoisted_11, [
                _createVNode(_component_router_link, {
                  class: _normalizeClass(["nav-link", { active: _ctx.$route.name === 'Notifications' }]),
                  to: "/notifications"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Iconify, { icon: "lets-icons:bell-light" }),
                    _cache[8] || (_cache[8] = _createElementVNode("span", { class: "menu-text" }, "Notifications", -1))
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _createElementVNode("li", _hoisted_12, [
                _createVNode(_component_router_link, {
                  class: _normalizeClass(["nav-link", { active: _ctx.$route.name === 'Update Password' }]),
                  to: "/update-password"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Iconify, { icon: "mage:security-shield" }),
                    _cache[9] || (_cache[9] = _createElementVNode("span", { class: "menu-text" }, "Update Password", -1))
                  ]),
                  _: 1
                }, 8, ["class"])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}
}

})
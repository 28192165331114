import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vModelSelect as _vModelSelect, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "page-card d-flex justify-content-center" }
const _hoisted_2 = {
  key: 0,
  class: "account-contents"
}
const _hoisted_3 = { class: "mobile-screen" }
const _hoisted_4 = { class: "pb-3" }
const _hoisted_5 = { class: "profile-page" }
const _hoisted_6 = { class: "mt-3 mb-5 bg-gray border-gray rounded" }
const _hoisted_7 = { class: "desktop-screen" }
const _hoisted_8 = { class: "form-check form-switch d-flex align-items-center justify-content-between px-2" }
const _hoisted_9 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_10 = { class: "mobile-screen" }
const _hoisted_11 = { class: "form-check form-switch px-3" }
const _hoisted_12 = { class: "ms-5 my-4" }
const _hoisted_13 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_14 = {
  key: 0,
  class: "profile-page mt-3 pt-4 border-top"
}
const _hoisted_15 = { class: "row g-2" }
const _hoisted_16 = { class: "col-lg-6 col-sm-12" }
const _hoisted_17 = { class: "each-field mb-4" }
const _hoisted_18 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_19 = { class: "col-lg-6 col-sm-12" }
const _hoisted_20 = { class: "each-field mb-4" }
const _hoisted_21 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_22 = { class: "text-end mt-5" }
const _hoisted_23 = ["disabled"]
const _hoisted_24 = { key: 1 }
const _hoisted_25 = { class: "d-flex justify-content-center my-5" }
const _hoisted_26 = {
  class: "text-center",
  style: {"width":"400px"}
}

import MyAccount from "@/components/Dashboard/my-account.vue";
import NewUserLogin from "@/components/Modal/NewUserLogin.vue";
import LoginMob from "@/components/BottomSheet/CreateAccount/LoginMob.vue";
import {ref} from "vue";
import { useStore} from "vuex"
import { useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'notifications',
  setup(__props) {

const router= useRouter();
const store = useStore();
const saveReminder = ref(false);
const frequency = ref("");
const days = ref("");
const loading = ref(false);


const errors = ref({
  frequency: false,
  days:false,
  saveReminder : false,
})

const eMsg = ref({
  frequency: "This field is required",
  days:"This field is required",
  saveReminder:"This field is required",
})

const changeReminder = () => {
  saveReminder.value;
}

const submitNoti = () => {
  if(saveReminder.value == false){
    errors.value.saveReminder = true;
    return;
  }else {
    errors.value.saveReminder = false
  }

  if(frequency.value == ""){
    errors.value.frequency = true;
    return;
  }else {
    errors.value.frequency = false
  }

  if(days.value == ""){
    errors.value.days = true;
    return;
  }else {
    errors.value.days = false
  }
  loading.value = true;
  store.commit("setLoader", true);
  store.dispatch ("patch",{
    
    endpoint: "/user",
    details: {
      reminder: saveReminder.value,
      reminder_frequency: frequency.value,
      reminder_day: days.value,
    },
  } ).then((resp) => {
    store.commit("setLoader", false);
    loading.value = false;
      window.setTimeout(() => {
        window.location.reload();
      }, 1100);
  })
  .catch((err) => {
    loading.value = true;
    store.commit("setLoader", false);
  })
}


return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MyAccount),
    (_unref(store).state.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: "back-icon e-icon me-3",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(router).go(-1)), ["prevent"]))
              }, [
                _createVNode(_component_Iconify, { icon: "ep:arrow-left" })
              ])
            ])
          ]),
          _cache[16] || (_cache[16] = _createElementVNode("div", { class: "desktop-screen" }, [
            _createElementVNode("div", { class: "b-nav border-bottom" }, [
              _createElementVNode("h5", null, "Notifications")
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _cache[11] || (_cache[11] = _createElementVNode("p", null, "Reminder", -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _cache[8] || (_cache[8] = _createElementVNode("label", {
                    class: "form-check-label text-gray",
                    for: "flexSwitchCheckChecked"
                  }, " Enable notifications for electricity purchase reminders", -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((saveReminder).value = $event)),
                      class: "form-check-input",
                      style: {"height":"22px"},
                      type: "checkbox",
                      role: "switch",
                      id: "flexSwitchCheckChecked",
                      value: "false",
                      onChange: _cache[2] || (_cache[2] = ($event: any) => (changeReminder()))
                    }, null, 544), [
                      [_vModelCheckbox, saveReminder.value]
                    ])
                  ]),
                  (errors.value.saveReminder)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(eMsg.value.saveReminder), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", {
                    class: "form-check-label text-gray mb-4 d-block",
                    for: "flexSwitchCheckChecked"
                  }, " Enable notifications for electricity purchase reminders", -1)),
                  _createElementVNode("div", _hoisted_12, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((saveReminder).value = $event)),
                      class: "form-check-input",
                      style: {"height":"22px"},
                      type: "checkbox",
                      role: "switch",
                      id: "flexSwitchCheckChecked",
                      value: "false",
                      onChange: _cache[4] || (_cache[4] = ($event: any) => (changeReminder()))
                    }, null, 544), [
                      [_vModelCheckbox, saveReminder.value]
                    ])
                  ])
                ]),
                _cache[10] || (_cache[10] = _createElementVNode("div", null, null, -1)),
                (errors.value.saveReminder)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(eMsg.value.saveReminder), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          (saveReminder.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _cache[13] || (_cache[13] = _createElementVNode("label", {
                        for: "meter",
                        class: "mb-2"
                      }, [
                        _createTextVNode("Frequency"),
                        _createElementVNode("span", { class: "text-danger" }, "*")
                      ], -1)),
                      _createElementVNode("div", null, [
                        _withDirectives(_createElementVNode("select", {
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((frequency).value = $event)),
                          class: "form-select form-select-lg mb-3",
                          "aria-label": "Large select example"
                        }, _cache[12] || (_cache[12] = [
                          _createElementVNode("option", {
                            class: "px-2",
                            value: "",
                            disabled: ""
                          }, "select option", -1),
                          _createElementVNode("option", { value: "Weekely" }, "Weekly", -1),
                          _createElementVNode("option", { value: "Daily" }, "Daily", -1),
                          _createElementVNode("option", { value: "Monthly" }, "Monthly", -1)
                        ]), 512), [
                          [_vModelSelect, frequency.value]
                        ])
                      ]),
                      (errors.value.frequency)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(eMsg.value.frequency), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _cache[15] || (_cache[15] = _createElementVNode("label", {
                        for: "meter",
                        class: "mb-2"
                      }, [
                        _createTextVNode("What day of the week"),
                        _createElementVNode("span", { class: "text-danger" }, "*")
                      ], -1)),
                      _createElementVNode("div", null, [
                        _withDirectives(_createElementVNode("select", {
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((days).value = $event)),
                          class: "form-select form-select-lg mb-3",
                          "aria-label": "Large select example"
                        }, _cache[14] || (_cache[14] = [
                          _createStaticVNode("<option class=\"px-2\" value=\"\" disabled data-v-7102b814>select option</option><option value=\"Monday\" data-v-7102b814>Monday</option><option value=\"Tuesday\" data-v-7102b814>Tuesday</option><option value=\"Wednesday\" data-v-7102b814>Wednesday</option><option value=\"Thursday\" data-v-7102b814>Thursday</option><option value=\"Friday\" data-v-7102b814>Friday</option><option value=\"Saturday\" data-v-7102b814>Saturday</option><option value=\"Sunday\" data-v-7102b814>Sunday</option>", 8)
                        ]), 512), [
                          [_vModelSelect, days.value]
                        ])
                      ]),
                      (errors.value.days)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(eMsg.value.days), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("button", {
                      onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (submitNoti()), ["prevent"])),
                      disabled: loading.value,
                      class: "btn-primary px-3"
                    }, _toDisplayString(loading.value ? "Loading..." : "Save Update"), 9, _hoisted_23)
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, [
              _createVNode(_component_Iconify, {
                icon: "lets-icons:flash-light",
                style: {"font-size":"28px"}
              }),
              _cache[17] || (_cache[17] = _createStaticVNode("<h5 class=\"my-4\" data-v-7102b814>You have not signed in</h5><p class=\"p-text text-gray mb-4\" data-v-7102b814> Please create an account or sign in to have access to your account </p><div class=\"desktop-screen\" data-v-7102b814><div class=\"d-flex align-items-center justify-content-center\" data-v-7102b814><button data-bs-target=\"#exampleModalToggleB\" data-bs-toggle=\"modal\" class=\"btn-primary auth-btn me-3\" data-v-7102b814> Create an Account </button><button data-bs-target=\"#exampleModalToggleC\" data-bs-toggle=\"modal\" class=\"btn-primary auth-btn\" data-v-7102b814> Login </button></div></div><div class=\"mobile-screen\" data-v-7102b814><div class=\"d-flex align-items-center justify-content-center\" data-v-7102b814><button class=\"btn-primary auth-btn me-3\" data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottom10\" aria-controls=\"offcanvasBottom10\" data-v-7102b814> Create an Account </button><button data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottom11\" aria-controls=\"offcanvasBottom11\" class=\"btn-primary auth-btn\" data-v-7102b814> Login </button></div></div>", 4))
            ])
          ]),
          _createVNode(LoginMob),
          _createVNode(NewUserLogin)
        ]))
  ]))
}
}

})
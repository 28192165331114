import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, vModelSelect as _vModelSelect, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "offcanvas offcanvas-end right-sheet",
  tabindex: "-1",
  id: "offcanvasRight4",
  "aria-labelledby": "offcanvasRightLabel4"
}
const _hoisted_2 = { class: "offcanvas-header border-bottom py-4 d-block" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = {
  type: "button",
  class: "bg-white me-2",
  "data-bs-dismiss": "offcanvas",
  "aria-label": "Close"
}
const _hoisted_5 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_6 = { class: "office-icon d-flex align-items-center" }
const _hoisted_7 = { class: "offcanvas-body p-4" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { action: "" }
const _hoisted_10 = { class: "each-field mb-4" }
const _hoisted_11 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_12 = { class: "each-field mb-4" }
const _hoisted_13 = { class: "input-group mb-3" }
const _hoisted_14 = {
  class: "input-group-text",
  id: "inputGroup-sizing-default"
}
const _hoisted_15 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_16 = { class: "each-field mb-4" }
const _hoisted_17 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_18 = { class: "each-field mb-4" }
const _hoisted_19 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_20 = { class: "each-field mb-4" }
const _hoisted_21 = { class: "input-group mb-3" }
const _hoisted_22 = {
  class: "input-group-text",
  id: "basic-addon1"
}
const _hoisted_23 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_24 = { class: "d-grid mt-4" }
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { class: "steps d-flex justify-content-between align-items-center mb-3" }
const _hoisted_27 = { class: "d-flex align-items-center" }
const _hoisted_28 = { action: "" }
const _hoisted_29 = { class: "each-field mb-4" }
const _hoisted_30 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_31 = { class: "each-field mb-4" }
const _hoisted_32 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_33 = { class: "each-field mb-4" }
const _hoisted_34 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_35 = { class: "each-field mb-4" }
const _hoisted_36 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_37 = { class: "each-field mb-4" }
const _hoisted_38 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_39 = { class: "each-field mb-4" }
const _hoisted_40 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_41 = { class: "each-field mb-4" }
const _hoisted_42 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_43 = { class: "each-field mb-4" }
const _hoisted_44 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_45 = { class: "each-field mb-4" }
const _hoisted_46 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_47 = { class: "each-field mb-4" }
const _hoisted_48 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_49 = { class: "d-grid mt-4" }
const _hoisted_50 = {
  class: "offcanvas offcanvas-bottom full-size",
  tabindex: "-1",
  id: "offcanvasBottomE",
  "aria-labelledby": "offcanvasBottomLabelE"
}
const _hoisted_51 = { class: "offcanvas-header border-bottom py-4 d-block" }
const _hoisted_52 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_53 = { class: "d-flex align-items-center" }
const _hoisted_54 = {
  type: "button",
  class: "me-2 back-icon",
  "data-bs-dismiss": "offcanvas",
  "aria-label": "Close"
}
const _hoisted_55 = { class: "office-icon d-flex align-items-center" }
const _hoisted_56 = { class: "offcanvas-body p-4" }
const _hoisted_57 = { key: 0 }
const _hoisted_58 = { action: "" }
const _hoisted_59 = { class: "each-field mb-4" }
const _hoisted_60 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_61 = { class: "each-field mb-4" }
const _hoisted_62 = { class: "input-group mb-3" }
const _hoisted_63 = {
  class: "input-group-text",
  id: "inputGroup-sizing-default"
}
const _hoisted_64 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_65 = { class: "each-field mb-4" }
const _hoisted_66 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_67 = { class: "each-field mb-4" }
const _hoisted_68 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_69 = { class: "each-field mb-4" }
const _hoisted_70 = { class: "input-group mb-3" }
const _hoisted_71 = {
  class: "input-group-text",
  id: "basic-addon1"
}
const _hoisted_72 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_73 = { class: "d-grid mt-4" }
const _hoisted_74 = { key: 1 }
const _hoisted_75 = { class: "steps d-flex justify-content-between align-items-center mb-3" }
const _hoisted_76 = { class: "d-flex align-items-center" }
const _hoisted_77 = { action: "" }
const _hoisted_78 = { class: "each-field mb-4" }
const _hoisted_79 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_80 = { class: "each-field mb-4" }
const _hoisted_81 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_82 = { class: "each-field mb-4" }
const _hoisted_83 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_84 = { class: "each-field mb-4" }
const _hoisted_85 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_86 = { class: "each-field mb-4" }
const _hoisted_87 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_88 = { class: "each-field mb-4" }
const _hoisted_89 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_90 = { class: "each-field mb-4" }
const _hoisted_91 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_92 = { class: "each-field mb-4" }
const _hoisted_93 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_94 = { class: "each-field mb-4" }
const _hoisted_95 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_96 = { class: "each-field mb-4" }
const _hoisted_97 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_98 = { class: "d-grid mt-4" }

import { ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";


export default /*@__PURE__*/_defineComponent({
  __name: 'OfficeForm',
  setup(__props) {

const router = useRouter();
const $toast = useToast();
const store = useStore();
const loading = ref(false);
const phoneRegex = /^0(7[0]|8[0-1]|9[0-1])[0-9]{8}$/;
const decimalRegex = /^\d+(\.\d+)?$/;
const numbRegex = /^\d+$/;
const stepOne = ref(true);
const stepTwo = ref(false);

// const fieldRegex = /^0-9(1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-9])[0-9]{2}$/;

const office = reactive({
  companyName: "",
  companyAddress: "",
  contactPersonEmail: "",
  contactPersonName: "",
  type: "",
  contactPersonPhone: "",
  gridHours: "",
  gridCost: "",
  genSize: "",
  genType: "",
  monthlyFuel: "",
  inverter: "",
  nightPeakLoad: "",
  dayPeakLoad: "",
  dailyConsumption: "",
  rooms: "",
});

const errors = ref({
  companyName: false,
  companyAddress: false,
  contactPersonEmail: false,
  contactPersonName: false,
  contactPersonPhone: false,
  gridHours: false,
  gridCost: false,
  genSize: false,
  genType: false,
  monthlyFuel: false,
  inverter: false,
  nightPeakLoad: false,
  dayPeakLoad: false,
  dailyConsumption: false,
  rooms: false,
});

const eMsg = ref({
  companyName: "This field is required",
  companyAddress: "This field is required",
  contactPersonEmail: "This field is required",
  contactPersonName: "This field is required",
  contactPersonPhone: "This field is required",
  gridHours: "This field is required",
  gridCost: "This field is required",
  genSize: "This field is required",
  monthlyFuel: "This field is required",
  genType: "This field is required",
  inverter: "This field is required",
  nightPeakLoad: "This field is required",
  dayPeakLoad: "This field is required",
  dailyConsumption: "This field is required",
  rooms: "This field is required",
});

const handleStepOne = () => {
  stepOne.value = true;
  stepTwo.value = false;
};

const handleNextPage = () => {
  if (office.companyName == "") {
    errors.value.companyName = true;
    return;
  } else {
    errors.value.companyName = false;
  }

  if (office.companyAddress == "") {
    errors.value.companyAddress = true;
    return;
  } else {
    errors.value.companyAddress = false;
  }

  if (office.contactPersonEmail == "") {
    errors.value.contactPersonEmail = true;
    return;
  } else if (
    !office.contactPersonEmail.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.contactPersonEmail = true;
    eMsg.value.contactPersonEmail = "Invalid Email";
    return;
  } else {
    errors.value.contactPersonEmail = false;
  }

  if (office.contactPersonName == "") {
    errors.value.contactPersonName = true;
    return;
  } else {
    errors.value.contactPersonName = false;
  }

  if (office.contactPersonPhone == "") {
    errors.value.contactPersonPhone = true;
    return;
  } else if (!phoneRegex.test(office.contactPersonPhone)) {
    errors.value.contactPersonPhone = true;
    eMsg.value.contactPersonPhone = "Please Enter a valid Phone Number";
    return;
  } else {
    errors.value.contactPersonPhone = false;
  }

  stepOne.value = false;
  stepTwo.value = true;
};

const saveForm = () => {
  if (office.gridHours == "") {
    errors.value.gridHours = true;
    return;
  } else if (!numbRegex.test(office.gridHours)) {
    errors.value.gridHours = true;
    eMsg.value.gridHours = "Please enter numbers only";
    return;
  } else {
    errors.value.gridHours = false;
  }

  if (office.gridCost == "") {
    errors.value.gridCost = true;
    return;
  } else if (!numbRegex.test(office.gridCost)) {
    errors.value.gridCost = true;
    eMsg.value.gridCost = "Please enter numbers only";
    return;
  } else {
    errors.value.gridCost = false;
  }

  if (office.genSize == "") {
    errors.value.genSize = true;
    return;
  } else if (!decimalRegex.test(office.genSize)) {
    errors.value.genSize = true;
    eMsg.value.genSize = "Please enter numbers only";
    return;
  } else {
    errors.value.genSize = false;
  }

  if (office.genType == "") {
    errors.value.genType = true;
    return;
  } else {
    errors.value.genType = false;
  }

  if (office.monthlyFuel == "") {
    errors.value.monthlyFuel = true;
    return;
  } else if (!numbRegex.test(office.monthlyFuel)) {
    errors.value.monthlyFuel = true;
    eMsg.value.monthlyFuel = "Please enter numbers only";
    return;
  } else {
    errors.value.monthlyFuel = false;
  }

  if (office.inverter == "") {
    errors.value.inverter = true;
    return;
  } else if (!numbRegex.test(office.inverter)) {
    errors.value.inverter = true;
    eMsg.value.inverter = "Please enter numbers only";
    return;
  } else {
    errors.value.inverter = false;
  }

  if (office.nightPeakLoad == "") {
    errors.value.nightPeakLoad = true;
    return;
  } else if (!numbRegex.test(office.nightPeakLoad)) {
    errors.value.nightPeakLoad = true;
    eMsg.value.nightPeakLoad = "Please enter numbers only";
    return;
  } else {
    errors.value.nightPeakLoad = false;
  }

  if (office.dayPeakLoad == "") {
    errors.value.dayPeakLoad = true;
    return;
  } else if (!numbRegex.test(office.dayPeakLoad)) {
    errors.value.dayPeakLoad = true;
    eMsg.value.dayPeakLoad = "Please enter numbers only";
    return;
  } else {
    errors.value.dayPeakLoad = false;
  }

  if (office.dailyConsumption == "") {
    errors.value.dailyConsumption = true;
    return;
  } else if (!numbRegex.test(office.dailyConsumption)) {
    errors.value.dailyConsumption = true;
    eMsg.value.dailyConsumption = "Please enter numbers only";
    return;
  } else {
    errors.value.dailyConsumption = false;
  }

  if (office.rooms == "") {
    errors.value.rooms = true;
    return;
  } else if (!numbRegex.test(office.rooms)) {
    errors.value.rooms = true;
    eMsg.value.rooms = "Please enter numbers only";
    return;
  } else {
    errors.value.rooms = false;
  }

  store
    .dispatch("post", {
      endpoint: "/auditForm/office",
      details: {
        name: office.companyName,
        address: office.companyAddress,
        contact_name: office.contactPersonName,
        email: office.contactPersonEmail,
        phone: office.contactPersonPhone,
        rooms: office.rooms,
        gridCost: office.gridCost,
        gridHours: office.gridHours,
        gen: office.genType,
        genSize: office.genSize,
        inverter: office.inverter,
        fuel: office.monthlyFuel,
        dayPeakLoad: office.dayPeakLoad,
        nightPeakLoad: office.nightPeakLoad,
        dayConsumption: office.dailyConsumption,
      },
    })
    .then((resp) => {
      // console.log(resp);
      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
      useToast().success("Form Submitted Successfully");
    });
};

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", _hoisted_4, [
            _createVNode(_component_Iconify, { icon: "ep:back" })
          ]),
          _cache[36] || (_cache[36] = _createElementVNode("span", null, "Back", -1))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[38] || (_cache[38] = _createElementVNode("h5", { id: "offcanvasRightLabel4" }, "TAO Energy Audit Form", -1)),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Iconify, {
              icon: "fluent:building-bank-48-regular",
              style: {"color":"#818181"}
            }),
            _cache[37] || (_cache[37] = _createElementVNode("p", { class: "p-text text-gray ms-2" }, "Office", -1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (stepOne.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _cache[45] || (_cache[45] = _createElementVNode("div", { class: "steps d-flex justify-content-between align-items-center mb-3" }, [
                _createElementVNode("p", null, "Contact Person Information"),
                _createElementVNode("div", null, [
                  _createElementVNode("button", { class: "btn text-primary border-light rounded-pill" }, [
                    _createTextVNode(" Step"),
                    _createElementVNode("span", { class: "p" }, "1/2")
                  ])
                ])
              ], -1)),
              _createElementVNode("form", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _cache[39] || (_cache[39] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "name"
                  }, [
                    _createTextVNode("Company Name "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((office.companyName) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "e.g taoenergy"
                    }, null, 512), [
                      [_vModelText, office.companyName]
                    ])
                  ]),
                  (errors.value.companyName)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(eMsg.value.companyName), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _cache[40] || (_cache[40] = _createElementVNode("label", {
                    for: "meter",
                    class: "mb-2"
                  }, [
                    _createTextVNode("Comapany Address"),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("span", _hoisted_14, [
                      _createVNode(_component_Iconify, {
                        icon: "carbon:location",
                        class: "pe-2",
                        style: {"width":"30px","height":"30px","color":"#818181","border-right":"1px solid #c6c6c6"}
                      })
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((office.companyAddress) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "e.g smith Rd"
                    }, null, 512), [
                      [_vModelText, office.companyAddress]
                    ])
                  ]),
                  (errors.value.companyAddress)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(eMsg.value.companyAddress), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _cache[41] || (_cache[41] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "name"
                  }, [
                    _createTextVNode("Company Email "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((office.contactPersonEmail) = $event)),
                      type: "email",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "e.g Joedoe@gmail.com"
                    }, null, 512), [
                      [_vModelText, office.contactPersonEmail]
                    ])
                  ]),
                  (errors.value.contactPersonEmail)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(eMsg.value.contactPersonEmail), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _cache[42] || (_cache[42] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "name"
                  }, [
                    _createTextVNode("Contact Person Name "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((office.contactPersonName) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "e.g Joe Doe"
                    }, null, 512), [
                      [_vModelText, office.contactPersonName]
                    ])
                  ]),
                  (errors.value.companyName)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(eMsg.value.companyName), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _cache[44] || (_cache[44] = _createElementVNode("label", {
                    for: "meter",
                    class: "mb-2"
                  }, [
                    _createTextVNode(" Contact Phone Number "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_Iconify, {
                        icon: "twemoji:flag-nigeria",
                        style: {"width":"24px","height":"24x"}
                      }),
                      _cache[43] || (_cache[43] = _createElementVNode("span", {
                        class: "pe-2",
                        style: {"border-right":"1px solid gray"}
                      }, " +234", -1))
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((office.contactPersonPhone) = $event)),
                      type: "tel",
                      class: "form-control border-gray bg-gray",
                      placeholder: "081*******",
                      "aria-label": "Username",
                      "aria-describedby": "basic-addon1"
                    }, null, 512), [
                      [_vModelText, office.contactPersonPhone]
                    ]),
                    (errors.value.contactPersonPhone)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(eMsg.value.contactPersonPhone), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("button", {
                    onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (handleNextPage()), ["prevent"])),
                    type: "submit",
                    class: "border-gray rounded text-white btn-primary"
                  }, " Next ")
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (stepTwo.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("div", {
                    class: "back-icon",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (handleStepOne()))
                  }, [
                    _createVNode(_component_Iconify, { icon: "ep:arrow-left" })
                  ]),
                  _cache[46] || (_cache[46] = _createElementVNode("div", null, [
                    _createElementVNode("p", { class: "p-text" }, "Current Electricity Sources")
                  ], -1))
                ]),
                _cache[47] || (_cache[47] = _createElementVNode("div", null, [
                  _createElementVNode("button", { class: "btn text-primary border-light rounded-pill" }, [
                    _createTextVNode(" Step"),
                    _createElementVNode("span", { class: "p" }, "2/2")
                  ])
                ], -1))
              ]),
              _createElementVNode("form", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _cache[48] || (_cache[48] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Grid (hours) "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((office.gridHours) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.gridHours]
                    ])
                  ]),
                  (errors.value.gridHours)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_30, _toDisplayString(eMsg.value.gridHours), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _cache[49] || (_cache[49] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Estimated monthly grid cost"),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((office.gridCost) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.gridCost]
                    ])
                  ]),
                  (errors.value.gridCost)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_32, _toDisplayString(eMsg.value.gridCost), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_33, [
                  _cache[51] || (_cache[51] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Type of Generator "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((office.genType) = $event)),
                      class: "form-select form-select-lg",
                      "aria-label": "Large select example"
                    }, _cache[50] || (_cache[50] = [
                      _createElementVNode("option", {
                        class: "px-2",
                        value: "",
                        disabled: ""
                      }, "select option", -1),
                      _createElementVNode("option", { value: "diesel" }, "Diesel", -1),
                      _createElementVNode("option", { value: "pms" }, "PMS", -1),
                      _createElementVNode("option", { value: "lpg" }, "LPG", -1)
                    ]), 512), [
                      [_vModelSelect, office.genType]
                    ])
                  ]),
                  (errors.value.genSize)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_34, _toDisplayString(eMsg.value.genSize), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_35, [
                  _cache[52] || (_cache[52] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Generator size (KVA) "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((office.genSize) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.genSize]
                    ])
                  ]),
                  (errors.value.genSize)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_36, _toDisplayString(eMsg.value.genSize), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_37, [
                  _cache[53] || (_cache[53] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Estimated monthly generator fuel cost "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((office.monthlyFuel) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.monthlyFuel]
                    ])
                  ]),
                  (errors.value.monthlyFuel)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_38, _toDisplayString(eMsg.value.monthlyFuel), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_39, [
                  _cache[54] || (_cache[54] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Inverter (capacity - KVA) "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((office.inverter) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.inverter]
                    ])
                  ]),
                  (errors.value.inverter)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_40, _toDisplayString(eMsg.value.inverter), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_41, [
                  _cache[55] || (_cache[55] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Estimated daytime peak load in kW "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((office.dayPeakLoad) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.dayPeakLoad]
                    ])
                  ]),
                  (errors.value.dayPeakLoad)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_42, _toDisplayString(eMsg.value.dayPeakLoad), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_43, [
                  _cache[56] || (_cache[56] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Estimated night-time peak load in kW "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((office.nightPeakLoad) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.nightPeakLoad]
                    ])
                  ]),
                  (errors.value.nightPeakLoad)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_44, _toDisplayString(eMsg.value.nightPeakLoad), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_45, [
                  _cache[57] || (_cache[57] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Estimated daily Consumption in kWh "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((office.dailyConsumption) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.dailyConsumption]
                    ])
                  ]),
                  (errors.value.dailyConsumption)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_46, _toDisplayString(eMsg.value.dailyConsumption), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_47, [
                  _cache[58] || (_cache[58] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Number of rooms in the building "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((office.rooms) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.rooms]
                    ])
                  ]),
                  (errors.value.rooms)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_48, _toDisplayString(eMsg.value.rooms), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_49, [
                  _createElementVNode("button", {
                    onClick: _cache[17] || (_cache[17] = _withModifiers(($event: any) => (saveForm()), ["prevent"])),
                    type: "submit",
                    class: "btn-primary",
                    style: {"height":"44px"}
                  }, " Submit ")
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_50, [
      _createElementVNode("div", _hoisted_51, [
        _createElementVNode("div", _hoisted_52, [
          _createElementVNode("div", _hoisted_53, [
            _createElementVNode("button", _hoisted_54, [
              _createVNode(_component_Iconify, { icon: "ep:arrow-left" })
            ]),
            _cache[59] || (_cache[59] = _createElementVNode("h6", {
              class: "fs-16",
              id: "offcanvasBottomLabelE"
            }, "TAO Energy Audit Form", -1))
          ]),
          _createElementVNode("div", _hoisted_55, [
            _createVNode(_component_Iconify, {
              icon: "fluent:building-bank-48-regular",
              style: {"color":"#818181","font-size":"24px"}
            }),
            _cache[60] || (_cache[60] = _createElementVNode("p", { class: "p-text text-gray ms-2" }, "Office", -1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_56, [
        (stepOne.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
              _cache[67] || (_cache[67] = _createElementVNode("div", { class: "steps d-flex justify-content-between align-items-center mb-3" }, [
                _createElementVNode("p", null, "Contact Person Information"),
                _createElementVNode("div", null, [
                  _createElementVNode("button", { class: "btn text-primary border-light rounded-pill" }, [
                    _createTextVNode(" Step"),
                    _createElementVNode("span", { class: "p" }, "1/2")
                  ])
                ])
              ], -1)),
              _createElementVNode("form", _hoisted_58, [
                _createElementVNode("div", _hoisted_59, [
                  _cache[61] || (_cache[61] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "name"
                  }, [
                    _createTextVNode("Company Name "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((office.companyName) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "e.g taoenergy"
                    }, null, 512), [
                      [_vModelText, office.companyName]
                    ])
                  ]),
                  (errors.value.companyName)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_60, _toDisplayString(eMsg.value.companyName), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_61, [
                  _cache[62] || (_cache[62] = _createElementVNode("label", {
                    for: "meter",
                    class: "mb-2"
                  }, [
                    _createTextVNode("Comapany Address"),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", _hoisted_62, [
                    _createElementVNode("span", _hoisted_63, [
                      _createVNode(_component_Iconify, {
                        icon: "carbon:location",
                        class: "pe-2",
                        style: {"width":"30px","height":"30px","color":"#818181","border-right":"1px solid #c6c6c6"}
                      })
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((office.companyAddress) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "e.g smith Rd"
                    }, null, 512), [
                      [_vModelText, office.companyAddress]
                    ])
                  ]),
                  (errors.value.companyAddress)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_64, _toDisplayString(eMsg.value.companyAddress), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_65, [
                  _cache[63] || (_cache[63] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "name"
                  }, [
                    _createTextVNode("Company Email "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((office.contactPersonEmail) = $event)),
                      type: "email",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "e.g joedoe@gmail.com"
                    }, null, 512), [
                      [_vModelText, office.contactPersonEmail]
                    ])
                  ]),
                  (errors.value.contactPersonEmail)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_66, _toDisplayString(eMsg.value.contactPersonEmail), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_67, [
                  _cache[64] || (_cache[64] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "name"
                  }, [
                    _createTextVNode("Contact Person Name "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((office.contactPersonName) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "e.g Joe Doe"
                    }, null, 512), [
                      [_vModelText, office.contactPersonName]
                    ])
                  ]),
                  (errors.value.companyName)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_68, _toDisplayString(eMsg.value.companyName), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_69, [
                  _cache[66] || (_cache[66] = _createElementVNode("label", {
                    for: "meter",
                    class: "mb-2"
                  }, [
                    _createTextVNode(" Contact Phone Number "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", _hoisted_70, [
                    _createElementVNode("div", _hoisted_71, [
                      _createVNode(_component_Iconify, {
                        icon: "twemoji:flag-nigeria",
                        style: {"width":"24px","height":"24x"}
                      }),
                      _cache[65] || (_cache[65] = _createElementVNode("span", {
                        class: "pe-2",
                        style: {"border-right":"1px solid gray"}
                      }, " +234", -1))
                    ]),
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((office.contactPersonPhone) = $event)),
                      type: "tel",
                      class: "form-control border-gray bg-gray",
                      placeholder: "081*******",
                      "aria-label": "Username",
                      "aria-describedby": "basic-addon1"
                    }, null, 512), [
                      [_vModelText, office.contactPersonPhone]
                    ])
                  ]),
                  (errors.value.contactPersonPhone)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_72, _toDisplayString(eMsg.value.contactPersonPhone), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_73, [
                  _createElementVNode("button", {
                    onClick: _cache[23] || (_cache[23] = _withModifiers(($event: any) => (handleNextPage()), ["prevent"])),
                    type: "submit",
                    class: "border-gray rounded btn-primary",
                    style: {"height":"44px","color":"white"}
                  }, " Next ")
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (stepTwo.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_74, [
              _createElementVNode("div", _hoisted_75, [
                _createElementVNode("div", _hoisted_76, [
                  _createElementVNode("div", {
                    class: "back-icon",
                    onClick: _cache[24] || (_cache[24] = ($event: any) => (handleStepOne()))
                  }, [
                    _createVNode(_component_Iconify, { icon: "ep:arrow-left" })
                  ]),
                  _cache[68] || (_cache[68] = _createElementVNode("div", null, [
                    _createElementVNode("p", { class: "p-text" }, "Current Electricity Sources")
                  ], -1))
                ]),
                _cache[69] || (_cache[69] = _createElementVNode("div", null, [
                  _createElementVNode("button", { class: "btn text-primary border-light rounded-pill" }, [
                    _createTextVNode(" Step"),
                    _createElementVNode("span", { class: "p" }, "2/2")
                  ])
                ], -1))
              ]),
              _createElementVNode("form", _hoisted_77, [
                _createElementVNode("div", _hoisted_78, [
                  _cache[70] || (_cache[70] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Grid (hours) "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((office.gridHours) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.gridHours]
                    ])
                  ]),
                  (errors.value.gridHours)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_79, _toDisplayString(eMsg.value.gridHours), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_80, [
                  _cache[71] || (_cache[71] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Estimated monthly grid cost"),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((office.gridCost) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.gridCost]
                    ])
                  ]),
                  (errors.value.gridCost)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_81, _toDisplayString(eMsg.value.gridCost), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_82, [
                  _cache[73] || (_cache[73] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Type of Generator "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("select", {
                      "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((office.genType) = $event)),
                      class: "form-select form-select-lg",
                      "aria-label": "Large select example"
                    }, _cache[72] || (_cache[72] = [
                      _createElementVNode("option", {
                        class: "px-2",
                        value: "",
                        disabled: ""
                      }, "select option", -1),
                      _createElementVNode("option", { value: "diesel" }, "Diesel", -1),
                      _createElementVNode("option", { value: "pms" }, "PMS", -1),
                      _createElementVNode("option", { value: "lpg" }, "LPG", -1)
                    ]), 512), [
                      [_vModelSelect, office.genType]
                    ])
                  ]),
                  (errors.value.genSize)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_83, _toDisplayString(eMsg.value.genSize), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_84, [
                  _cache[74] || (_cache[74] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Generator size (KVA) "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((office.genSize) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.genSize]
                    ])
                  ]),
                  (errors.value.genSize)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_85, _toDisplayString(eMsg.value.genSize), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_86, [
                  _cache[75] || (_cache[75] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Estimated monthly generator fuel cost "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((office.monthlyFuel) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.monthlyFuel]
                    ])
                  ]),
                  (errors.value.monthlyFuel)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_87, _toDisplayString(eMsg.value.monthlyFuel), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_88, [
                  _cache[76] || (_cache[76] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Inverter (capacity - KVA) "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((office.inverter) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.inverter]
                    ])
                  ]),
                  (errors.value.inverter)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_89, _toDisplayString(eMsg.value.inverter), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_90, [
                  _cache[77] || (_cache[77] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Estimated daytime peak load in kW "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((office.dayPeakLoad) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.dayPeakLoad]
                    ])
                  ]),
                  (errors.value.dayPeakLoad)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_91, _toDisplayString(eMsg.value.dayPeakLoad), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_92, [
                  _cache[78] || (_cache[78] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Estimated night-time peak load in kW "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((office.nightPeakLoad) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.nightPeakLoad]
                    ])
                  ]),
                  (errors.value.nightPeakLoad)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_93, _toDisplayString(eMsg.value.nightPeakLoad), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_94, [
                  _cache[79] || (_cache[79] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Estimated daily Consumption in kWh "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((office.dailyConsumption) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.dailyConsumption]
                    ])
                  ]),
                  (errors.value.dailyConsumption)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_95, _toDisplayString(eMsg.value.dailyConsumption), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_96, [
                  _cache[80] || (_cache[80] = _createElementVNode("label", {
                    class: "mb-2",
                    for: "meter"
                  }, [
                    _createTextVNode("Number of rooms in the building "),
                    _createElementVNode("span", { class: "text-danger" }, "*")
                  ], -1)),
                  _createElementVNode("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((office.rooms) = $event)),
                      type: "text",
                      class: "form-control border-gray bg-gray mb-2",
                      placeholder: "Your answer"
                    }, null, 512), [
                      [_vModelText, office.rooms]
                    ])
                  ]),
                  (errors.value.rooms)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_97, _toDisplayString(eMsg.value.rooms), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_98, [
                  _createElementVNode("button", {
                    onClick: _cache[35] || (_cache[35] = _withModifiers(($event: any) => (saveForm()), ["prevent"])),
                    type: "submit",
                    class: "btn-primary",
                    style: {"height":"44px"}
                  }, " Submit ")
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}
}

})
<script lang="ts" setup>
import { ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";

const router = useRouter();
const $toast = useToast();
const store = useStore();
const loading = ref(false);
const phoneRegex = /^0(7[0]|8[0-1]|9[0-1])[0-9]{8}$/;
const decimalRegex = /^\d+(\.\d+)?$/;
const numbRegex = /^\d+$/;
const stepOne = ref(true);
const stepTwo = ref(false);

// const fieldRegex = /^0-9(1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-9])[0-9]{2}$/;

const office = reactive({
  companyName: "",
  companyAddress: "",
  contactPersonEmail: "",
  contactPersonName: "",
  type: "",
  contactPersonPhone: "",
  gridHours: "",
  gridCost: "",
  genSize: "",
  genType: "",
  monthlyFuel: "",
  inverter: "",
  nightPeakLoad: "",
  dayPeakLoad: "",
  dailyConsumption: "",
  rooms: "",
});

const errors = ref({
  companyName: false,
  companyAddress: false,
  contactPersonEmail: false,
  contactPersonName: false,
  contactPersonPhone: false,
  gridHours: false,
  gridCost: false,
  genSize: false,
  genType: false,
  monthlyFuel: false,
  inverter: false,
  nightPeakLoad: false,
  dayPeakLoad: false,
  dailyConsumption: false,
  rooms: false,
});

const eMsg = ref({
  companyName: "This field is required",
  companyAddress: "This field is required",
  contactPersonEmail: "This field is required",
  contactPersonName: "This field is required",
  contactPersonPhone: "This field is required",
  gridHours: "This field is required",
  gridCost: "This field is required",
  genSize: "This field is required",
  monthlyFuel: "This field is required",
  genType: "This field is required",
  inverter: "This field is required",
  nightPeakLoad: "This field is required",
  dayPeakLoad: "This field is required",
  dailyConsumption: "This field is required",
  rooms: "This field is required",
});

const handleStepOne = () => {
  stepOne.value = true;
  stepTwo.value = false;
};

const handleNextPage = () => {
  if (office.companyName == "") {
    errors.value.companyName = true;
    return;
  } else {
    errors.value.companyName = false;
  }

  if (office.companyAddress == "") {
    errors.value.companyAddress = true;
    return;
  } else {
    errors.value.companyAddress = false;
  }

  if (office.contactPersonEmail == "") {
    errors.value.contactPersonEmail = true;
    return;
  } else if (
    !office.contactPersonEmail.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.contactPersonEmail = true;
    eMsg.value.contactPersonEmail = "Invalid Email";
    return;
  } else {
    errors.value.contactPersonEmail = false;
  }

  if (office.contactPersonName == "") {
    errors.value.contactPersonName = true;
    return;
  } else {
    errors.value.contactPersonName = false;
  }

  if (office.contactPersonPhone == "") {
    errors.value.contactPersonPhone = true;
    return;
  } else if (!phoneRegex.test(office.contactPersonPhone)) {
    errors.value.contactPersonPhone = true;
    eMsg.value.contactPersonPhone = "Please Enter a valid Phone Number";
    return;
  } else {
    errors.value.contactPersonPhone = false;
  }

  stepOne.value = false;
  stepTwo.value = true;
};

const saveForm = () => {
  if (office.gridHours == "") {
    errors.value.gridHours = true;
    return;
  } else if (!numbRegex.test(office.gridHours)) {
    errors.value.gridHours = true;
    eMsg.value.gridHours = "Please enter numbers only";
    return;
  } else {
    errors.value.gridHours = false;
  }

  if (office.gridCost == "") {
    errors.value.gridCost = true;
    return;
  } else if (!numbRegex.test(office.gridCost)) {
    errors.value.gridCost = true;
    eMsg.value.gridCost = "Please enter numbers only";
    return;
  } else {
    errors.value.gridCost = false;
  }

  if (office.genSize == "") {
    errors.value.genSize = true;
    return;
  } else if (!decimalRegex.test(office.genSize)) {
    errors.value.genSize = true;
    eMsg.value.genSize = "Please enter numbers only";
    return;
  } else {
    errors.value.genSize = false;
  }

  if (office.genType == "") {
    errors.value.genType = true;
    return;
  } else {
    errors.value.genType = false;
  }

  if (office.monthlyFuel == "") {
    errors.value.monthlyFuel = true;
    return;
  } else if (!numbRegex.test(office.monthlyFuel)) {
    errors.value.monthlyFuel = true;
    eMsg.value.monthlyFuel = "Please enter numbers only";
    return;
  } else {
    errors.value.monthlyFuel = false;
  }

  if (office.inverter == "") {
    errors.value.inverter = true;
    return;
  } else if (!numbRegex.test(office.inverter)) {
    errors.value.inverter = true;
    eMsg.value.inverter = "Please enter numbers only";
    return;
  } else {
    errors.value.inverter = false;
  }

  if (office.nightPeakLoad == "") {
    errors.value.nightPeakLoad = true;
    return;
  } else if (!numbRegex.test(office.nightPeakLoad)) {
    errors.value.nightPeakLoad = true;
    eMsg.value.nightPeakLoad = "Please enter numbers only";
    return;
  } else {
    errors.value.nightPeakLoad = false;
  }

  if (office.dayPeakLoad == "") {
    errors.value.dayPeakLoad = true;
    return;
  } else if (!numbRegex.test(office.dayPeakLoad)) {
    errors.value.dayPeakLoad = true;
    eMsg.value.dayPeakLoad = "Please enter numbers only";
    return;
  } else {
    errors.value.dayPeakLoad = false;
  }

  if (office.dailyConsumption == "") {
    errors.value.dailyConsumption = true;
    return;
  } else if (!numbRegex.test(office.dailyConsumption)) {
    errors.value.dailyConsumption = true;
    eMsg.value.dailyConsumption = "Please enter numbers only";
    return;
  } else {
    errors.value.dailyConsumption = false;
  }

  if (office.rooms == "") {
    errors.value.rooms = true;
    return;
  } else if (!numbRegex.test(office.rooms)) {
    errors.value.rooms = true;
    eMsg.value.rooms = "Please enter numbers only";
    return;
  } else {
    errors.value.rooms = false;
  }

  store
    .dispatch("post", {
      endpoint: "/auditForm/office",
      details: {
        name: office.companyName,
        address: office.companyAddress,
        contact_name: office.contactPersonName,
        email: office.contactPersonEmail,
        phone: office.contactPersonPhone,
        rooms: office.rooms,
        gridCost: office.gridCost,
        gridHours: office.gridHours,
        gen: office.genType,
        genSize: office.genSize,
        inverter: office.inverter,
        fuel: office.monthlyFuel,
        dayPeakLoad: office.dayPeakLoad,
        nightPeakLoad: office.nightPeakLoad,
        dayConsumption: office.dailyConsumption,
      },
    })
    .then((resp) => {
      // console.log(resp);
      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
      useToast().success("Form Submitted Successfully");
    });
};
</script>

<template>
  <div
    class="offcanvas offcanvas-end right-sheet"
    tabindex="-1"
    id="offcanvasRight4"
    aria-labelledby="offcanvasRightLabel4"
  >
    <div class="offcanvas-header border-bottom py-4 d-block">
      <div class="mb-3">
        <button
          type="button"
          class="bg-white me-2"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <Iconify icon="ep:back" />
        </button>
        <span>Back</span>
      </div>

      <div class="d-flex align-items-center justify-content-between">
        <h5 id="offcanvasRightLabel4">TAO Energy Audit Form</h5>
        <div class="office-icon d-flex align-items-center">
          <Iconify
            icon="fluent:building-bank-48-regular"
            style="color: #818181"
          />
          <p class="p-text text-gray ms-2">Office</p>
        </div>
      </div>
    </div>
    <div class="offcanvas-body p-4">
      <div v-if="stepOne">
        <div
          class="steps d-flex justify-content-between align-items-center mb-3"
        >
          <p>Contact Person Information</p>
          <div>
            <button class="btn text-primary border-light rounded-pill">
              Step<span class="p">1/2</span>
            </button>
          </div>
        </div>
        <form action="">
          <div class="each-field mb-4">
            <label class="mb-2" for="name"
              >Company Name <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.companyName"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="e.g taoenergy"
              />
            </div>
            <div v-if="errors.companyName" class="error-msg">
              {{ eMsg.companyName }}
            </div>
          </div>

          <div class="each-field mb-4">
            <label for="meter" class="mb-2"
              >Comapany Address<span class="text-danger">*</span></label
            >
            <div class="input-group mb-3">
              <span class="input-group-text" id="inputGroup-sizing-default"
                ><Iconify
                  icon="carbon:location"
                  class="pe-2"
                  style="
                    width: 30px;
                    height: 30px;
                    color: #818181;
                    border-right: 1px solid #c6c6c6;
                  "
                />
              </span>
              <input
                v-model="office.companyAddress"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="e.g smith Rd"
              />
            </div>
            <div v-if="errors.companyAddress" class="error-msg">
              {{ eMsg.companyAddress }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="name"
              >Company Email <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.contactPersonEmail"
                type="email"
                class="form-control border-gray bg-gray mb-2"
                placeholder="e.g Joedoe@gmail.com"
              />
            </div>
            <div v-if="errors.contactPersonEmail" class="error-msg">
              {{ eMsg.contactPersonEmail }}
            </div>
          </div>

          <div class="each-field mb-4">
            <label class="mb-2" for="name"
              >Contact Person Name <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.contactPersonName"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="e.g Joe Doe"
              />
            </div>
            <div v-if="errors.companyName" class="error-msg">
              {{ eMsg.companyName }}
            </div>
          </div>

          <div class="each-field mb-4">
            <label for="meter" class="mb-2">
              Contact Phone Number <span class="text-danger">*</span></label
            >
            <div class="input-group mb-3">
              <div class="input-group-text" id="basic-addon1">
                <Iconify
                  icon="twemoji:flag-nigeria"
                  style="width: 24px; height: 24x"
                /><span class="pe-2" style="border-right: 1px solid gray">
                  +234</span
                >
              </div>
            
                <input
                  v-model="office.contactPersonPhone"
                  type="tel"
                  class="form-control border-gray bg-gray"
                  placeholder="081*******"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
        
              <div v-if="errors.contactPersonPhone" class="error-msg">
                {{ eMsg.contactPersonPhone }}
              </div>
            </div>
          </div>

          <div class="d-grid mt-4">
            <button
              @click.prevent="handleNextPage()"
              type="submit"
              class="border-gray rounded text-white btn-primary"
            >
              Next
            </button>
          </div>
        </form>
      </div>
      <div v-if="stepTwo">
        <div
          class="steps d-flex justify-content-between align-items-center mb-3"
        >
          <div class="d-flex align-items-center">
            <div class="back-icon" @click="handleStepOne()">
              <Iconify icon="ep:arrow-left" />
            </div>
            <div>
              <p class="p-text">Current Electricity Sources</p>
            </div>
          </div>

          <div>
            <button class="btn text-primary border-light rounded-pill">
              Step<span class="p">2/2</span>
            </button>
          </div>
        </div>

        <form action="">
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Grid (hours) <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.gridHours"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.gridHours" class="error-msg">
              {{ eMsg.gridHours }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Estimated monthly grid cost<span class="text-danger"
                >*</span
              ></label
            >
            <div>
              <input
                v-model="office.gridCost"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.gridCost" class="error-msg">
              {{ eMsg.gridCost }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Type of Generator <span class="text-danger">*</span></label
            >
            <div>
              <select
                v-model="office.genType"
                class="form-select form-select-lg"
                aria-label="Large select example"
              >
                <option class="px-2" value="" disabled>select option</option>
                <option value="diesel">Diesel</option>
                <option value="pms">PMS</option>
                <option value="lpg">LPG</option>
              </select>
            </div>

            <div v-if="errors.genSize" class="error-msg">
              {{ eMsg.genSize }}
            </div>
          </div>

          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Generator size (KVA) <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.genSize"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.genSize" class="error-msg">
              {{ eMsg.genSize }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Estimated monthly generator fuel cost
              <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.monthlyFuel"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.monthlyFuel" class="error-msg">
              {{ eMsg.monthlyFuel }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Inverter (capacity - KVA)
              <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.inverter"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.inverter" class="error-msg">
              {{ eMsg.inverter }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Estimated daytime peak load in kW
              <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.dayPeakLoad"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.dayPeakLoad" class="error-msg">
              {{ eMsg.dayPeakLoad }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Estimated night-time peak load in kW
              <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.nightPeakLoad"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.nightPeakLoad" class="error-msg">
              {{ eMsg.nightPeakLoad }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Estimated daily Consumption in kWh
              <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.dailyConsumption"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.dailyConsumption" class="error-msg">
              {{ eMsg.dailyConsumption }}
            </div>
          </div>

          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Number of rooms in the building
              <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.rooms"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.rooms" class="error-msg">
              {{ eMsg.rooms }}
            </div>
          </div>

          <div class="d-grid mt-4">
            <button
              @click.prevent="saveForm()"
              type="submit"
              class="btn-primary"
              style="height: 44px"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div
    class="offcanvas offcanvas-bottom full-size"
    tabindex="-1"
    id="offcanvasBottomE"
    aria-labelledby="offcanvasBottomLabelE"
  >
    <div class="offcanvas-header border-bottom py-4 d-block">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <button
            type="button"
            class="me-2 back-icon"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <Iconify icon="ep:arrow-left" />
          </button>
          <h6 class="fs-16" id="offcanvasBottomLabelE">TAO Energy Audit Form</h6>
        </div>
        <div class="office-icon d-flex align-items-center">
          <Iconify
            icon="fluent:building-bank-48-regular"
            style="color: #818181; font-size: 24px"
          />
          <p class="p-text text-gray ms-2">Office</p>
        </div>
      </div>
    </div>
    <div class="offcanvas-body p-4">
      <div v-if="stepOne">
        <div
          class="steps d-flex justify-content-between align-items-center mb-3"
        >
          <p>Contact Person Information</p>
          <div>
            <button class="btn text-primary border-light rounded-pill">
              Step<span class="p">1/2</span>
            </button>
          </div>
        </div>
        <form action="">
          <div class="each-field mb-4">
            <label class="mb-2" for="name"
              >Company Name <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.companyName"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="e.g taoenergy"
              />
            </div>
            <div v-if="errors.companyName" class="error-msg">
              {{ eMsg.companyName }}
            </div>
          </div>

          <div class="each-field mb-4">
            <label for="meter" class="mb-2"
              >Comapany Address<span class="text-danger">*</span></label
            >
            <div class="input-group mb-3">
              <span class="input-group-text" id="inputGroup-sizing-default"
                ><Iconify
                  icon="carbon:location"
                  class="pe-2"
                  style="
                    width: 30px;
                    height: 30px;
                    color: #818181;
                    border-right: 1px solid #c6c6c6;
                  "
                />
              </span>
              <input
                v-model="office.companyAddress"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="e.g smith Rd"
              />
            </div>
            <div v-if="errors.companyAddress" class="error-msg">
              {{ eMsg.companyAddress }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="name"
              >Company Email <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.contactPersonEmail"
                type="email"
                class="form-control border-gray bg-gray mb-2"
                placeholder="e.g joedoe@gmail.com"
              />
            </div>
            <div v-if="errors.contactPersonEmail" class="error-msg">
              {{ eMsg.contactPersonEmail }}
            </div>
          </div>

          <div class="each-field mb-4">
            <label class="mb-2" for="name"
              >Contact Person Name <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.contactPersonName"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="e.g Joe Doe"
              />
            </div>
            <div v-if="errors.companyName" class="error-msg">
              {{ eMsg.companyName }}
            </div>
          </div>

          <div class="each-field mb-4">
            <label for="meter" class="mb-2">
              Contact Phone Number <span class="text-danger">*</span></label
            >
            <div class="input-group mb-3">
              <div class="input-group-text" id="basic-addon1">
                <Iconify
                  icon="twemoji:flag-nigeria"
                  style="width: 24px; height: 24x"
                /><span class="pe-2" style="border-right: 1px solid gray">
                  +234</span
                >
              </div>

              <input
                v-model="office.contactPersonPhone"
                type="tel"
                class="form-control border-gray bg-gray"
                placeholder="081*******"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div v-if="errors.contactPersonPhone" class="error-msg">
              {{ eMsg.contactPersonPhone }}
            </div>
          </div>

          <div class="d-grid mt-4">
            <button
              @click.prevent="handleNextPage()"
              type="submit"
              class="border-gray rounded btn-primary"
              style="height: 44px; color: white"
            >
              Next
            </button>
          </div>
        </form>
      </div>
      <div v-if="stepTwo">
        <div
          class="steps d-flex justify-content-between align-items-center mb-3"
        >
          <div class="d-flex align-items-center">
            <div class="back-icon" @click="handleStepOne()">
              <Iconify icon="ep:arrow-left" />
            </div>
            <div>
              <p class="p-text">Current Electricity Sources</p>
            </div>
          </div>

          <div>
            <button class="btn text-primary border-light rounded-pill">
              Step<span class="p">2/2</span>
            </button>
          </div>
        </div>

        <form action="">
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Grid (hours) <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.gridHours"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.gridHours" class="error-msg">
              {{ eMsg.gridHours }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Estimated monthly grid cost<span class="text-danger"
                >*</span
              ></label
            >
            <div>
              <input
                v-model="office.gridCost"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.gridCost" class="error-msg">
              {{ eMsg.gridCost }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Type of Generator <span class="text-danger">*</span></label
            >
            <div>
              <select
                v-model="office.genType"
                class="form-select form-select-lg"
                aria-label="Large select example"
              >
                <option class="px-2" value="" disabled>select option</option>
                <option value="diesel">Diesel</option>
                <option value="pms">PMS</option>
                <option value="lpg">LPG</option>
              </select>
            </div>

            <div v-if="errors.genSize" class="error-msg">
              {{ eMsg.genSize }}
            </div>
          </div>

          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Generator size (KVA) <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.genSize"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.genSize" class="error-msg">
              {{ eMsg.genSize }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Estimated monthly generator fuel cost
              <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.monthlyFuel"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.monthlyFuel" class="error-msg">
              {{ eMsg.monthlyFuel }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Inverter (capacity - KVA)
              <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.inverter"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.inverter" class="error-msg">
              {{ eMsg.inverter }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Estimated daytime peak load in kW
              <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.dayPeakLoad"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.dayPeakLoad" class="error-msg">
              {{ eMsg.dayPeakLoad }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Estimated night-time peak load in kW
              <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.nightPeakLoad"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.nightPeakLoad" class="error-msg">
              {{ eMsg.nightPeakLoad }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Estimated daily Consumption in kWh
              <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.dailyConsumption"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.dailyConsumption" class="error-msg">
              {{ eMsg.dailyConsumption }}
            </div>
          </div>

          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Number of rooms in the building
              <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="office.rooms"
                type="text"
                class="form-control border-gray bg-gray mb-2"
                placeholder="Your answer"
              />
            </div>
            <div v-if="errors.rooms" class="error-msg">
              {{ eMsg.rooms }}
            </div>
          </div>

          <div class="d-grid mt-4">
            <button
              @click.prevent="saveForm()"
              type="submit"
              class="btn-primary"
              style="height: 44px"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script lang="ts" setup>
import landingNav from "@/components/Dashboard/LandingPageNav.vue";
import { ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";

const store = useStore();
const phoneRegex = /^0(7[0]|8[0-1]|9[0-1])[0-9]{8}$/;
const loading = ref(false);
const form = ref({
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
});

const eMsg = ref({
  email: "Please enter your email",
  message: "This field is required",
  phone: "This field is required",
});

const errors = ref({
  email: false,
  message: false,
  phone: false,
});

const send = () => {
  if (form.value.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !form.value.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;

    if (form.value.phone == "") {
      errors.value.phone = true;
      return;
    } else if (!phoneRegex.test(form.value.phone)) {
      errors.value.phone = true;
      eMsg.value.phone = "Please Enter a valid Phone Number";
      return;
    } else {
      errors.value.phone = false;
    }

    if (form.value.message == "") {
      errors.value.message = true;
      return;
    } else {
      errors.value.message = false;
      loading.value = true;

      store
        .dispatch("post", {
          endpoint: "/contact",
          details: {
            name: form.value.name,
            email: form.value.email,
            phone: form.value.phone,
            subject: form.value.subject,
            message: form.value.message,
          },
        })
        .then((resp) => {
          console.log("the response", resp);
          loading.value = false;
          useToast().success("message sent successfully")
          window.setTimeout(() =>{
            window.location.reload()
          }, 1200)
        });
    }
  }
};
</script>

<template>
  <div class="l-wrapper">
    <landingNav></landingNav>
    <div class="all-section policy-page">
      <div
        class="contact-screen d-flex align-items-center justify-content-center"
      >
        <h2 class="text-white fw-bold">Contact Us</h2>
      </div>

      <section class="screen-view">
        <div class="formm">
          <div class="lsd me-4">
            <h4 class="fw-bold">Get in touch with us</h4>
            <div class="p-3 rounded-lg mt-4 bg-graying">
              <p>You can also reach out to us with these contact details;</p>
              <div class="">
                <div class="dab each-space">
                  <ul class="p-0">
                    <li class="my-4 text-black fw-600">
                      <Iconify class="icons me-3" icon="zondicons:location" />
                      <span>1 Nairobi Street, Wuse 2, Abuja</span>
                    </li>

                    <li class="my-4 text-black fw-600">
                      <Iconify icon="ic:round-email" class="icons me-3" /><span>
                        <a class="text-dark" href="info@tao.ng"> info@tao.ng</a>
                      </span>
                    </li>
                    <li class="my-4 text-black fw-600">
                      <Iconify
                        icon="solar:phone-bold"
                        class="icons me-3"
                      /><span>+234 908 485 7430 | +13109013903 </span>
                    </li>
                  </ul>
                </div>
              </div>

              <p class="fs-14 fw-600 py-3">Social media</p>
              <div class="d-flex align-items-center social-icons">
                <div class="me-2 i-bg-black">
                  <Iconify
                    style="font-size: 20px"
                    class=""
                    icon="skill-icons:linkedin"
                  />
                </div>
                <div class="me-2 i-bg-black">
                  <Iconify style="font-size: 20px" icon="devicon:facebook" />
                </div>
                <div class="me-2 i-bg-black">
                  <Iconify
                    style="font-size: 20px"
                    icon="skill-icons:instagram"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="rsd p-3 rounded-lg mt-4 bg-graying">
            <form
              action="
            "
            >
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <div class="each-field mb-3">
                    <label class="mb-2" for="meter">Name</label>
                    <div>
                      <input
                        v-model="form.name"
                        type="text"
                        class="form-control border-gray bg-white"
                        placeholder="Enter your name"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="each-field mb-3">
                    <label class="mb-2" for="meter">Email Address</label>
                    <div>
                      <input
                        v-model="form.email"
                        type="text"
                        class="form-control border-gray bg-white"
                        placeholder="Enter your email"
                      />
                    </div>
                    <div v-if="errors.email" class="error-msg">
                      {{ eMsg.email }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="each-field mb-3">
                    <label for="meter" class="mb-2">Phone Number</label>
                    <div
                      class="d-flex align-items-center border-gray bg-white form-control"
                    >
                      <div class="d-flex align-items-center">
                        <Iconify
                          icon="twemoji:flag-nigeria"
                          style="width: 24px; height: 24x"
                        /><span
                          class="pe-2"
                          style="border-right: 1px solid gray"
                        >
                          +234</span
                        >
                      </div>

                      <input
                        v-model="form.phone"
                        type="tel"
                        class="border-0 w-100 ps-2"
                        placeholder="Phone number"
                        max-length="11"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="each-field mb-3">
                    <label class="mb-2" for="meter">Subject</label>
                    <div>
                      <input
                        v-model="form.subject"
                        type="text"
                        class="form-control border-gray bg-white"
                        placeholder="what is the subject of your inquiry"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="each-field mb-3">
                    <label class="mb-2" for="meter">How can we help you?</label>
                    <div>
                      <textarea
                        v-model="form.message"
                        class="text-control border-gray bg-white"
                        placeholder="Feel free to write your message..."
                      ></textarea>
                    </div>
                    <div v-if="errors.message" class="error-msg">
                      {{ eMsg.message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="my-4 ms-4">
                <button
                :disabled="loading"
                 @click.prevent="send" class="btn-primary px-5">
                 {{loading? "Loading...": "Send Message"}}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import { formatDate } from "@/core/utils/helpers";



const props = defineProps({
apiOfficeRes : {}
});

const offices : any =  computed(() => {
  return props.apiOfficeRes
})


</script>

<template>
  <div v-if="offices">
  <div  
    class="offcanvas offcanvas-end right-sheet"
    tabindex="-1"
    id="offcanvasRight6"
    aria-labelledby="offcanvasRightLabel6"
  >
    <div class="offcanvas-header border-bottom py-4 d-block">
      <div class="mb-3">
        <button
          type="button"
          class="bg-white me-2"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <Iconify icon="ep:back" />
        </button>
        <span>Back</span>
      </div>
      <div>
        <div class="d-flex align-items-center justify-content-between">
          <h5 id="offcanvasRightLabel6">Office Space</h5>
          <p class="text-gray">
            {{ formatDate(offices.created_at) }}
          </p>
        </div>
      </div>
    </div>
      <div class="offcanvas-body side-body p-4">
        <div class="each-card mb-4">
          <p class="text-primary mb-3">Contact Person Information</p>
          <div class="card-wrapper border rounded">
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Company Name</p>
              <p>{{ offices.name }}</p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Contact Person Name</p>
              <p class="p-text">{{ offices.contact_name }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Contact Phone Number</p>
              <p class="p-text">{{ offices.phone }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Company Address</p>

              <p class="p-text">{{ offices.address }}</p>
            </div>
          </div>
        </div>
        <div class="each-card">
          <p class="text-primary mb-3">Current Electricity Source</p>
          <div class="card-wrapper border rounded">
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Grid (hours)</p>
              <p class="p-text">{{ offices.gridHours }}</p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Estimated monthly grid cost</p>
              <p class="p-text">{{ offices.gridCost }}</p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">
                Generator (Fuel)
              </p>
              <p class="p-text">{{ offices.gen }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Generator size (KVA)</p>
              <p class="p-text">{{ offices.genSize }} KVA</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">
                Estimated monthly fuel (diesel) cost
              </p>
              <p class="p-text">{{ offices.fuel }}</p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center">
              <p class="text-gray p-text">Number of rooms in the building</p>
              <p class="p-text">{{ offices.rooms }}</p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Inverter (capacity - KVA)</p>
              <p class="p-text">{{ offices.inverter }} KVA</p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Estimated daytime peak load in kW</p>
              <p class="p-text">{{ offices.dayPeakLoad }} kW</p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">
                Estimated night-time peak load in kW
              </p>
              <p class="p-text">
                {{ offices.nightPeakLoad }}
              </p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Estimated Daily Consumption in kWh</p>
              <p class="p-text">{{ offices.dayConsumption }} kWh</p>
            </div>
          </div>
        </div>
      </div>
   
  </div>

  <div  
    class="offcanvas offcanvas-bottom full-size"
    tabindex="-1"
    id="offcanvasBottomG"
    aria-labelledby="offcanvasBottomLabelG"
  >
    <div class="offcanvas-header border-bottom py-4 d-block">
      <div class="d-flex align-items-center justify-content-between">
        <div >
          <h5 id="offcanvasBottomLabelG">Office Space</h5>
          <p class="text-gray mt-2">
            {{ formatDate(offices.created_at) }}
          </p>
        </div>
        <div class="back-icon">
        <button
          type="button"
          class="me-2 btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
        </button>
      </div>
      </div>
      </div>
      <div class="offcanvas-body p-4">
        <div class="each-card mb-4">
          <p class="text-primary mb-3">Contact Person Information</p>
          <div class="card-wrapper border rounded">
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Company Name</p>
              <p>{{ offices.name }}</p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Contact Person Name</p>
              <p class="p-text">{{ offices.contact_name }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Contact Phone Number</p>
              <p class="p-text">{{ offices.phone }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Company Address</p>

              <p class="p-text">{{ offices.address }}</p>
            </div>
          </div>
        </div>
        <div class="each-card">
          <p class="text-primary mb-3">Current Electricity Source</p>
          <div class="card-wrapper border rounded">
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Grid (hours)</p>
              <p class="p-text">U{{ offices.gridHours }}</p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Estimated monthly grid cost</p>
              <p class="p-text">{{ offices.gridCost }}</p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">
                Generator (Fuel)
              </p>
              <p class="p-text">{{ offices.gen }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Generator size (KVA)</p>
              <p class="p-text">{{ offices.genSize }} KVA</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">
                Estimated monthly fuel (diesel) cost
              </p>
              <p class="p-text">{{ offices.fuel }}</p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Number of rooms in the building</p>
              <p class="p-text">{{ offices.rooms }}</p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Inverter (capacity - KVA)</p>
              <p class="p-text">{{ offices.inverter }} KVA</p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Estimated daytime peak load in kW</p>
              <p class="p-text">{{ offices.dayPeakLoad }} kW</p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">
                Estimated night-time peak load in kW
              </p>
              <p class="p-text">
                {{ offices.nightPeakLoad }} kW
              </p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Estimated Daily Consumption in kWh</p>
              <p class="p-text">{{ offices.dayConsumption }} kWh</p>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
  
</template>

<style lang="scss" scoped>
.side-body {
  height: 100%;
  overflow-y:scroll ;
}



</style>

<script setup lang="ts">
import { ref } from "vue";
import { useStore } from "vuex";

import NewUserLogin from "@/components/Modal/NewUserLogin.vue";
import NewLoginMob from "@/components/BottomSheet/CreateAccount/LoginMob.vue";
import { useRouter } from "vue-router";
const router = useRouter();
const store = useStore();

const logout = () => {
  store.commit("logout");
};
</script>

<template>
  <div class="mobile-screen">
    <div v-if="store.state.user">
      <div class="d-flex  align-items-center mb-4">
        <div class="initials">
          <h4 class="text-primary uppercase">
            {{ store.state.user.first_name[0]
            }}{{ store.state.user.last_name[0] }}
          </h4>
        </div>
        <div class="ms-3">
          <h6 class="capitalize mb-2">
            {{ store.state.user.first_name }}
            {{ store.state.user.last_name }}
          </h6>
          <p class="profile-email">
            {{ store.state.user.email }}
          </p>
        </div>
      </div>

      <div class="account-card border rounded mt-4">
        <ul class="p-0 m-0">
          <li class="account-list border-bottom">
            <router-link
              to="/my-profile"
              :class="{ picked: $route.name === 'My Profile' }"
              class="each-list"
            >
              <div class="d-flex align-items-center">
                <Iconify icon="solar:user-linear" style="font-size: 22px" />
                <span class="menu-text ms-2">My Profile</span>
              </div>
              <Iconify icon="ep:arrow-right" style="font-size: 22px" />
            </router-link>
          </li>
          <li class="account-list border-bottom">
            <router-link
              to="/beneficiary"
              :class="{ picked: $route.name === 'Beneficiary' }"
              class="each-list"
            >
              <div class="d-flex align-items-center">
                <Iconify
                  icon="solar:users-group-rounded-linear"
                  style="font-size: 22px"
                />
                <span class="menu-text ms-2">Beneficiary</span>
              </div>
              <Iconify icon="ep:arrow-right" style="font-size: 22px" />
            </router-link>
          </li>
          <li class="account-list border-bottom">
            <router-link
              to="/notifications"
              :class="{ picked: $route.name === 'Notification' }"
              class="each-list"
            >
              <div class="d-flex align-items-center">
                <Iconify icon="lets-icons:bell-light" style="font-size: 22px" />
                <span class="menu-text ms-2">Notifications</span>
              </div>
              <Iconify icon="ep:arrow-right" style="font-size: 22px" />
            </router-link>
          </li>
          <li class="account-list border-bottom">
            <router-link
              to="/update-password"
              :class="{ picked: $route.name === 'Update Password' }"
              class="each-list"
            >
              <div class="d-flex align-items-center">
                <Iconify icon="mage:security-shield" style="font-size: 22px" />
                <span class="menu-text ms-2">Update Password</span>
              </div>
              <Iconify icon="ep:arrow-right" style="font-size: 22px" />
            </router-link>
          </li>
          <li class="account-list">
            <div  @click.prevent="logout()" class="each-list text-danger pointer">
              <div class="d-flex align-items-center">
                <Iconify icon="hugeicons:logout-01" style="font-size: 22px" />
                <span class="menu-text ms-2">Log Out</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div v-else>
      <div class="d-flex justify-content-center mt-5">
        <div class="text-center" style="width: 400px">
          <Iconify icon="lets-icons:flash-light" style="font-size: 28px" />
          <h5 class="my-4">You have not signed in</h5>
          <p class="p-text text-gray mb-4">
            Please create an account or sign in to have access to your account
            settings
          </p>
          <div class="desktop-screen">  
          <div class="d-flex align-items-center justify-content-center ">
            <button
              data-bs-target="#exampleModalToggleB"
              data-bs-toggle="modal"
              class="btn-primary auth-btn me-3"
          
            >
              Create an Account
            </button>
            <button
              data-bs-target="#exampleModalToggleC"
              data-bs-toggle="modal"
              class="btn-primary auth-btn"
     
            >
              Login
            </button>
          </div>
        </div>

        <div class="mobile-screen"> 
          <div class="d-flex align-items-center justify-content-center ">
            <button
              class="btn-primary auth-btn me-3"
              data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasBottom10"
            aria-controls="offcanvasBottom10"
            >
              Create an Account
            </button>
            <button
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasBottom11"
            aria-controls="offcanvasBottom11"
              class="btn-primary auth-btn"
     
            >
              Login
            </button>
          </div>
        </div>
        </div>
      </div>
      <NewLoginMob></NewLoginMob>
      <NewUserLogin></NewUserLogin>
    </div>
  </div>
</template>

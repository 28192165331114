<template>
  <div class="home-screen d-flex align-items-center justify-content-center">
    <div class="home-logo">
      <img src="@/assets/images/logo.svg" alt="">
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script  setup lang="ts">
import { ref, onMounted } from "vue";

import {useRouter} from "vue-router";

const router = useRouter();

onMounted(() => {
  setTimeout(() => {
    router.push({ name: 'Landing Page' });
  }, 1500); 
});

</script>

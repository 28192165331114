import moment from "moment";
export const formatDate = (date: string) => moment(date).format("MMM D, YYYY");
export const formatDateTime = (date: string) =>
  moment(date).format("MMM D, YYYY h:mm:ss A");

  export const formatTime = (time: number) =>
  moment(time).format(" h:mm A");

  export const formatAmount = (amount: number) => {
    return amount
      ? new Intl.NumberFormat("en-NG", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(amount)
      : amount;
  };
<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import EachBeneficiary from "@/components/BottomSheet/Beneficiary/EditBeneficiary.vue";
import AddNew from "@/components/BottomSheet/Beneficiary/AddNewBeneficiary.vue";
import NewUserLogin from "@/components/Modal/NewUserLogin.vue";
import LoginMob from "@/components/BottomSheet/CreateAccount/LoginMob.vue";
import MyAccount from "@/components/Dashboard/my-account.vue";
import { useRouter } from "vue-router";

const store = useStore();
const loadInfo = ref(false);
const loading = ref(false);
const beneficiaryList: any = ref([]);
const router = useRouter();
const oneBeneficiary : any = ref({});

const filter = ref({
  page:1,
  limit:10,
  from: null,
  to:null,
  name: ""
});


const getBeneficiary = () => {
  store.commit("setLoader", true);
  store.dispatch("get", `/beneficiary`).then((resp) => {
    store.commit("setLoader", false);
    beneficiaryList.value = resp.data.data;
    oneBeneficiary.value = beneficiaryList.value[0]
    loadInfo.value = true;

  }).catch((err) => {
    store.commit("setLoader", false);
  })
};

const setBeneficiary = (item: any) => {
  oneBeneficiary.value = item;
};

onMounted(() => {
  if (store.state.user) {
    getBeneficiary();
  }
});
</script>

<template>
  <div class="page-card d-flex justify-content-center">
    <MyAccount></MyAccount>

    <div v-if="store.state.user" class="account-contents">
      <EachBeneficiary :oneBeneficiary="oneBeneficiary"></EachBeneficiary>
      <div class="desktop-screen">
        <div
          class="b-nav border-bottom d-flex justify-content-between align-items-center"
        >
          <div class="desktop-screen">
            <h5>Beneficiary</h5>
          </div>

          <div v-if="beneficiaryList.length > 0" class="d-grid">
            <button
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight3"
              aria-controls="offcanvasRight3"
              class="btn-primary px-3"
            >
              Add New Beneficiary
            </button>
          </div>
        </div>
      </div>
      <AddNew></AddNew>

      <div v-if="loadInfo">
        <div v-if="beneficiaryList.length > 0">
          <div class="desktop-screen">
            <div class="transaction-history mt-4 px-3">
              <div class="search">
                <form @submit.prevent="getBeneficiary()">
                  <input
                    v-model="filter.name"
                    type="search"
                    class="input-field form-control ps-5"
                    placeholder="Search by name"
                  />
                  <div class="search-icon">
                    <Iconify
                      icon="iconamoon:search-thin"
                      @submit.prevent="getBeneficiary()"
                    />
                  </div>
                </form>
              </div>
              <div class="table border rounded responsive mt-4">
          
                  <table>
                    <thead>
                      <tr>
                        <th>Meter Number</th>
                        <th>Electricity Provider</th>
                        <th>Meter Type</th>
                        <th>Name</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(beneficiary, index) in beneficiaryList"
                        :key="index"
                      >
                        <td>{{ beneficiary.meter_no }}</td>
                        <td>{{ beneficiary.provider }}</td>
                        <td>{{ beneficiary.meter_type }}</td>
                        <td>{{ beneficiary.name }}</td>
                        <td>
                          <div
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRightD"
                            aria-controls="offcanvasRightD"
                            @click="setBeneficiary(beneficiary)"
                          >
                            <Iconify
                              icon="ri:more-line"
                              style="color: #ff6600"
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                
                </div>
                <div
                  class="pagination d-flex align-items-center justify-content-between px-4 pb-4"
                >
                  <div class="previous box-shad text-disabled">
                    <span
                      ><Iconify icon="ep:back" style="font-size: 22px"
                    /></span>
                    Previous
                  </div>
                  <div class="page-numb">1</div>
                  <div class="box-shad text-disabled text-center">
                    Next
                    <span
                      ><Iconify icon="ep:right" style="font-size: 22px"
                    /></span>
                  </div>
                </div>
          
            </div>
          </div>
          
          <div class="mobile-screen">
            <div class="d-flex my-4">
              <div class="back-icon e-icon me-2" @click.prevent="router.go(-1)">
                <Iconify icon="ep:arrow-left" />
              </div>
              <p class="text-gray">Select a beneficiary</p>
            </div>
            <div class="search">
              <form @submit.prevent="getBeneficiary()">
                <input
                v-model="filter.name"
                  type="search"
                  class="input-field form-control ps-5"
                  placeholder="Search by name"
                />
                <div class="search-icon">
                  <Iconify
                    icon="iconamoon:search-thin"
                    @submit.prevent="getBeneficiary()"
                  />
                </div>
              </form>
            </div>
            <div class="mt-4">
            <div 
              v-for="(beneficiary, index) in beneficiaryList"
              :key="index"
              class="each-benefit mb-2"
            >
              <div class="d-flex align-items-center justify-content-start p-2 rounded ">
                <div
                  class="bg-primary rounded-circle me-3"
                  style="height: 10px; width: 10px"
                ></div>
                <div 
                  class="details d-flex justify-content-between align-items-center"
                >
                  <div>
                    <p class="fs-6 mb-2">{{ beneficiary.meter_no }}</p>
                    <p class="p-text text-gray">
                      {{ beneficiary.provider }}
                    </p>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="text-end" >
                      <p class="text-gray p-text mb-3 capitalize">
                        {{ beneficiary.name }}
                      </p>
                      <p class="text-gray p-text">
                        {{ beneficiary.meter_type }}
                      </p>
                    </div>

                    <div
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasBottomT"
                      aria-controls="offcanvasBottomT"
                      @click="setBeneficiary(beneficiary)"
                    >
                      <iconify
                        
                        icon="ic:sharp-more-vert"
                        style="font-size: 20px"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-grid add-benefit">
              <button
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasBottomG"
                aria-controls="offcanvasBottomG"
                class="btn-primary"
              >
                Add New Beneficiary
              </button>
            </div>
          </div>
          </div>
         
        </div>

        <div v-else>
          <div class="d-flex justify-content-center mt-5">
            <div class="text-center" style="width: 400px">
              <Iconify
                icon="lets-icons:flash-light"
                style="font-size: 24px; color: black"
              />
              <p class="p-text text-gray my-4">
                You have no beneficiaries yet, click the button below to a add
                new beneficiary
              </p>
              <div class="d-grid">
                <div class="desktop-screen">
                  <button
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight3"
                    aria-controls="offcanvasRight3"
                    class="btn-primary px-4"
                  >
                    Add New Beneficiary
                  </button>
                </div>
                <div class="mobile-screen">
                  <button
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasBottomG"
                    aria-controls="offcanvasBottomG"
                    class="btn-primary px-4"
                  >
                    Add New Beneficiary
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </div>
    <div v-else>
    <div class="d-flex justify-content-center my-5">
      <div class="text-center" style="width: 400px">
        <Iconify icon="lets-icons:flash-light" style="font-size: 28px" />
        <h5 class="my-4">You have not signed in</h5>
        <p class="p-text text-gray mb-4">
          Please create an account or sign in to have access to your account
        </p>

        <div class="desktop-screen">  
          <div class="d-flex align-items-center justify-content-center ">
            <button
              data-bs-target="#exampleModalToggleB"
              data-bs-toggle="modal"
              class="btn-primary auth-btn me-3"
          
            >
              Create an Account
            </button>
            <button
              data-bs-target="#exampleModalToggleC"
              data-bs-toggle="modal"
              class="btn-primary auth-btn"
     
            >
              Login
            </button>
          </div>
        </div>

        <div class="mobile-screen"> 
          <div class="d-flex align-items-center justify-content-center ">
            <button
              class="btn-primary auth-btn me-3"
              data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasBottom10"
            aria-controls="offcanvasBottom10"
            >
              Create an Account
            </button>
            <button
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasBottom11"
            aria-controls="offcanvasBottom11"
              class="btn-primary auth-btn"
     
            >
              Login
            </button>
          </div>
        
        </div>
      </div>
    </div>
    <LoginMob></LoginMob>
    <NewUserLogin></NewUserLogin>
  </div>
   
  </div>
</template>

<style lang="scss" scoped>
.add-benefit {
  position: fixed;
  bottom: 120px;
  right: 20px;
  left: 20px;
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "page-card d-flex justify-content-center" }
const _hoisted_2 = {
  key: 0,
  class: "account-contents"
}
const _hoisted_3 = { class: "desktop-screen" }
const _hoisted_4 = { class: "b-nav border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_5 = {
  key: 0,
  class: "d-grid"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "desktop-screen" }
const _hoisted_9 = { class: "transaction-history mt-4 px-3" }
const _hoisted_10 = { class: "search" }
const _hoisted_11 = { class: "search-icon" }
const _hoisted_12 = { class: "table border rounded responsive mt-4" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "pagination d-flex align-items-center justify-content-between px-4 pb-4" }
const _hoisted_15 = { class: "previous box-shad text-disabled" }
const _hoisted_16 = { class: "box-shad text-disabled text-center" }
const _hoisted_17 = { class: "mobile-screen" }
const _hoisted_18 = { class: "d-flex my-4" }
const _hoisted_19 = { class: "search" }
const _hoisted_20 = { class: "search-icon" }
const _hoisted_21 = { class: "mt-4" }
const _hoisted_22 = { class: "d-flex align-items-center justify-content-start p-2 rounded" }
const _hoisted_23 = { class: "details d-flex justify-content-between align-items-center" }
const _hoisted_24 = { class: "fs-6 mb-2" }
const _hoisted_25 = { class: "p-text text-gray" }
const _hoisted_26 = { class: "d-flex align-items-center" }
const _hoisted_27 = { class: "text-end" }
const _hoisted_28 = { class: "text-gray p-text mb-3 capitalize" }
const _hoisted_29 = { class: "text-gray p-text" }
const _hoisted_30 = ["onClick"]
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { class: "d-flex justify-content-center mt-5" }
const _hoisted_33 = {
  class: "text-center",
  style: {"width":"400px"}
}
const _hoisted_34 = { key: 1 }
const _hoisted_35 = { class: "d-flex justify-content-center my-5" }
const _hoisted_36 = {
  class: "text-center",
  style: {"width":"400px"}
}

import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import EachBeneficiary from "@/components/BottomSheet/Beneficiary/EditBeneficiary.vue";
import AddNew from "@/components/BottomSheet/Beneficiary/AddNewBeneficiary.vue";
import NewUserLogin from "@/components/Modal/NewUserLogin.vue";
import LoginMob from "@/components/BottomSheet/CreateAccount/LoginMob.vue";
import MyAccount from "@/components/Dashboard/my-account.vue";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'beneficiary',
  setup(__props) {

const store = useStore();
const loadInfo = ref(false);
const loading = ref(false);
const beneficiaryList: any = ref([]);
const router = useRouter();
const oneBeneficiary : any = ref({});

const filter = ref({
  page:1,
  limit:10,
  from: null,
  to:null,
  name: ""
});


const getBeneficiary = () => {
  store.commit("setLoader", true);
  store.dispatch("get", `/beneficiary`).then((resp) => {
    store.commit("setLoader", false);
    beneficiaryList.value = resp.data.data;
    oneBeneficiary.value = beneficiaryList.value[0]
    loadInfo.value = true;

  }).catch((err) => {
    store.commit("setLoader", false);
  })
};

const setBeneficiary = (item: any) => {
  oneBeneficiary.value = item;
};

onMounted(() => {
  if (store.state.user) {
    getBeneficiary();
  }
});

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!
  const _component_iconify = _resolveComponent("iconify")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MyAccount),
    (_unref(store).state.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(EachBeneficiary, { oneBeneficiary: oneBeneficiary.value }, null, 8, ["oneBeneficiary"]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "desktop-screen" }, [
                _createElementVNode("h5", null, "Beneficiary")
              ], -1)),
              (beneficiaryList.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[7] || (_cache[7] = [
                    _createElementVNode("button", {
                      "data-bs-toggle": "offcanvas",
                      "data-bs-target": "#offcanvasRight3",
                      "aria-controls": "offcanvasRight3",
                      class: "btn-primary px-3"
                    }, " Add New Beneficiary ", -1)
                  ])))
                : _createCommentVNode("", true)
            ])
          ]),
          _createVNode(AddNew),
          (loadInfo.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (beneficiaryList.value.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("form", {
                              onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (getBeneficiary()), ["prevent"]))
                            }, [
                              _withDirectives(_createElementVNode("input", {
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filter.value.name) = $event)),
                                type: "search",
                                class: "input-field form-control ps-5",
                                placeholder: "Search by name"
                              }, null, 512), [
                                [_vModelText, filter.value.name]
                              ]),
                              _createElementVNode("div", _hoisted_11, [
                                _createVNode(_component_Iconify, {
                                  icon: "iconamoon:search-thin",
                                  onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (getBeneficiary()), ["prevent"]))
                                })
                              ])
                            ], 32)
                          ]),
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("table", null, [
                              _cache[9] || (_cache[9] = _createElementVNode("thead", null, [
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", null, "Meter Number"),
                                  _createElementVNode("th", null, "Electricity Provider"),
                                  _createElementVNode("th", null, "Meter Type"),
                                  _createElementVNode("th", null, "Name"),
                                  _createElementVNode("th")
                                ])
                              ], -1)),
                              _createElementVNode("tbody", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(beneficiaryList.value, (beneficiary, index) => {
                                  return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                    _createElementVNode("td", null, _toDisplayString(beneficiary.meter_no), 1),
                                    _createElementVNode("td", null, _toDisplayString(beneficiary.provider), 1),
                                    _createElementVNode("td", null, _toDisplayString(beneficiary.meter_type), 1),
                                    _createElementVNode("td", null, _toDisplayString(beneficiary.name), 1),
                                    _createElementVNode("td", null, [
                                      _createElementVNode("div", {
                                        "data-bs-toggle": "offcanvas",
                                        "data-bs-target": "#offcanvasRightD",
                                        "aria-controls": "offcanvasRightD",
                                        onClick: ($event: any) => (setBeneficiary(beneficiary))
                                      }, [
                                        _createVNode(_component_Iconify, {
                                          icon: "ri:more-line",
                                          style: {"color":"#ff6600"}
                                        })
                                      ], 8, _hoisted_13)
                                    ])
                                  ]))
                                }), 128))
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("span", null, [
                                _createVNode(_component_Iconify, {
                                  icon: "ep:back",
                                  style: {"font-size":"22px"}
                                })
                              ]),
                              _cache[10] || (_cache[10] = _createTextVNode(" Previous "))
                            ]),
                            _cache[12] || (_cache[12] = _createElementVNode("div", { class: "page-numb" }, "1", -1)),
                            _createElementVNode("div", _hoisted_16, [
                              _cache[11] || (_cache[11] = _createTextVNode(" Next ")),
                              _createElementVNode("span", null, [
                                _createVNode(_component_Iconify, {
                                  icon: "ep:right",
                                  style: {"font-size":"22px"}
                                })
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("div", {
                            class: "back-icon e-icon me-2",
                            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_unref(router).go(-1)), ["prevent"]))
                          }, [
                            _createVNode(_component_Iconify, { icon: "ep:arrow-left" })
                          ]),
                          _cache[13] || (_cache[13] = _createElementVNode("p", { class: "text-gray" }, "Select a beneficiary", -1))
                        ]),
                        _createElementVNode("div", _hoisted_19, [
                          _createElementVNode("form", {
                            onSubmit: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (getBeneficiary()), ["prevent"]))
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((filter.value.name) = $event)),
                              type: "search",
                              class: "input-field form-control ps-5",
                              placeholder: "Search by name"
                            }, null, 512), [
                              [_vModelText, filter.value.name]
                            ]),
                            _createElementVNode("div", _hoisted_20, [
                              _createVNode(_component_Iconify, {
                                icon: "iconamoon:search-thin",
                                onSubmit: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (getBeneficiary()), ["prevent"]))
                              })
                            ])
                          ], 32)
                        ]),
                        _createElementVNode("div", _hoisted_21, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(beneficiaryList.value, (beneficiary, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: index,
                              class: "each-benefit mb-2"
                            }, [
                              _createElementVNode("div", _hoisted_22, [
                                _cache[14] || (_cache[14] = _createElementVNode("div", {
                                  class: "bg-primary rounded-circle me-3",
                                  style: {"height":"10px","width":"10px"}
                                }, null, -1)),
                                _createElementVNode("div", _hoisted_23, [
                                  _createElementVNode("div", null, [
                                    _createElementVNode("p", _hoisted_24, _toDisplayString(beneficiary.meter_no), 1),
                                    _createElementVNode("p", _hoisted_25, _toDisplayString(beneficiary.provider), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_26, [
                                    _createElementVNode("div", _hoisted_27, [
                                      _createElementVNode("p", _hoisted_28, _toDisplayString(beneficiary.name), 1),
                                      _createElementVNode("p", _hoisted_29, _toDisplayString(beneficiary.meter_type), 1)
                                    ]),
                                    _createElementVNode("div", {
                                      "data-bs-toggle": "offcanvas",
                                      "data-bs-target": "#offcanvasBottomT",
                                      "aria-controls": "offcanvasBottomT",
                                      onClick: ($event: any) => (setBeneficiary(beneficiary))
                                    }, [
                                      _createVNode(_component_iconify, {
                                        icon: "ic:sharp-more-vert",
                                        style: {"font-size":"20px"}
                                      })
                                    ], 8, _hoisted_30)
                                  ])
                                ])
                              ])
                            ]))
                          }), 128)),
                          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "d-grid add-benefit" }, [
                            _createElementVNode("button", {
                              "data-bs-toggle": "offcanvas",
                              "data-bs-target": "#offcanvasBottomG",
                              "aria-controls": "offcanvasBottomG",
                              class: "btn-primary"
                            }, " Add New Beneficiary ")
                          ], -1))
                        ])
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_31, [
                      _createElementVNode("div", _hoisted_32, [
                        _createElementVNode("div", _hoisted_33, [
                          _createVNode(_component_Iconify, {
                            icon: "lets-icons:flash-light",
                            style: {"font-size":"24px","color":"black"}
                          }),
                          _cache[16] || (_cache[16] = _createStaticVNode("<p class=\"p-text text-gray my-4\" data-v-458f59d4> You have no beneficiaries yet, click the button below to a add new beneficiary </p><div class=\"d-grid\" data-v-458f59d4><div class=\"desktop-screen\" data-v-458f59d4><button data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasRight3\" aria-controls=\"offcanvasRight3\" class=\"btn-primary px-4\" data-v-458f59d4> Add New Beneficiary </button></div><div class=\"mobile-screen\" data-v-458f59d4><button data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottomG\" aria-controls=\"offcanvasBottomG\" class=\"btn-primary px-4\" data-v-458f59d4> Add New Beneficiary </button></div></div>", 2))
                        ])
                      ])
                    ]))
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("div", _hoisted_36, [
              _createVNode(_component_Iconify, {
                icon: "lets-icons:flash-light",
                style: {"font-size":"28px"}
              }),
              _cache[17] || (_cache[17] = _createStaticVNode("<h5 class=\"my-4\" data-v-458f59d4>You have not signed in</h5><p class=\"p-text text-gray mb-4\" data-v-458f59d4> Please create an account or sign in to have access to your account </p><div class=\"desktop-screen\" data-v-458f59d4><div class=\"d-flex align-items-center justify-content-center\" data-v-458f59d4><button data-bs-target=\"#exampleModalToggleB\" data-bs-toggle=\"modal\" class=\"btn-primary auth-btn me-3\" data-v-458f59d4> Create an Account </button><button data-bs-target=\"#exampleModalToggleC\" data-bs-toggle=\"modal\" class=\"btn-primary auth-btn\" data-v-458f59d4> Login </button></div></div><div class=\"mobile-screen\" data-v-458f59d4><div class=\"d-flex align-items-center justify-content-center\" data-v-458f59d4><button class=\"btn-primary auth-btn me-3\" data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottom10\" aria-controls=\"offcanvasBottom10\" data-v-458f59d4> Create an Account </button><button data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottom11\" aria-controls=\"offcanvasBottom11\" class=\"btn-primary auth-btn\" data-v-458f59d4> Login </button></div></div>", 4))
            ])
          ]),
          _createVNode(LoginMob),
          _createVNode(NewUserLogin)
        ]))
  ]))
}
}

})
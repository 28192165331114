import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "mobile-screen" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "d-flex align-items-center mb-4" }
const _hoisted_4 = { class: "initials" }
const _hoisted_5 = { class: "text-primary uppercase" }
const _hoisted_6 = { class: "ms-3" }
const _hoisted_7 = { class: "capitalize mb-2" }
const _hoisted_8 = { class: "profile-email" }
const _hoisted_9 = { class: "account-card border rounded mt-4" }
const _hoisted_10 = { class: "p-0 m-0" }
const _hoisted_11 = { class: "account-list border-bottom" }
const _hoisted_12 = { class: "d-flex align-items-center" }
const _hoisted_13 = { class: "account-list border-bottom" }
const _hoisted_14 = { class: "d-flex align-items-center" }
const _hoisted_15 = { class: "account-list border-bottom" }
const _hoisted_16 = { class: "d-flex align-items-center" }
const _hoisted_17 = { class: "account-list border-bottom" }
const _hoisted_18 = { class: "d-flex align-items-center" }
const _hoisted_19 = { class: "account-list" }
const _hoisted_20 = { class: "d-flex align-items-center" }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { class: "d-flex justify-content-center mt-5" }
const _hoisted_23 = {
  class: "text-center",
  style: {"width":"400px"}
}

import { ref } from "vue";
import { useStore } from "vuex";

import NewUserLogin from "@/components/Modal/NewUserLogin.vue";
import NewLoginMob from "@/components/BottomSheet/CreateAccount/LoginMob.vue";
import { useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'myAccountMOB',
  setup(__props) {

const router = useRouter();
const store = useStore();

const logout = () => {
  store.commit("logout");
};

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(store).state.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h4", _hoisted_5, _toDisplayString(_unref(store).state.user.first_name[0]) + _toDisplayString(_unref(store).state.user.last_name[0]), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h6", _hoisted_7, _toDisplayString(_unref(store).state.user.first_name) + " " + _toDisplayString(_unref(store).state.user.last_name), 1),
              _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(store).state.user.email), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("ul", _hoisted_10, [
              _createElementVNode("li", _hoisted_11, [
                _createVNode(_component_router_link, {
                  to: "/my-profile",
                  class: _normalizeClass([{ picked: _ctx.$route.name === 'My Profile' }, "each-list"])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_Iconify, {
                        icon: "solar:user-linear",
                        style: {"font-size":"22px"}
                      }),
                      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "menu-text ms-2" }, "My Profile", -1))
                    ]),
                    _createVNode(_component_Iconify, {
                      icon: "ep:arrow-right",
                      style: {"font-size":"22px"}
                    })
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _createElementVNode("li", _hoisted_13, [
                _createVNode(_component_router_link, {
                  to: "/beneficiary",
                  class: _normalizeClass([{ picked: _ctx.$route.name === 'Beneficiary' }, "each-list"])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_Iconify, {
                        icon: "solar:users-group-rounded-linear",
                        style: {"font-size":"22px"}
                      }),
                      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "menu-text ms-2" }, "Beneficiary", -1))
                    ]),
                    _createVNode(_component_Iconify, {
                      icon: "ep:arrow-right",
                      style: {"font-size":"22px"}
                    })
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _createElementVNode("li", _hoisted_15, [
                _createVNode(_component_router_link, {
                  to: "/notifications",
                  class: _normalizeClass([{ picked: _ctx.$route.name === 'Notification' }, "each-list"])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_Iconify, {
                        icon: "lets-icons:bell-light",
                        style: {"font-size":"22px"}
                      }),
                      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "menu-text ms-2" }, "Notifications", -1))
                    ]),
                    _createVNode(_component_Iconify, {
                      icon: "ep:arrow-right",
                      style: {"font-size":"22px"}
                    })
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _createElementVNode("li", _hoisted_17, [
                _createVNode(_component_router_link, {
                  to: "/update-password",
                  class: _normalizeClass([{ picked: _ctx.$route.name === 'Update Password' }, "each-list"])
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_Iconify, {
                        icon: "mage:security-shield",
                        style: {"font-size":"22px"}
                      }),
                      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "menu-text ms-2" }, "Update Password", -1))
                    ]),
                    _createVNode(_component_Iconify, {
                      icon: "ep:arrow-right",
                      style: {"font-size":"22px"}
                    })
                  ]),
                  _: 1
                }, 8, ["class"])
              ]),
              _createElementVNode("li", _hoisted_19, [
                _createElementVNode("div", {
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (logout()), ["prevent"])),
                  class: "each-list text-danger pointer"
                }, [
                  _createElementVNode("div", _hoisted_20, [
                    _createVNode(_component_Iconify, {
                      icon: "hugeicons:logout-01",
                      style: {"font-size":"22px"}
                    }),
                    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "menu-text ms-2" }, "Log Out", -1))
                  ])
                ])
              ])
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_Iconify, {
                icon: "lets-icons:flash-light",
                style: {"font-size":"28px"}
              }),
              _cache[6] || (_cache[6] = _createStaticVNode("<h5 class=\"my-4\">You have not signed in</h5><p class=\"p-text text-gray mb-4\"> Please create an account or sign in to have access to your account settings </p><div class=\"desktop-screen\"><div class=\"d-flex align-items-center justify-content-center\"><button data-bs-target=\"#exampleModalToggleB\" data-bs-toggle=\"modal\" class=\"btn-primary auth-btn me-3\"> Create an Account </button><button data-bs-target=\"#exampleModalToggleC\" data-bs-toggle=\"modal\" class=\"btn-primary auth-btn\"> Login </button></div></div><div class=\"mobile-screen\"><div class=\"d-flex align-items-center justify-content-center\"><button class=\"btn-primary auth-btn me-3\" data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottom10\" aria-controls=\"offcanvasBottom10\"> Create an Account </button><button data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottom11\" aria-controls=\"offcanvasBottom11\" class=\"btn-primary auth-btn\"> Login </button></div></div>", 4))
            ])
          ]),
          _createVNode(NewLoginMob),
          _createVNode(NewUserLogin)
        ]))
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "navbar-wrapper" }
const _hoisted_2 = { class: "navbar-items" }
const _hoisted_3 = { class: "d-flex align-items-center" }
const _hoisted_4 = { class: "fs-5" }
const _hoisted_5 = { class: "desktop-screen" }
const _hoisted_6 = { class: "d-flex align-items-center" }
const _hoisted_7 = {
  key: 0,
  class: "d-flex align-items-center me-3 px-4 border-end"
}
const _hoisted_8 = { class: "navbar-profile d-flex align-items-center" }
const _hoisted_9 = { class: "user-icon border d-flex justify-content-center align-items-center" }

import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'Navbar',
  setup(__props) {

const route = useRoute();
const store = useStore();

const logout = () => {
  store.commit("logout");
};

const showsidebar = computed(() => {
  return store.state.showsidebar;
});

const toggleSidebar = () => {
  store.commit("toggleSidebar", !showsidebar.value);
};

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Iconify, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleSidebar())),
          class: "me-3 toggler",
          style: {"font-size":"24px"},
          icon: "solar:hamburger-menu-outline"
        }),
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(route).name), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          (_unref(store).state.user)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("span", null, [
                  _createVNode(_component_Iconify, {
                    icon: "bi:phone",
                    stye: "font-size: 24px;"
                  }),
                  _createTextVNode(" " + _toDisplayString(_unref(store).state.user ? _unref(store).state.user.phone : ""), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_Iconify, { icon: "solar:user-outline" })
            ]),
            _createElementVNode("div", null, [
              _cache[3] || (_cache[3] = _createElementVNode("span", {
                class: "dropdown-toggle ms-2",
                id: "dropdownMenuButton1",
                "data-bs-toggle": "dropdown",
                "aria-expanded": "false"
              }, null, -1)),
              _createElementVNode("div", {
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (logout()), ["prevent"])),
                class: "mt-4 px-2 shadow pointer border-0 dropdown-menu",
                "aria-labelledby": "dropdownMenuButton1"
              }, [
                _createVNode(_component_Iconify, {
                  style: {"font-size":"22px"},
                  class: "text-danger pointer",
                  icon: "tabler:logout-2"
                }),
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "menu-text ms-2" }, "Log Out", -1))
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})
<template>
  <div class="conta">
  <div class="loader"></div> 
  </div>
</template>

<style lang="scss" scoped>

.conta{
 width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  position: fixed;
  background-color: #26232372;

  .loader {
  --d:27px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  color: #ff6600;
  box-shadow: 
    calc(1*var(--d))      calc(0*var(--d))     0 0,
    calc(0.707*var(--d))  calc(0.707*var(--d)) 0 1px,
    calc(0*var(--d))      calc(1*var(--d))     0 2px,
    calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3px,
    calc(-1*var(--d))     calc(0*var(--d))     0 4px,
    calc(-0.707*var(--d)) calc(-0.707*var(--d))0 5px,
    calc(0*var(--d))      calc(-1*var(--d))    0 6px;
  animation: l27 1s infinite steps(8);
}

}
@keyframes l27 {
  100% {transform: rotate(1turn)}
}





</style>
import { useToast } from "vue-toast-notification";

export const toggleSidebar = (state:any, displaySidebar: boolean) => {
  state.showsidebar = displaySidebar
};

export const setUser = (state: any, user: any) => {
  state.user = user.data;
  state.token = user.accessToken;
  var result = encodeURIComponent(JSON.stringify(user));
  localStorage.setItem("tao", result);
};

export const getUser = (state: any) => {
  var data = localStorage.getItem("tao");
  if (data) {
    var js = JSON.parse(decodeURIComponent(data));
    state.user = js.data;
    state.token = js.accessToken;
  }
};

export const logout = (state: any) => {
  window.localStorage.removeItem("tao");
  state.user = null;
  state.token = null;
  window.location.href = "/buy-energy-app";
};

export const setLoader = (state: any, showLoader: boolean) => {
  state.loader = showLoader;
};

import { RouteRecordRaw } from "vue-router";
import Layout from "@/views/dashboard/layout.vue";
import Track from "@/views/dashboard/track/track.vue";
import MyProfile from "@/views/dashboard/myAccount/my-profile.vue";
import MyAccount from "@/components/Dashboard/my-account.vue";
import Beneficiary from "@/views/dashboard/myAccount/beneficiary.vue";
import Notifications from "@/views/dashboard/myAccount/notifications.vue";
import UpdatePassword from "@/views/dashboard/myAccount/update-password.vue";

import EnergyAudit from "@/views/dashboard/track/energy-audit.vue";
import Electricity from "@/views/dashboard/Electricity.vue";

import Account from "@/views/dashboard/myAccountMOB.vue";

const dashboardRoutes: Array<RouteRecordRaw> = [
  {
    path: "/layout",
    component: Layout,
    children: [
      {
        path: "/buy-electricity",
        component: Electricity,
        name: "Buy Electricity",
      },
      {
        path: "/track",
        name: "Buy Solar",
        component: Track,
      },
      {
        path: "/energy-audit",
        name: "Energy-Audit",
        component: EnergyAudit,
      },

      {
        path: "/my-account",
        name: "My Account",
        component: MyAccount,
      },

      {
        path: "/my-account",
        name: "My Account",
        component: Account,
      },

      {
        path: "/my-profile",
        name: "My Profile",
        component: MyProfile,
      },
      {
        path: "/beneficiary",
        name: "Beneficiary",
        component: Beneficiary,
      },
      {
        path: "/notifications",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: "/update-password",
        name: "Update Password",
        component: UpdatePassword,
      },
    ],
  },
];

export default dashboardRoutes;

<script lang="ts" setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { formatDateTime } from "@/core/utils/helpers";
import { useToast } from "vue-toast-notification";


const store = useStore();

const props = defineProps({
  transaction: {},
  serviceProvider: {} as any
});
const trans: any = computed(() => {
  return props.transaction
});

const refreshPage = () =>{
 window.setTimeout(() =>{
  window.location.reload();
 }, 1500
 );
}

const makePayment = () => {
  store
    .dispatch("post", {
      endpoint: "/purchase",
      details: {
        beneficiary: false,
        meter_no: trans.value.meter_no,
        provider: props.serviceProvider.serviceID,
        meter_type: trans.value.meter_type,
        phone: trans.value.phone,
        amount: trans.value.amount,
        meter_name: trans.value.meter_name,
      },
    })
    .then((resp) => {
      useToast().success("Processing");
      window.location.href = resp.data.data;
    })
    .catch((err) => {
  })
};
</script>

<template>
  <div v-if="trans">
    <div
      class="offcanvas offcanvas-end right-sheet"
      tabindex="-1"
      id="offcanvasRightA"
      aria-labelledby="offcanvasRightLabelA"
    >
      <div class="offcanvas-header border-bottom py-4 d-block">
        <div class="mb-3">
          <button
            type="button"
            class="bg-white me-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <Iconify icon="ep:back" />
          </button>
          <span>Back</span>
        </div>

        <h5 id="offcanvasRightLabelA">Transactions Details</h5>

        <div class="border p-2 mt-4 rounded">
          <div class="d-flex justify-content-between align-items-center mb-3 fs-14">
            <p>Reference</p>
            <p>{{ trans.ref }}</p>
          </div>
          <div class="d-flex justify-content-between align-items-center fs-14">
            <p>Paid At</p>
            <p>{{ formatDateTime(trans.created_at) }}</p>
          </div>
        </div>
      </div>
      <div class="offcanvas-body">
        <div class="meter-token my-3 rounded">
          <div class="d-flex p-3 align-items-center justify-content-between">
            <p class="meter p-text">Meter Token</p>
             <p v-if="trans.token" class="met-no">{{ trans.token }}</p>
            <div v-else>
              <button type="button" class="btn-normal" @click.prevent="refreshPage">Refresh</button>
            </div>
          </div>
        </div>
        <div class="card-wrapper border rounded">
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Provider</p>
            <div class="provider-logo p-2 rounded text-end">
              <img height="50px" width="70px"  :src="serviceProvider.image"/>
            </div>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Meter Type</p>
            <p class="p-text text-end">{{ trans.meter_type }}</p>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Meter Number</p>
            <p class="p-text text-end">{{ trans.meter_no }}</p>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Meter Name</p>
            <p class="p-text text-end">{{ trans.meter_name }}</p>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Amount</p>
            <p class="p-text text-end">NGN {{ trans.amount }}</p>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Fee</p>
            <p class="p-text text-end">NGN 100</p>
          </div>
        </div>

        <div class="d-grid mt-4">
          <button @click.prevent="makePayment()" class="btn-primary border-0">
            <span class="fw-bold">Purchase Again</span>
          </button>
        </div>
      </div>
    </div>

    <div
      class="offcanvas offcanvas-bottom full-size"
      tabindex="-1"
      id="offcanvasBottomC"
    aria-labelledby="offcanvasBottomLabelC"
    >
      <div class="offcanvas-header border-bottom py-4 d-block">
        <div class="d-flex align-items-center justify-content-between">
          <h5 id="offcanvasBottomLabelC">Transactions Details</h5>
     <div class="close-btn me-4">  <button
            type="button"
            class="bg-white  btn-close "
            data-bs-dismiss="offcanvas"
            aria-label="Close"
       
          >
          </button></div>
      </div>

        <div class="border p-2 mt-4 rounded">
          <div class="d-flex justify-content-between align-items-center mb-3 fs-14">
            <p>Reference</p>
            <p >{{ trans.ref }}</p>
          </div>
          <div class="d-flex justify-content-between align-items-center fs-14">
            <p>Paid At</p>
            <p>{{ formatDateTime(trans.created_at) }}</p>
          </div>
        </div>
      </div>
      <div class="offcanvas-body">
        <div class="meter-token my-3 rounded">
          <div class="d-flex p-3 align-items-center justify-content-between">
            <p class="meter p-text">Meter Token</p>
             <p v-if="trans.token" class="met-no">{{ trans.token }}</p>
            <div v-else>
              <button type="button" class="btn-normal" @click.prevent="refreshPage">Refresh</button>
            </div>
          </div>
        </div>
        <div class="card-wrapper border rounded">
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Provider</p>
            <div class="provider-logo p-2 rounded">
              <img height="50px" width="70px"  :src="serviceProvider.image"/>
            </div>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Meter Type</p>
            <p class="p-text">{{ trans.meter_type }}</p>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Meter Number</p>
            <p class="p-text">{{ trans.meter_no }}</p>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Meter Name</p>
            <p class="p-text">{{ trans.meter_name }}</p>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Amount</p>
            <p class="p-text">NGN {{ trans.amount }}</p>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Fee</p>
            <p class="p-text">NGN 100</p>
          </div>
        </div>

        <div class="d-grid mt-4">
          <button @click.prevent="makePayment()" class="btn-primary border-0">
            <span class="fw-bold">Purchase Again</span>
          </button>
        </div>
      </div>
    </div>
  </div>


</template>

<style lang="scss" scoped>

</style>

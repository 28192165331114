import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "offcanvas offcanvas-end right-sheet",
  tabindex: "-1",
  id: "offcanvasRight6",
  "aria-labelledby": "offcanvasRightLabel6"
}
const _hoisted_3 = { class: "offcanvas-header border-bottom py-4 d-block" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = {
  type: "button",
  class: "bg-white me-2",
  "data-bs-dismiss": "offcanvas",
  "aria-label": "Close"
}
const _hoisted_6 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_7 = { class: "text-gray" }
const _hoisted_8 = { class: "offcanvas-body side-body p-4" }
const _hoisted_9 = { class: "each-card mb-4" }
const _hoisted_10 = { class: "card-wrapper border rounded" }
const _hoisted_11 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_12 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_13 = { class: "p-text" }
const _hoisted_14 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_15 = { class: "p-text" }
const _hoisted_16 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_17 = { class: "p-text" }
const _hoisted_18 = { class: "each-card" }
const _hoisted_19 = { class: "card-wrapper border rounded" }
const _hoisted_20 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_21 = { class: "p-text" }
const _hoisted_22 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_23 = { class: "p-text" }
const _hoisted_24 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_25 = { class: "p-text" }
const _hoisted_26 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_27 = { class: "p-text" }
const _hoisted_28 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_29 = { class: "p-text" }
const _hoisted_30 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_31 = { class: "p-text" }
const _hoisted_32 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_33 = { class: "p-text" }
const _hoisted_34 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_35 = { class: "p-text" }
const _hoisted_36 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_37 = { class: "p-text" }
const _hoisted_38 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_39 = { class: "p-text" }
const _hoisted_40 = {
  class: "offcanvas offcanvas-bottom full-size",
  tabindex: "-1",
  id: "offcanvasBottomG",
  "aria-labelledby": "offcanvasBottomLabelG"
}
const _hoisted_41 = { class: "offcanvas-header border-bottom py-4 d-block" }
const _hoisted_42 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_43 = { class: "text-gray mt-2" }
const _hoisted_44 = { class: "offcanvas-body p-4" }
const _hoisted_45 = { class: "each-card mb-4" }
const _hoisted_46 = { class: "card-wrapper border rounded" }
const _hoisted_47 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_48 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_49 = { class: "p-text" }
const _hoisted_50 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_51 = { class: "p-text" }
const _hoisted_52 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_53 = { class: "p-text" }
const _hoisted_54 = { class: "each-card" }
const _hoisted_55 = { class: "card-wrapper border rounded" }
const _hoisted_56 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_57 = { class: "p-text" }
const _hoisted_58 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_59 = { class: "p-text" }
const _hoisted_60 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_61 = { class: "p-text" }
const _hoisted_62 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_63 = { class: "p-text" }
const _hoisted_64 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_65 = { class: "p-text" }
const _hoisted_66 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_67 = { class: "p-text" }
const _hoisted_68 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_69 = { class: "p-text" }
const _hoisted_70 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_71 = { class: "p-text" }
const _hoisted_72 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_73 = { class: "p-text" }
const _hoisted_74 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_75 = { class: "p-text" }

import { ref, computed } from "vue";
import { formatDate } from "@/core/utils/helpers";




export default /*@__PURE__*/_defineComponent({
  __name: 'office',
  props: {
apiOfficeRes : {}
},
  setup(__props) {

const props = __props;

const offices : any =  computed(() => {
  return props.apiOfficeRes
})



return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (offices.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", _hoisted_5, [
                _createVNode(_component_Iconify, { icon: "ep:back" })
              ]),
              _cache[0] || (_cache[0] = _createElementVNode("span", null, "Back", -1))
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_6, [
                _cache[1] || (_cache[1] = _createElementVNode("h5", { id: "offcanvasRightLabel6" }, "Office Space", -1)),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(formatDate)(offices.value.created_at)), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-primary mb-3" }, "Contact Person Information", -1)),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-gray p-text" }, "Company Name", -1)),
                  _createElementVNode("p", null, _toDisplayString(offices.value.name), 1)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-gray p-text" }, "Contact Person Name", -1)),
                  _createElementVNode("p", _hoisted_13, _toDisplayString(offices.value.contact_name), 1)
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _cache[4] || (_cache[4] = _createElementVNode("p", { class: "text-gray p-text" }, "Contact Phone Number", -1)),
                  _createElementVNode("p", _hoisted_15, _toDisplayString(offices.value.phone), 1)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _cache[5] || (_cache[5] = _createElementVNode("p", { class: "text-gray p-text" }, "Company Address", -1)),
                  _createElementVNode("p", _hoisted_17, _toDisplayString(offices.value.address), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _cache[17] || (_cache[17] = _createElementVNode("p", { class: "text-primary mb-3" }, "Current Electricity Source", -1)),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _cache[7] || (_cache[7] = _createElementVNode("p", { class: "text-gray p-text" }, "Grid (hours)", -1)),
                  _createElementVNode("p", _hoisted_21, _toDisplayString(offices.value.gridHours), 1)
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _cache[8] || (_cache[8] = _createElementVNode("p", { class: "text-gray p-text" }, "Estimated monthly grid cost", -1)),
                  _createElementVNode("p", _hoisted_23, _toDisplayString(offices.value.gridCost), 1)
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _cache[9] || (_cache[9] = _createElementVNode("p", { class: "text-gray p-text" }, " Generator (Fuel) ", -1)),
                  _createElementVNode("p", _hoisted_25, _toDisplayString(offices.value.gen), 1)
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-gray p-text" }, "Generator size (KVA)", -1)),
                  _createElementVNode("p", _hoisted_27, _toDisplayString(offices.value.genSize) + " KVA", 1)
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _cache[11] || (_cache[11] = _createElementVNode("p", { class: "text-gray p-text" }, " Estimated monthly fuel (diesel) cost ", -1)),
                  _createElementVNode("p", _hoisted_29, _toDisplayString(offices.value.fuel), 1)
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _cache[12] || (_cache[12] = _createElementVNode("p", { class: "text-gray p-text" }, "Number of rooms in the building", -1)),
                  _createElementVNode("p", _hoisted_31, _toDisplayString(offices.value.rooms), 1)
                ]),
                _createElementVNode("div", _hoisted_32, [
                  _cache[13] || (_cache[13] = _createElementVNode("p", { class: "text-gray p-text" }, "Inverter (capacity - KVA)", -1)),
                  _createElementVNode("p", _hoisted_33, _toDisplayString(offices.value.inverter) + " KVA", 1)
                ]),
                _createElementVNode("div", _hoisted_34, [
                  _cache[14] || (_cache[14] = _createElementVNode("p", { class: "text-gray p-text" }, "Estimated daytime peak load in kW", -1)),
                  _createElementVNode("p", _hoisted_35, _toDisplayString(offices.value.dayPeakLoad) + " kW", 1)
                ]),
                _createElementVNode("div", _hoisted_36, [
                  _cache[15] || (_cache[15] = _createElementVNode("p", { class: "text-gray p-text" }, " Estimated night-time peak load in kW ", -1)),
                  _createElementVNode("p", _hoisted_37, _toDisplayString(offices.value.nightPeakLoad), 1)
                ]),
                _createElementVNode("div", _hoisted_38, [
                  _cache[16] || (_cache[16] = _createElementVNode("p", { class: "text-gray p-text" }, "Estimated Daily Consumption in kWh", -1)),
                  _createElementVNode("p", _hoisted_39, _toDisplayString(offices.value.dayConsumption) + " kWh", 1)
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_40, [
          _createElementVNode("div", _hoisted_41, [
            _createElementVNode("div", _hoisted_42, [
              _createElementVNode("div", null, [
                _cache[18] || (_cache[18] = _createElementVNode("h5", { id: "offcanvasBottomLabelG" }, "Office Space", -1)),
                _createElementVNode("p", _hoisted_43, _toDisplayString(_unref(formatDate)(offices.value.created_at)), 1)
              ]),
              _cache[19] || (_cache[19] = _createElementVNode("div", { class: "back-icon" }, [
                _createElementVNode("button", {
                  type: "button",
                  class: "me-2 btn-close",
                  "data-bs-dismiss": "offcanvas",
                  "aria-label": "Close"
                })
              ], -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_44, [
            _createElementVNode("div", _hoisted_45, [
              _cache[24] || (_cache[24] = _createElementVNode("p", { class: "text-primary mb-3" }, "Contact Person Information", -1)),
              _createElementVNode("div", _hoisted_46, [
                _createElementVNode("div", _hoisted_47, [
                  _cache[20] || (_cache[20] = _createElementVNode("p", { class: "text-gray p-text" }, "Company Name", -1)),
                  _createElementVNode("p", null, _toDisplayString(offices.value.name), 1)
                ]),
                _createElementVNode("div", _hoisted_48, [
                  _cache[21] || (_cache[21] = _createElementVNode("p", { class: "text-gray p-text" }, "Contact Person Name", -1)),
                  _createElementVNode("p", _hoisted_49, _toDisplayString(offices.value.contact_name), 1)
                ]),
                _createElementVNode("div", _hoisted_50, [
                  _cache[22] || (_cache[22] = _createElementVNode("p", { class: "text-gray p-text" }, "Contact Phone Number", -1)),
                  _createElementVNode("p", _hoisted_51, _toDisplayString(offices.value.phone), 1)
                ]),
                _createElementVNode("div", _hoisted_52, [
                  _cache[23] || (_cache[23] = _createElementVNode("p", { class: "text-gray p-text" }, "Company Address", -1)),
                  _createElementVNode("p", _hoisted_53, _toDisplayString(offices.value.address), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_54, [
              _cache[35] || (_cache[35] = _createElementVNode("p", { class: "text-primary mb-3" }, "Current Electricity Source", -1)),
              _createElementVNode("div", _hoisted_55, [
                _createElementVNode("div", _hoisted_56, [
                  _cache[25] || (_cache[25] = _createElementVNode("p", { class: "text-gray p-text" }, "Grid (hours)", -1)),
                  _createElementVNode("p", _hoisted_57, "U" + _toDisplayString(offices.value.gridHours), 1)
                ]),
                _createElementVNode("div", _hoisted_58, [
                  _cache[26] || (_cache[26] = _createElementVNode("p", { class: "text-gray p-text" }, "Estimated monthly grid cost", -1)),
                  _createElementVNode("p", _hoisted_59, _toDisplayString(offices.value.gridCost), 1)
                ]),
                _createElementVNode("div", _hoisted_60, [
                  _cache[27] || (_cache[27] = _createElementVNode("p", { class: "text-gray p-text" }, " Generator (Fuel) ", -1)),
                  _createElementVNode("p", _hoisted_61, _toDisplayString(offices.value.gen), 1)
                ]),
                _createElementVNode("div", _hoisted_62, [
                  _cache[28] || (_cache[28] = _createElementVNode("p", { class: "text-gray p-text" }, "Generator size (KVA)", -1)),
                  _createElementVNode("p", _hoisted_63, _toDisplayString(offices.value.genSize) + " KVA", 1)
                ]),
                _createElementVNode("div", _hoisted_64, [
                  _cache[29] || (_cache[29] = _createElementVNode("p", { class: "text-gray p-text" }, " Estimated monthly fuel (diesel) cost ", -1)),
                  _createElementVNode("p", _hoisted_65, _toDisplayString(offices.value.fuel), 1)
                ]),
                _createElementVNode("div", _hoisted_66, [
                  _cache[30] || (_cache[30] = _createElementVNode("p", { class: "text-gray p-text" }, "Number of rooms in the building", -1)),
                  _createElementVNode("p", _hoisted_67, _toDisplayString(offices.value.rooms), 1)
                ]),
                _createElementVNode("div", _hoisted_68, [
                  _cache[31] || (_cache[31] = _createElementVNode("p", { class: "text-gray p-text" }, "Inverter (capacity - KVA)", -1)),
                  _createElementVNode("p", _hoisted_69, _toDisplayString(offices.value.inverter) + " KVA", 1)
                ]),
                _createElementVNode("div", _hoisted_70, [
                  _cache[32] || (_cache[32] = _createElementVNode("p", { class: "text-gray p-text" }, "Estimated daytime peak load in kW", -1)),
                  _createElementVNode("p", _hoisted_71, _toDisplayString(offices.value.dayPeakLoad) + " kW", 1)
                ]),
                _createElementVNode("div", _hoisted_72, [
                  _cache[33] || (_cache[33] = _createElementVNode("p", { class: "text-gray p-text" }, " Estimated night-time peak load in kW ", -1)),
                  _createElementVNode("p", _hoisted_73, _toDisplayString(offices.value.nightPeakLoad) + " kW ", 1)
                ]),
                _createElementVNode("div", _hoisted_74, [
                  _cache[34] || (_cache[34] = _createElementVNode("p", { class: "text-gray p-text" }, "Estimated Daily Consumption in kWh", -1)),
                  _createElementVNode("p", _hoisted_75, _toDisplayString(offices.value.dayConsumption) + " kWh", 1)
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})
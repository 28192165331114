import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "offcanvas offcanvas-end right-sheet",
  tabindex: "-1",
  id: "offcanvasRight7",
  "aria-labelledby": "offcanvasRightLabel7"
}
const _hoisted_3 = { class: "offcanvas-header border-bottom py-4 d-block" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = {
  type: "button",
  class: "bg-white me-2",
  "data-bs-dismiss": "offcanvas",
  "aria-label": "Close"
}
const _hoisted_6 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_7 = { class: "text-gray" }
const _hoisted_8 = { class: "offcanvas-body p-4" }
const _hoisted_9 = { class: "each-card mb-4" }
const _hoisted_10 = { class: "card-wrapper border rounded" }
const _hoisted_11 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_12 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_13 = { class: "p-text" }
const _hoisted_14 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_15 = { class: "p-text" }
const _hoisted_16 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_17 = { class: "p-text" }
const _hoisted_18 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_19 = { class: "p-text" }
const _hoisted_20 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_21 = { class: "p-text" }
const _hoisted_22 = {
  class: "offcanvas offcanvas-bottom full-size",
  tabindex: "-1",
  id: "offcanvasBottomF",
  "aria-labelledby": "offcanvasBottomLabelF"
}
const _hoisted_23 = { class: "offcanvas-header border-bottom mb-4 d-block" }
const _hoisted_24 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_25 = { class: "text-gray mt-2" }
const _hoisted_26 = { class: "offcanvas-body p-3" }
const _hoisted_27 = { class: "each-card mb-4" }
const _hoisted_28 = { class: "card-wrapper border rounded" }
const _hoisted_29 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_30 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_31 = { class: "p-text" }
const _hoisted_32 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_33 = { class: "p-text" }
const _hoisted_34 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_35 = { class: "p-text" }
const _hoisted_36 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_37 = { class: "p-text" }
const _hoisted_38 = { class: "provider p-3 border-bottom d-flex justify-content-between align-items-center" }
const _hoisted_39 = { class: "p-text" }

import { ref, computed } from "vue";
import { formatDate } from "@/core/utils/helpers";


export default /*@__PURE__*/_defineComponent({
  __name: 'Home',
  props: {
  apiHomeRes: {},
},
  setup(__props) {

const props = __props;

const homes: any = computed(() => {
  return props.apiHomeRes;
});

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (homes.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("button", _hoisted_5, [
                _createVNode(_component_Iconify, { icon: "ep:back" })
              ]),
              _cache[0] || (_cache[0] = _createElementVNode("span", null, "Back", -1))
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_6, [
                _cache[1] || (_cache[1] = _createElementVNode("h5", { id: "offcanvasRightLabel7" }, "Home", -1)),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(formatDate)(homes.value.created_at)), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _cache[8] || (_cache[8] = _createElementVNode("p", { class: "text-primary mb-3" }, "Contact Person Information", -1)),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-gray p-text" }, "Name", -1)),
                  _createElementVNode("p", null, _toDisplayString(homes.value.name), 1)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-gray p-text" }, "Address", -1)),
                  _createElementVNode("p", _hoisted_13, _toDisplayString(homes.value.address), 1)
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _cache[4] || (_cache[4] = _createElementVNode("p", { class: "text-gray p-text" }, "Phone Number", -1)),
                  _createElementVNode("p", _hoisted_15, _toDisplayString(homes.value.phone), 1)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _cache[5] || (_cache[5] = _createElementVNode("p", { class: "text-gray p-text" }, "Email", -1)),
                  _createElementVNode("p", _hoisted_17, _toDisplayString(homes.value.email), 1)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-gray p-text" }, "Number of Rooms", -1)),
                  _createElementVNode("p", _hoisted_19, _toDisplayString(homes.value.rooms), 1)
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _cache[7] || (_cache[7] = _createElementVNode("p", { class: "text-gray p-text" }, "Home Appliances", -1)),
                  _createElementVNode("p", _hoisted_21, _toDisplayString(homes.value.appliances), 1)
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", null, [
                _cache[9] || (_cache[9] = _createElementVNode("h5", { id: "offcanvasBottomLabelF" }, "Home", -1)),
                _createElementVNode("p", _hoisted_25, _toDisplayString(_unref(formatDate)(homes.value.created_at)), 1)
              ]),
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "back-icon" }, [
                _createElementVNode("button", {
                  type: "button",
                  class: "me-2 btn-close",
                  "data-bs-dismiss": "offcanvas",
                  "aria-label": "Close"
                })
              ], -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _cache[17] || (_cache[17] = _createElementVNode("p", { class: "text-primary mb-3" }, "Contact Person Information", -1)),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _cache[11] || (_cache[11] = _createElementVNode("p", { class: "text-gray p-text" }, "Name", -1)),
                  _createElementVNode("p", null, _toDisplayString(homes.value.name), 1)
                ]),
                _createElementVNode("div", _hoisted_30, [
                  _cache[12] || (_cache[12] = _createElementVNode("p", { class: "text-gray p-text" }, "Address", -1)),
                  _createElementVNode("p", _hoisted_31, _toDisplayString(homes.value.address), 1)
                ]),
                _createElementVNode("div", _hoisted_32, [
                  _cache[13] || (_cache[13] = _createElementVNode("p", { class: "text-gray p-text" }, "Phone Number", -1)),
                  _createElementVNode("p", _hoisted_33, _toDisplayString(homes.value.phone), 1)
                ]),
                _createElementVNode("div", _hoisted_34, [
                  _cache[14] || (_cache[14] = _createElementVNode("p", { class: "text-gray p-text" }, "Email", -1)),
                  _createElementVNode("p", _hoisted_35, _toDisplayString(homes.value.email), 1)
                ]),
                _createElementVNode("div", _hoisted_36, [
                  _cache[15] || (_cache[15] = _createElementVNode("p", { class: "text-gray p-text" }, "Number of Rooms", -1)),
                  _createElementVNode("p", _hoisted_37, _toDisplayString(homes.value.rooms), 1)
                ]),
                _createElementVNode("div", _hoisted_38, [
                  _cache[16] || (_cache[16] = _createElementVNode("p", { class: "text-gray p-text" }, "Home Appliances", -1)),
                  _createElementVNode("p", _hoisted_39, _toDisplayString(homes.value.appliances), 1)
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})
<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const loadInfo = ref(false);
const loading = ref(false);
const beneficiaryList: any = ref([]);
const emit = defineEmits(['selectBeneficiary']);

const getBeneficiary = () => {
  store.commit("setLoader", true);
  store.dispatch("get", `/beneficiary`).then((resp) => {
    store.commit("setLoader", false);
    beneficiaryList.value = resp.data.data;
    loadInfo.value = true;
  }).catch((err) => {
    store.commit("setLoader", false);
  })
};

const selectBeneficiary = (beneficiary : any) => {
  emit('selectBeneficiary', beneficiary );
 
};

onMounted(() => {
  if (store.state.user) {
    getBeneficiary();
    
  }
});
</script>

<template>

    <div
      class="offcanvas offcanvas-end right-sheet"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header border-bottom py-4 d-block">
        <div class="mb-3">
          <button
            type="button"
            class="bg-white me-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <Iconify icon="ep:back" />
          </button>
          <span>Back</span>
        </div>

        <h5 id="offcanvasRightLabel">Beneficiaries</h5>
      </div>

      <div v-if="loadInfo" class="offcanvas-body p-0">
        <div v-if="beneficiaryList.length > 0">
          <div class="search-input m-3">
            <form @submit.prevent="getBeneficiary()">
              <input
                type="search"
                class="input-field form-control ps-5"
                placeholder="Search history"
              />
              <div class="search-icon">
                <Iconify
                  icon="iconamoon:search-thin"
                  @submit.prevent="getBeneficiary()"
                />
              </div>
            </form>
          </div>

          <p class="p-3 text-gray">Select a beneficiary</p>

          <div
            v-for="(beneficiary, index) in beneficiaryList"
            :key="index"
            class="each-benefit"
          >
            <div
              @click="selectBeneficiary(beneficiary)"
              class="d-flex p-3 align-items-center justify-content-start"
            >
              <div
                class="bg-primary rounded-circle me-3"
                style="height: 10px; width: 10px"
              ></div>
              <div
                class="details d-flex justify-content-between align-items-center"
              >
                <div>
                  <p class="fs-6 mb-2">{{ beneficiary.meter_no }}</p>
                  <p class="p-text text-gray">
                    {{ beneficiary.provider }}
                  </p>
                </div>
                <div class="text-end">
                  <p class="text-gray p-text mb-2">{{ beneficiary.name }}</p>
                  <p class="text-gray p-text">{{ beneficiary.meter_type }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="d-flex justify-content-center mt-5">
            You have no Beneficiaries yet
          </div>
        </div>
      </div>
    </div>

    <div
      class="offcanvas offcanvas-bottom full-size"
      tabindex="-1"
      id="offcanvasBottom"
      aria-labelledby="offcanvasBottomLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasBottomLabel">
        Beneficiaries
        </h5>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div v-if="loadInfo" class="offcanvas-body p-0">
        <div v-if="beneficiaryList.length > 0">
          <div class="search-input m-3">
            <form @submit.prevent="getBeneficiary()">
              <input
                type="search"
                class="input-field form-control ps-5"
                placeholder="Search history"
              />
              <div class="search-icon">
                <Iconify
                  icon="iconamoon:search-thin"
                  @submit.prevent="getBeneficiary()"
                />
              </div>
            </form>
          </div>

          <p class="p-3 text-gray">Select a beneficiary</p>

          <div
            v-for="(beneficiary, index) in beneficiaryList"
            :key="index"
            class="each-benefit"
          >
            <div
              @click="selectBeneficiary(beneficiary)"
              class="d-flex p-3 align-items-center justify-content-start"
            >
              <div
                class="bg-primary rounded-circle me-3"
                style="height: 10px; width: 10px"
              ></div>
              <div
                class="details d-flex justify-content-between align-items-center"
              >
                <div>
                  <p class="fs-6 mb-2">{{ beneficiary.meter_no }}</p>
                  <p class="p-text text-gray">
                    {{ beneficiary.provider }}
                  </p>
                </div>
                <div class="text-end" >
                  <p class="text-gray p-text mb-2">{{ beneficiary.name }}</p>
                  <p class="text-gray p-text">{{ beneficiary.meter_type }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="d-flex justify-content-center mt-5">
            You have no Beneficiaries yet
          </div>
        </div>
      </div>
    </div>


</template>

<style lang="scss" scoped>

</style>

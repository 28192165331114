import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "l-wrapper" }
const _hoisted_2 = { class: "all-section policy-page" }
const _hoisted_3 = { class: "screen-view" }
const _hoisted_4 = { class: "formm" }
const _hoisted_5 = { class: "lsd me-4" }
const _hoisted_6 = { class: "p-3 rounded-lg mt-4 bg-graying" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "dab each-space" }
const _hoisted_9 = { class: "p-0" }
const _hoisted_10 = { class: "my-4 text-black fw-600" }
const _hoisted_11 = { class: "my-4 text-black fw-600" }
const _hoisted_12 = { class: "my-4 text-black fw-600" }
const _hoisted_13 = { class: "d-flex align-items-center social-icons" }
const _hoisted_14 = { class: "me-2 i-bg-black" }
const _hoisted_15 = { class: "me-2 i-bg-black" }
const _hoisted_16 = { class: "me-2 i-bg-black" }
const _hoisted_17 = { class: "rsd p-3 rounded-lg mt-4 bg-graying" }
const _hoisted_18 = { action: "\n            " }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-lg-6 col-md-12" }
const _hoisted_21 = { class: "each-field mb-3" }
const _hoisted_22 = { class: "col-lg-6 col-md-12" }
const _hoisted_23 = { class: "each-field mb-3" }
const _hoisted_24 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_25 = { class: "col-lg-6 col-md-12" }
const _hoisted_26 = { class: "each-field mb-3" }
const _hoisted_27 = { class: "d-flex align-items-center border-gray bg-white form-control" }
const _hoisted_28 = { class: "d-flex align-items-center" }
const _hoisted_29 = { class: "col-lg-6 col-md-12" }
const _hoisted_30 = { class: "each-field mb-3" }
const _hoisted_31 = { class: "col-12" }
const _hoisted_32 = { class: "each-field mb-3" }
const _hoisted_33 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_34 = { class: "my-4 ms-4" }
const _hoisted_35 = ["disabled"]

import landingNav from "@/components/Dashboard/LandingPageNav.vue";
import { ref } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'contact',
  setup(__props) {

const store = useStore();
const phoneRegex = /^0(7[0]|8[0-1]|9[0-1])[0-9]{8}$/;
const loading = ref(false);
const form = ref({
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
});

const eMsg = ref({
  email: "Please enter your email",
  message: "This field is required",
  phone: "This field is required",
});

const errors = ref({
  email: false,
  message: false,
  phone: false,
});

const send = () => {
  if (form.value.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !form.value.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;

    if (form.value.phone == "") {
      errors.value.phone = true;
      return;
    } else if (!phoneRegex.test(form.value.phone)) {
      errors.value.phone = true;
      eMsg.value.phone = "Please Enter a valid Phone Number";
      return;
    } else {
      errors.value.phone = false;
    }

    if (form.value.message == "") {
      errors.value.message = true;
      return;
    } else {
      errors.value.message = false;
      loading.value = true;

      store
        .dispatch("post", {
          endpoint: "/contact",
          details: {
            name: form.value.name,
            email: form.value.email,
            phone: form.value.phone,
            subject: form.value.subject,
            message: form.value.message,
          },
        })
        .then((resp) => {
          console.log("the response", resp);
          loading.value = false;
          useToast().success("message sent successfully")
          window.setTimeout(() =>{
            window.location.reload()
          }, 1200)
        });
    }
  }
};

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(landingNav),
    _createElementVNode("div", _hoisted_2, [
      _cache[17] || (_cache[17] = _createElementVNode("div", { class: "contact-screen d-flex align-items-center justify-content-center" }, [
        _createElementVNode("h2", { class: "text-white fw-bold" }, "Contact Us")
      ], -1)),
      _createElementVNode("section", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[10] || (_cache[10] = _createElementVNode("h4", { class: "fw-bold" }, "Get in touch with us", -1)),
            _createElementVNode("div", _hoisted_6, [
              _cache[8] || (_cache[8] = _createElementVNode("p", null, "You can also reach out to us with these contact details;", -1)),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("ul", _hoisted_9, [
                    _createElementVNode("li", _hoisted_10, [
                      _createVNode(_component_Iconify, {
                        class: "icons me-3",
                        icon: "zondicons:location"
                      }),
                      _cache[5] || (_cache[5] = _createElementVNode("span", null, "1 Nairobi Street, Wuse 2, Abuja", -1))
                    ]),
                    _createElementVNode("li", _hoisted_11, [
                      _createVNode(_component_Iconify, {
                        icon: "ic:round-email",
                        class: "icons me-3"
                      }),
                      _cache[6] || (_cache[6] = _createElementVNode("span", null, [
                        _createElementVNode("a", {
                          class: "text-dark",
                          href: "info@tao.ng"
                        }, " info@tao.ng")
                      ], -1))
                    ]),
                    _createElementVNode("li", _hoisted_12, [
                      _createVNode(_component_Iconify, {
                        icon: "solar:phone-bold",
                        class: "icons me-3"
                      }),
                      _cache[7] || (_cache[7] = _createElementVNode("span", null, "+234 908 485 7430 | +13109013903 ", -1))
                    ])
                  ])
                ])
              ]),
              _cache[9] || (_cache[9] = _createElementVNode("p", { class: "fs-14 fw-600 py-3" }, "Social media", -1)),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_Iconify, {
                    style: {"font-size":"20px"},
                    class: "",
                    icon: "skill-icons:linkedin"
                  })
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_Iconify, {
                    style: {"font-size":"20px"},
                    icon: "devicon:facebook"
                  })
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_Iconify, {
                    style: {"font-size":"20px"},
                    icon: "skill-icons:instagram"
                  })
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("form", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _cache[11] || (_cache[11] = _createElementVNode("label", {
                      class: "mb-2",
                      for: "meter"
                    }, "Name", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.name) = $event)),
                        type: "text",
                        class: "form-control border-gray bg-white",
                        placeholder: "Enter your name"
                      }, null, 512), [
                        [_vModelText, form.value.name]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _cache[12] || (_cache[12] = _createElementVNode("label", {
                      class: "mb-2",
                      for: "meter"
                    }, "Email Address", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.email) = $event)),
                        type: "text",
                        class: "form-control border-gray bg-white",
                        placeholder: "Enter your email"
                      }, null, 512), [
                        [_vModelText, form.value.email]
                      ])
                    ]),
                    (errors.value.email)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_24, _toDisplayString(eMsg.value.email), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, [
                    _cache[14] || (_cache[14] = _createElementVNode("label", {
                      for: "meter",
                      class: "mb-2"
                    }, "Phone Number", -1)),
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, [
                        _createVNode(_component_Iconify, {
                          icon: "twemoji:flag-nigeria",
                          style: {"width":"24px","height":"24x"}
                        }),
                        _cache[13] || (_cache[13] = _createElementVNode("span", {
                          class: "pe-2",
                          style: {"border-right":"1px solid gray"}
                        }, " +234", -1))
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((form.value.phone) = $event)),
                        type: "tel",
                        class: "border-0 w-100 ps-2",
                        placeholder: "Phone number",
                        "max-length": "11"
                      }, null, 512), [
                        [_vModelText, form.value.phone]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("div", _hoisted_30, [
                    _cache[15] || (_cache[15] = _createElementVNode("label", {
                      class: "mb-2",
                      for: "meter"
                    }, "Subject", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((form.value.subject) = $event)),
                        type: "text",
                        class: "form-control border-gray bg-white",
                        placeholder: "what is the subject of your inquiry"
                      }, null, 512), [
                        [_vModelText, form.value.subject]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("div", _hoisted_32, [
                    _cache[16] || (_cache[16] = _createElementVNode("label", {
                      class: "mb-2",
                      for: "meter"
                    }, "How can we help you?", -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("textarea", {
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((form.value.message) = $event)),
                        class: "text-control border-gray bg-white",
                        placeholder: "Feel free to write your message..."
                      }, null, 512), [
                        [_vModelText, form.value.message]
                      ])
                    ]),
                    (errors.value.message)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_33, _toDisplayString(eMsg.value.message), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_34, [
                _createElementVNode("button", {
                  disabled: loading.value,
                  onClick: _withModifiers(send, ["prevent"]),
                  class: "btn-primary px-5"
                }, _toDisplayString(loading.value? "Loading...": "Send Message"), 9, _hoisted_35)
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/main.scss";
import {Icon} from '@iconify/vue'
import "vue-toast-notification/dist/theme-bootstrap.css";


const app = createApp(App);
app.use(store);
app.use(router);

app.component('Iconify', Icon);

app.mount("#app");

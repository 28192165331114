<script setup lang="ts">
import { ref } from "vue";
import { useStore } from "vuex";

import { useRouter } from "vue-router";
const router = useRouter();
const store = useStore();
</script>

<template>
  <div class="desktop-screen">
    <div v-if="store.state.user">
      <div class="side-menu ">
        <div class="username d-flex align-items-center border-bottom p-4">
          <div class="initials">
            <h4 class="text-primary uppercase">
              {{ store.state.user.first_name[0]
              }}{{ store.state.user.last_name[0] }}
            </h4>
          </div>
          <div class="ms-3">
            <h6 class="capitalize">
              {{ store.state.user.first_name }}
              {{ store.state.user.last_name }}
            </h6>
            <p class="text-gray" style="font-size: 11px">
              {{ store.state.user.email }}
            </p>
          </div>
        </div>

        <div class="list-item">
          <ul class="menu-items text-center mt-4">
            <li class="each-item">
              <router-link
                class="nav-link"
                to="/my-profile"
                :class="{ picked: $route.name === 'My Profile' }"
              >
                <Iconify icon="solar:user-linear" />
                <span class="menu-text">My Profile</span>
              </router-link>
            </li>
            <li class="each-item">
              <router-link
                class="nav-link"
                to="/beneficiary"
                :class="{ picked: $route.name === 'Beneficiary' }"
              >
                <Iconify icon="solar:users-group-rounded-linear" />
                <span class="menu-text">Beneficiary</span>
              </router-link>
            </li>
            <li class="each-item">
              <router-link
                class="nav-link"
                to="/notifications"
                :class="{ picked: $route.name === 'Notifications' }"
              >
                <Iconify icon="lets-icons:bell-light" />
                <span class="menu-text">Notifications</span>
              </router-link>
            </li>
            <li class="each-item">
              <router-link
                class="nav-link"
                to="/update-password"
                :class="{ picked: $route.name === 'Update Password' }"
              >
                <Iconify icon="mage:security-shield" />
                <span class="menu-text">Update Password</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

   
  </div>
</template>

<style lang="scss" scoped></style>

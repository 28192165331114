import { RouteRecordRaw } from "vue-router";
import Home from "@/views/public/home.vue";
import Solar from "@/views/public/buy-solar.vue";
const publicRoutes: Array<RouteRecordRaw> = [

  {
    path: "/buy-energy-app",
    name: "Home",
    component: Home,
  },
  {
    path: "/buy-solar-app",
    name: "Solar",
    component:Solar,
  },
];

export default publicRoutes;

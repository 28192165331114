<script lang="ts" setup>
import MyAccount from "@/components/Dashboard/my-account.vue";
import NewUserLogin from "@/components/Modal/NewUserLogin.vue";
import LoginMob from "@/components/BottomSheet/CreateAccount/LoginMob.vue";
import {ref} from "vue";
import { useStore} from "vuex"
import { useRouter } from "vue-router";
const router= useRouter();
const store = useStore();
const saveReminder = ref(false);
const frequency = ref("");
const days = ref("");
const loading = ref(false);


const errors = ref({
  frequency: false,
  days:false,
  saveReminder : false,
})

const eMsg = ref({
  frequency: "This field is required",
  days:"This field is required",
  saveReminder:"This field is required",
})

const changeReminder = () => {
  saveReminder.value;
}

const submitNoti = () => {
  if(saveReminder.value == false){
    errors.value.saveReminder = true;
    return;
  }else {
    errors.value.saveReminder = false
  }

  if(frequency.value == ""){
    errors.value.frequency = true;
    return;
  }else {
    errors.value.frequency = false
  }

  if(days.value == ""){
    errors.value.days = true;
    return;
  }else {
    errors.value.days = false
  }
  loading.value = true;
  store.commit("setLoader", true);
  store.dispatch ("patch",{
    
    endpoint: "/user",
    details: {
      reminder: saveReminder.value,
      reminder_frequency: frequency.value,
      reminder_day: days.value,
    },
  } ).then((resp) => {
    store.commit("setLoader", false);
    loading.value = false;
      window.setTimeout(() => {
        window.location.reload();
      }, 1100);
  })
  .catch((err) => {
    loading.value = true;
    store.commit("setLoader", false);
  })
}

</script>

<template>
  <div  class="page-card d-flex justify-content-center">
    <MyAccount></MyAccount>
    <div v-if="store.state.user" class="account-contents ">
      <div class="mobile-screen">
        <div class="pb-3">
          <div class="back-icon e-icon me-3" @click.prevent="router.go(-1)">
              <Iconify icon="ep:arrow-left" />
            </div>
        </div>
      </div>
      <div class="desktop-screen">
        <div class="b-nav border-bottom">
          <h5>Notifications</h5>
        </div>
      </div>
      <div class="profile-page">
        <p>Reminder</p>
        <div class="mt-3 mb-5 bg-gray border-gray rounded ">
        <div class="desktop-screen">
          <div class="form-check form-switch d-flex align-items-center justify-content-between px-2">
            <label
              class="form-check-label text-gray"
              for="flexSwitchCheckChecked"
              > Enable notifications for electricity purchase reminders</label
            >
            <div>
            <input
              v-model="saveReminder"
              class="form-check-input"
              style="height: 22px"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              value="false"
              @change="changeReminder()"
            />
            </div>
            <div v-if="errors.saveReminder" class="error-msg">
              {{ eMsg.saveReminder }}
            </div>
          </div>
        </div>
        <div class="mobile-screen">
          <div class="form-check form-switch  px-3">
            <label
              class="form-check-label text-gray mb-4 d-block "
              for="flexSwitchCheckChecked"
              > Enable notifications for electricity purchase reminders</label
            >
            <div class="ms-5 my-4">
              <input
              v-model="saveReminder"
              class="form-check-input"
              style="height: 22px"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              value="false"
              @change="changeReminder()"
            />
            </div>
          </div>
            <div>
           
            </div>
            <div v-if="errors.saveReminder" class="error-msg">
              {{ eMsg.saveReminder }}
            </div>
        
        </div>
        </div>
      </div>

      <div v-if="saveReminder" class="profile-page mt-3 pt-4 border-top">
        <div class="row g-2">
          <div class="col-lg-6 col-sm-12">
            <div class="each-field mb-4">
              <label for="meter" class="mb-2"
                >Frequency<span class="text-danger">*</span></label
              >
              <div>
              <select
                v-model="frequency"
                class="form-select form-select-lg mb-3"
                aria-label="Large select example"
              >
                <option class="px-2" value="" disabled>select option</option>
                <option value="Weekely">Weekly</option>
                <option value="Daily">Daily</option>
                <option value="Monthly">Monthly</option>
              </select>
              </div>
               <div v-if="errors.frequency" class="error-msg">
                      {{ eMsg.frequency }}
                </div>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="each-field mb-4">
              <label for="meter" class="mb-2"
                >What day of the week<span class="text-danger">*</span></label
              >
              <div>
              <select
                v-model="days"
                class="form-select form-select-lg mb-3"
                aria-label="Large select example"
              >
                <option class="px-2" value="" disabled>select option</option>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="Thursday">Thursday</option>
                <option value="Friday">Friday</option>
                <option value="Saturday">Saturday</option>
                <option value="Sunday">Sunday</option>
              </select>
              </div>
                 <div v-if="errors.days" class="error-msg">
                      {{ eMsg.days }}
                </div>
            </div>
          </div>

          <div class="text-end mt-5">
            <button @click.prevent="submitNoti()" 
            :disabled="loading"
            class="btn-primary px-3">{{ loading ? "Loading..." : "Save Update" }}</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
    <div class="d-flex justify-content-center my-5">
      <div class="text-center" style="width: 400px">
        <Iconify icon="lets-icons:flash-light" style="font-size: 28px" />
        <h5 class="my-4">You have not signed in</h5>
        <p class="p-text text-gray mb-4">
          Please create an account or sign in to have access to your account
        </p>

        <div class="desktop-screen">  
          <div class="d-flex align-items-center justify-content-center ">
            <button
              data-bs-target="#exampleModalToggleB"
              data-bs-toggle="modal"
              class="btn-primary auth-btn me-3"
          
            >
              Create an Account
            </button>
            <button
              data-bs-target="#exampleModalToggleC"
              data-bs-toggle="modal"
              class="btn-primary auth-btn"
     
            >
              Login
            </button>
          </div>
        </div>

        <div class="mobile-screen"> 
          <div class="d-flex align-items-center justify-content-center ">
            <button
              class="btn-primary auth-btn me-3"
              data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasBottom10"
            aria-controls="offcanvasBottom10"
            >
              Create an Account
            </button>
            <button
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasBottom11"
            aria-controls="offcanvasBottom11"
              class="btn-primary auth-btn"
     
            >
              Login
            </button>
          </div>
        
        </div>
      </div>
    </div>
    <LoginMob></LoginMob>
    <NewUserLogin></NewUserLogin>
  </div>
  </div>
</template>

<style lang="scss" scoped>
.form-check-input {
  float: right;
}

.form-check-input:checked {
  background-color: #ff6600 !important;
  border-color: #ff6600 !important;
}
.form-switch {
  padding-left: 5px;
  height: 44px;
}

@media only screen and (max-width: 768px) {
  .form-check-input {
  float: left;
}
}
</style>

<script lang="ts" setup>
import { ref, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";
import { useRouter } from "vue-router";

const router = useRouter();
const store = useStore();
const Toast = useToast();
const loading = ref(false);
const providers = ref([]);
const showSummary = ref(false);
const showForm = ref(true);
const phoneRegex = /^0(7[0]|8[0-1]|9[0-1])[0-9]{8}$/;

const apiResponse = reactive({
  Customer_Name: "",
});

const beneficiary = reactive({
  meter_number: "",
  phone: "",
  email: "",
  meter_type: "",
  provider: "",
});

const errors = ref({
  meter_number: false,
  provider: false,
  meter_type: false,
  phone: false,
  email: false,
});

const eMsg = ref({
  meter_number: " This field is required",
  provider: "This field is required",
  meter_type: "This field is required",
  phone: "This field is required",
  email: "This field is required",
});

const getProvider = () => {
  store
    .dispatch("get", `/purchase/provider`)
    .then((resp) => {
      providers.value = resp.data.data;
    })
    .catch((err) => {
    });
};

const verifyBeneficiary = () => {
  if (beneficiary.meter_number == "") {
    errors.value.meter_number = true;
    return;
  } else if (!beneficiary.meter_number.match(/^\d{11}$/)) {
    errors.value.meter_number = true;
    eMsg.value.meter_number = "Please Enter a valid Meter Number";
    return;
  } else {
    errors.value.meter_number = false;
  }

  if (beneficiary.provider == "") {
    errors.value.provider = true;
    return;
  } else {
    errors.value.provider = false;
  }

  if (beneficiary.meter_type == "") {
    errors.value.meter_type = true;
    return;
  } else {
    errors.value.meter_type = false;
  }


  if (beneficiary.phone == "") {
    errors.value.phone = true;
    return;
  } else if (!phoneRegex.test(beneficiary.phone)) {
    errors.value.phone = true;
    eMsg.value.phone = "Please Enter a valid Phone Number";
    return;
  } else {
    errors.value.phone = false;
  }
  if (beneficiary.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !beneficiary.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;
  }
  loading.value = true;

  store
    .dispatch("post", {
      endpoint: "/purchase/verify",
      details: {
        meter_no: beneficiary.meter_number,
        meter_type: beneficiary.meter_type,
        provider: beneficiary.provider,
      },
    })
    .then((resp) => {
      loading.value = false;
      apiResponse.Customer_Name = resp.data.data.Customer_Name;
      useToast().success("Verified");
      showSummary.value = true;
      showForm.value = false;
    })
    .catch((err) => {
      loading.value = false;
    });
};

const saveBeneficiary = () => {
  store.commit("setLoader", true);
  store
    .dispatch("post", {
      endpoint: "/beneficiary",
      details: {
        name: apiResponse.Customer_Name,
        meter_no: beneficiary.meter_number,
        email: beneficiary.email,
        meter_type: beneficiary.meter_type,
        phone: beneficiary.phone,
        provider: beneficiary.provider,
      },
    })
    .then((resp) => {
      showSummary.value = true;
      showForm.value = false;
      useToast().success("New Beneficiary Added");
      window.setTimeout(() => {
        window.location.reload();
      }, 1200);
    })

    .catch((err) => {
    });
};

onMounted(() => {
  getProvider();
});
</script>

<template>
  <div
    class="offcanvas offcanvas-end right-sheet"
    tabindex="-1"
    id="offcanvasRight3"
    aria-labelledby="offcanvasRightLabel3"
  >
    <div class="offcanvas-header border-bottom py-4 d-block">
      <div class="mb-3">
        <button
          type="button"
          class="bg-white me-2"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <Iconify icon="ep:back" />
        </button>
        <span>Back</span>
      </div>

      <h5 id="offcanvasRightLabel3">Add New Beneficiaries</h5>
    </div>
    <div class="offcanvas-body p-4">
      <div v-if="showForm">
        <form @submit.prevent="verifyBeneficiary()">
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Meter Number <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="beneficiary.meter_number"
                type="text"
                class="form-control border-0 bg-gray mb-2"
                placeholder="e.g 123456789"
              />
            </div>
            <div v-if="errors.meter_number" class="error-msg">
              {{ eMsg.meter_number }}
            </div>
            <div></div>
          </div>

          <div class="each-field mb-4">
            <label for="meter" class="mb-2"
              >Provider <span class="text-danger">*</span></label
            >
            <div class="input-group mb-3">
              <span class="input-group-text" id="inputGroup-sizing-default"
                ><Iconify
                  icon="carbon:location"
                  class="pe-2"
                  style="
                    width: 30px;
                    height: 30px;
                    color: #818181;
                    border-right: 1px solid #c6c6c6;
                  "
                />
              </span>
              <select
                v-model="beneficiary.provider"
                class="form-select form-select-lg mb-3"
                aria-label="Large select example"
              >
                <option disabled value="">select option</option>
                =
                <option
                  v-for="(provider, index) in providers"
                  :key="index"
                  :value="provider.serviceID"
                >
                  {{ provider.serviceID }}
                </option>
              </select>
            </div>
            <div v-if="errors.provider" class="error-msg">
              {{ eMsg.provider }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Meter Type <span class="text-danger">*</span></label
            >
            <div>
              <select
                v-model="beneficiary.meter_type"
                class="form-select form-select-lg mb-3"
                aria-label="Large select example"
              >
                <option disabled value="">select option</option>
                <option value="prepaid">prepaid</option>
                <option value="postpaid">postpaid</option>
              </select>
            </div>
            <div v-if="errors.meter_type" class="error-msg">
              {{ eMsg.meter_type }}
            </div>
          </div>

          <div class="each-field mb-4">
            <label for="meter" class="mb-2"
              >Phone Number <span class="text-danger">*</span></label
            >
            <div class="input-group mb-3">
              <div class="input-group-text" id="basic-addon1">
                <Iconify
                  icon="twemoji:flag-nigeria"
                  style="width: 24px; height: 24x"
                /><span class="pe-2" style="border-right: 1px solid gray">
                  +234</span
                >
              </div>
              <input
                v-model="beneficiary.phone"
                type="text"
                class="form-control border-0 bg-gray"
                placeholder="081*******"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div v-if="errors.phone" class="error-msg">{{ eMsg.phone }}</div>
          </div>

          <div class="each-field mb-4">
            <label class="mb-2" for="meter">Email Address </label>
            <div>
              <input
                v-model="beneficiary.email"
                type="text"
                class="form-control border-0 bg-gray mb-2"
                placeholder="e.g jojndoe@gmail.com"
              />
            </div>
            <div v-if="errors.email" class="error-msg">{{ eMsg.email }}</div>
          </div>

          <div class="d-grid mt-4">
            <button
              type="submit"
              class="border-0 rounded btn-primary text-white"
              style="height: 44px"
              :disabled="loading"
            >
              {{ loading ? "Loading..." : " Verify" }}
            </button>
          </div>
        </form>
      </div>
      <div v-if="showSummary">

        <div class="d-flex align-items-center mb-3">
          <div class="back-icon me-3" @click.prevent="router.go(-1)">
            <Iconify icon="ep:arrow-left" />
          </div>
          <p >Verified Details</p>
        </div>
        <div class="card-wrapper border rounded">
         

          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Provider</p>
            <div class="provider-logo p-2 rounded">
              {{ beneficiary.provider }}
            </div>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Meter Type</p>
            <p class="p-text">{{ beneficiary.meter_type }}</p>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Meter Number</p>
            <p class="p-text">{{ beneficiary.meter_number }}</p>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Meter Name</p>
            <p class="p-text">{{ apiResponse.Customer_Name }}</p>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Phone Number</p>
            <p class="p-text">{{ beneficiary.phone }}</p>
          </div>
         
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Email</p>
            <p class="p-text">{{ beneficiary.email }}</p>
          </div>
        </div>
        <div class="d-grid mt-4">
          <button
            @click.prevent="saveBeneficiary()"
            type="submit"
            class="border-0 rounded btn-primary text-white"
            style="height: 44px"
            :disabled="loading"
          >
            {{ loading ? "Loading..." : " Save Beneficiary" }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="offcanvas offcanvas-bottom full-size"
    tabindex="-1"
    id="offcanvasBottomG"
    aria-labelledby="offcanvasBottomLabelG"
  >
    <div class="offcanvas-header border-bottom pt-2 d-block">
      <div class="mb-3">
        <button
          type="button"
          class="bg-white me-2"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <Iconify icon="ep:back" />
        </button>
        <span>Back</span>
      </div>

      <h5 id="offcanvasBottomLabelG">Add New Beneficiaries</h5>
    </div>
    <div class="offcanvas-body p-4">
      <div v-if="showForm">
        <form @submit.prevent="verifyBeneficiary()">
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Meter Number <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="beneficiary.meter_number"
                type="text"
                class="form-control border-0 bg-gray mb-2"
                placeholder="e.g 123456789"
              />
            </div>
            <div v-if="errors.meter_number" class="error-msg">
              {{ eMsg.meter_number }}
            </div>
            <div></div>
          </div>

          <div class="each-field mb-4">
            <label for="meter" class="mb-2"
              >Provider <span class="text-danger">*</span></label
            >
            <div class="input-group mb-3">
              <span class="input-group-text" id="inputGroup-sizing-default"
                ><Iconify
                  icon="carbon:location"
                  class="pe-2"
                  style="
                    width: 30px;
                    height: 30px;
                    color: #818181;
                    border-right: 1px solid #c6c6c6;
                  "
                />
              </span>
              <select
                v-model="beneficiary.provider"
                class="form-select form-select-lg mb-3"
                aria-label="Large select example"
              >
                <option disabled value="">select option</option>
                =
                <option
                  v-for="(provider, index) in providers"
                  :key="index"
                  :value="provider.serviceID"
                >
                  {{ provider.serviceID }}
                </option>
              </select>
            </div>
            <div v-if="errors.provider" class="error-msg">
              {{ eMsg.provider }}
            </div>
          </div>
          <div class="each-field mb-4">
            <label class="mb-2" for="meter"
              >Meter Type <span class="text-danger">*</span></label
            >
            <div>
              <select
                v-model="beneficiary.meter_type"
                class="form-select form-select-lg mb-3"
                aria-label="Large select example"
              >
                <option disabled value="">select option</option>
                <option value="prepaid">prepaid</option>
                <option value="postpaid">postpaid</option>
              </select>
            </div>
            <div v-if="errors.meter_type" class="error-msg">
              {{ eMsg.meter_type }}
            </div>
          </div>
      

          <div class="each-field mb-4">
            <label for="meter" class="mb-2"
              >Phone Number <span class="text-danger">*</span></label
            >
            <div class="input-group mb-3">
              <div class="input-group-text" id="basic-addon1">
                <Iconify
                  icon="twemoji:flag-nigeria"
                  style="width: 24px; height: 24x"
                /><span class="pe-2" style="border-right: 1px solid gray">
                  +234</span
                >
              </div>
              <input
                v-model="beneficiary.phone"
                type="text"
                class="form-control border-0 bg-gray"
                placeholder="081*******"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <div v-if="errors.phone" class="error-msg">{{ eMsg.phone }}</div>
          </div>

          <div class="each-field mb-4">
            <label class="mb-2" for="meter">Email Address </label>
            <div>
              <input
                v-model="beneficiary.email"
                type="text"
                class="form-control border-0 bg-gray mb-2"
                placeholder="e.g jojndoe@gmail.com"
              />
            </div>
            <div v-if="errors.email" class="error-msg">{{ eMsg.email }}</div>
          </div>

          <div class="d-grid mt-4">
            <button
              type="submit"
              class="border-0 rounded btn-primary text-white"
              style="height: 44px"
              :disabled="loading"
            >
              {{ loading ? "Loading..." : " Verify" }}
            </button>
          </div>
        </form>
      </div>
      <div v-if="showSummary">

        <div class="d-flex align-items-center">
          <div class="back-icon me-3" @click.prevent="router.go(-1)">
            <Iconify icon="ep:arrow-left" />
          </div>
          <p>Verified Details</p>
        </div>
        <div class="card-wrapper border rounded">
         

          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Provider</p>
            <div class="provider-logo p-2 rounded">
              {{ beneficiary.provider }}
            </div>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Meter Type</p>
            <p class="p-text">{{ beneficiary.meter_type }}</p>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Meter Number</p>
            <p class="p-text">{{ beneficiary.meter_number }}</p>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Meter Name</p>
            <p class="p-text">{{ apiResponse.Customer_Name }}</p>
          </div>
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Phone Number</p>
            <p class="p-text">{{ beneficiary.phone }}</p>
          </div>
        
          <div
            class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
          >
            <p class="text-gray p-text">Email</p>
            <p class="p-text">{{ beneficiary.email }}</p>
          </div>
        </div>
        <div class="d-grid mt-4">
          <button
            @click.prevent="saveBeneficiary()"
            type="submit"
            class="border-0 rounded btn-primary text-white"
            style="height: 44px"
            :disabled="loading"
          >
            {{ loading ? "Loading..." : " Save Beneficiary" }}
          </button>
        </div>
      </div>
    </div>
  </div>


</template>

<style lang="scss" scoped>

</style>

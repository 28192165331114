import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "page-wrapper" }
const _hoisted_3 = { class: "form-card" }
const _hoisted_4 = {
  class: "tab-content",
  id: "pills-tabContent"
}
const _hoisted_5 = {
  class: "tab-pane fade show active",
  id: "pills-home",
  role: "tabpanel",
  "aria-labelledby": "pills-home-tab"
}
const _hoisted_6 = {
  class: "tab-pane fade",
  id: "pills-profile",
  role: "tabpanel",
  "aria-labelledby": "pills-profile-tab"
}

import purchase from "@/components/BuyElectricity/Purchase.vue";
import transactionHistory from "@/components/BuyElectricity/TransactionHistory.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'Electricity',
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "button-nav-wrapper" }, [
          _createElementVNode("ul", {
            class: "nav nav-pills",
            id: "pills-tab",
            role: "tablist"
          }, [
            _createElementVNode("li", {
              class: "each-tab",
              role: "presentation"
            }, [
              _createElementVNode("button", {
                class: "tab-link active",
                id: "pills-home-tab",
                "data-bs-toggle": "pill",
                "data-bs-target": "#pills-home",
                type: "button",
                role: "tab",
                "aria-controls": "pills-home",
                "aria-selected": "true"
              }, " New Purchase ")
            ]),
            _createElementVNode("li", {
              class: "each-tab",
              role: "presentation"
            }, [
              _createElementVNode("button", {
                class: "tab-link",
                id: "pills-profile-tab",
                "data-bs-toggle": "pill",
                "data-bs-target": "#pills-profile",
                type: "button",
                role: "tab",
                "aria-controls": "pills-profile",
                "aria-selected": "false"
              }, " Transaction History ")
            ])
          ])
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(purchase)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(transactionHistory)
          ])
        ])
      ])
    ])
  ]))
}
}

})
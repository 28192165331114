import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "desktop-screen" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "side-menu" }
const _hoisted_4 = { class: "username d-flex align-items-center border-bottom p-4" }
const _hoisted_5 = { class: "initials" }
const _hoisted_6 = { class: "text-primary uppercase" }
const _hoisted_7 = { class: "ms-3" }
const _hoisted_8 = { class: "capitalize" }
const _hoisted_9 = {
  class: "text-gray",
  style: {"font-size":"11px"}
}
const _hoisted_10 = { class: "list-item" }
const _hoisted_11 = { class: "menu-items text-center mt-4" }
const _hoisted_12 = { class: "each-item" }
const _hoisted_13 = { class: "each-item" }
const _hoisted_14 = { class: "each-item" }
const _hoisted_15 = { class: "each-item" }

import { ref } from "vue";
import { useStore } from "vuex";

import { useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'my-account',
  setup(__props) {

const router = useRouter();
const store = useStore();

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(store).state.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h4", _hoisted_6, _toDisplayString(_unref(store).state.user.first_name[0]) + _toDisplayString(_unref(store).state.user.last_name[0]), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h6", _hoisted_8, _toDisplayString(_unref(store).state.user.first_name) + " " + _toDisplayString(_unref(store).state.user.last_name), 1),
                _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(store).state.user.email), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("ul", _hoisted_11, [
                _createElementVNode("li", _hoisted_12, [
                  _createVNode(_component_router_link, {
                    class: _normalizeClass(["nav-link", { picked: _ctx.$route.name === 'My Profile' }]),
                    to: "/my-profile"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Iconify, { icon: "solar:user-linear" }),
                      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "menu-text" }, "My Profile", -1))
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _createElementVNode("li", _hoisted_13, [
                  _createVNode(_component_router_link, {
                    class: _normalizeClass(["nav-link", { picked: _ctx.$route.name === 'Beneficiary' }]),
                    to: "/beneficiary"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Iconify, { icon: "solar:users-group-rounded-linear" }),
                      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "menu-text" }, "Beneficiary", -1))
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _createElementVNode("li", _hoisted_14, [
                  _createVNode(_component_router_link, {
                    class: _normalizeClass(["nav-link", { picked: _ctx.$route.name === 'Notifications' }]),
                    to: "/notifications"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Iconify, { icon: "lets-icons:bell-light" }),
                      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "menu-text" }, "Notifications", -1))
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _createElementVNode("li", _hoisted_15, [
                  _createVNode(_component_router_link, {
                    class: _normalizeClass(["nav-link", { picked: _ctx.$route.name === 'Update Password' }]),
                    to: "/update-password"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Iconify, { icon: "mage:security-shield" }),
                      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "menu-text" }, "Update Password", -1))
                    ]),
                    _: 1
                  }, 8, ["class"])
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})
import { RouteRecordRaw} from "vue-router";
import LandingPage from "@/views/public/landing-page.vue"
import FirstView from "@/views/public/first-app-view.vue"
import Policy from "@/views/public/privacy-policy.vue"
import Contact from "@/views/public/contact.vue"



const landingPageRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "First View",
    component: FirstView,
  },
  {
    path: "/landing-page",
    component : LandingPage,
    name: "Landing Page"
  },
  {
    path: "/policy",
    name: "Policy",
    component: Policy,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },

];

export default landingPageRoutes;
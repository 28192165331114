<template>
  <div class="layout-nav border-top">
    <div class="layout-nav-items">
      <div class="layout-nav-route">
        <router-link to="/" class="text-dark">Why Choose Us </router-link>
        <router-link to="/" class="route  text-dark"> What We Offer </router-link>
      </div>
      <div class="copyright">
        Copyright 2024 TAO Energy
      </div>
    </div>
  </div>
</template>


<script setup lang="ts"></script>


<template>
  <div class="small-screen-layout">
    <div class="item-container">
      <ul class="list-items">
        <!-- <li class="each-list text-center">
          <router-link
            class="each-link"
            to="#"
            :class="{ active: $route.name === 'Home' }"
          >
            <Iconify icon="solar:home-2-linear" class="menu-icon" />
            <p class="menu-text">Home</p>
          </router-link>
        </li> -->
        <li class="each-list text-center">
          <router-link
            class="each-link"
            to="/buy-electricity"
            :class="{ active: $route.name === 'Buy Electricity' }"
          >
            <Iconify icon="ion:flash" class="menu-icon" />
            <p class="menu-text">Buy Electricity</p>
          </router-link>
        </li>
        <li class="each-list text-center">
          <router-link
            class="each-link"
            to="/track"
            :class="{active: $route.name === 'Buy Solar'}"
            >
              <Iconify icon="ph:solar-panel-light" class="menu-icon" />
            <p class="menu-text">Buy Solar</p>
            </router-link>
        </li>
        <li class="each-list text-center">
          <router-link
            class="each-link"
            to="/my-account"
            :class="{ active: $route.name === 'My Account' }"
          >
            <Iconify icon="solar:user-linear" class="menu-icon" />
            <p class="menu-text">My Account</p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "page-card d-flex justify-content-center" }
const _hoisted_2 = {
  key: 0,
  class: "account-contents"
}
const _hoisted_3 = { class: "mobile-screen" }
const _hoisted_4 = { class: "pb-2" }
const _hoisted_5 = { class: "profile-page" }
const _hoisted_6 = { class: "mobile-screen" }
const _hoisted_7 = { class: "username d-flex align-items-center my-4" }
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = { class: "initials" }
const _hoisted_10 = { class: "text-primary uppercase" }
const _hoisted_11 = { class: "ms-3" }
const _hoisted_12 = { class: "capitalize" }
const _hoisted_13 = { class: "profile-email" }
const _hoisted_14 = { action: "" }
const _hoisted_15 = { class: "row g-4 mb-5" }
const _hoisted_16 = { class: "col-lg-6 col-sm-12" }
const _hoisted_17 = { class: "each-field" }
const _hoisted_18 = { class: "col-lg-6 col-sm-12" }
const _hoisted_19 = { class: "each-field" }
const _hoisted_20 = { class: "col-lg-6 col-sm-12" }
const _hoisted_21 = { class: "each-field" }
const _hoisted_22 = { class: "col-lg-6 col-sm-12" }
const _hoisted_23 = { class: "each-field mb-3" }
const _hoisted_24 = { class: "input-group mb-3" }
const _hoisted_25 = {
  class: "input-group-text",
  id: "basic-addon1"
}
const _hoisted_26 = { class: "updateBtn" }
const _hoisted_27 = ["disabled"]
const _hoisted_28 = { key: 1 }
const _hoisted_29 = { class: "d-flex justify-content-center my-5" }
const _hoisted_30 = {
  class: "text-center",
  style: {"width":"400px"}
}

import { ref, onMounted, reactive } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import MyAccount from "@/components/Dashboard/my-account.vue";
import NewUserLogin from "@/components/Modal/NewUserLogin.vue";
import LoginMob from "@/components/BottomSheet/CreateAccount/LoginMob.vue";



export default /*@__PURE__*/_defineComponent({
  __name: 'my-profile',
  setup(__props) {

const store = useStore();
const $toast = useToast();
const loading = ref(false);
const router= useRouter()



const userDetails = reactive ({
data: {
created_at:"",
email:"",
first_name:"",
last_name:"",
last_updated:"",
phone:"",
 },
  accessToken: "",

});

const UpdateDetails = () => {
  store.commit("setLoader", true);
  loading.value = true;
  store
    .dispatch("patch", {
      endpoint: "/user",
      details: {
        first_name: store.state.user.first_name,
        last_name : store.state.user.last_name,
        phone: store.state.user.phone,
      },
    })
    .then((resp) => {
      store.commit("setLoader", false);
      loading.value = false;
      userDetails.data = resp.data.data;
      useToast().success("Profile updated successfully");
      window.setTimeout(() => {
        window.location.reload();
      }, 1500);
    })
    .catch(() => {
      store.commit("setLoader", false);
      loading.value = false;
    });
};

const getUser = () => {
  store.commit("setLoader", true);
  store.dispatch("get", "/user").then((resp) => {
    store.commit("setLoader", false);
    userDetails.data = resp.data.data;
    userDetails.accessToken = store.state.token
    store.commit("setUser", userDetails)
  }).catch((err) => {
    store.commit("setLoader", true);
  })
};

onMounted(() => {
  if (store.state.user) {
    getUser();
  }

});

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MyAccount),
    (_unref(store).state.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: "back-icon e-icon me-3",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_unref(router).go(-1)), ["prevent"]))
              }, [
                _createVNode(_component_Iconify, { icon: "ep:arrow-left" })
              ])
            ])
          ]),
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "desktop-screen" }, [
            _createElementVNode("div", { class: "b-nav border-bottom" }, [
              _createElementVNode("h5", null, "My Profile")
            ])
          ], -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("h4", _hoisted_10, _toDisplayString(_unref(store).state.user.first_name[0]) + _toDisplayString(_unref(store).state.user.last_name[0]), 1)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("h6", _hoisted_12, _toDisplayString(_unref(store).state.user.first_name) + " " + _toDisplayString(_unref(store).state.user.last_name), 1),
                    _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(store).state.user.email), 1)
                  ])
                ])
              ])
            ]),
            _createElementVNode("form", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _cache[6] || (_cache[6] = _createElementVNode("label", {
                      class: "mb-2",
                      for: "email"
                    }, [
                      _createTextVNode("First Name "),
                      _createElementVNode("span", { class: "text-danger" }, "*")
                    ], -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(store).state.user.first_name) = $event)),
                        type: "text",
                        class: "form-control border-gray bg-gray",
                        placeholder: "e.g joe"
                      }, null, 512), [
                        [_vModelText, _unref(store).state.user.first_name]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _cache[7] || (_cache[7] = _createElementVNode("label", {
                      class: "mb-2",
                      for: "email"
                    }, [
                      _createTextVNode("Last Name "),
                      _createElementVNode("span", { class: "text-danger" }, "*")
                    ], -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(store).state.user.last_name) = $event)),
                        type: "text",
                        class: "form-control border-gray bg-gray",
                        placeholder: "e.g doe"
                      }, null, 512), [
                        [_vModelText, _unref(store).state.user.last_name]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _cache[8] || (_cache[8] = _createElementVNode("label", {
                      class: "mb-2",
                      for: "email"
                    }, [
                      _createTextVNode("Email Address "),
                      _createElementVNode("span", { class: "text-danger" }, "*")
                    ], -1)),
                    _createElementVNode("div", null, [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(store).state.user.email) = $event)),
                        type: "text",
                        class: "form-control border-gray bg-gray",
                        placeholder: "e.g joedoe@gmail.com",
                        disabled: ""
                      }, null, 512), [
                        [_vModelText, _unref(store).state.user.email]
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _cache[10] || (_cache[10] = _createElementVNode("label", {
                      for: "meter",
                      class: "mb-2"
                    }, [
                      _createTextVNode("Phone Number "),
                      _createElementVNode("span", { class: "text-danger" }, "*")
                    ], -1)),
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _createVNode(_component_Iconify, {
                          icon: "twemoji:flag-nigeria",
                          style: {"width":"24px","height":"24x"}
                        }),
                        _cache[9] || (_cache[9] = _createElementVNode("span", {
                          class: "pe-2",
                          style: {"border-right":"1px solid gray"}
                        }, " +234", -1))
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(store).state.user.phone) = $event)),
                        type: "tel",
                        class: "form-control border-gray bg-gray",
                        placeholder: "081*******",
                        "aria-label": "Username",
                        "aria-describedby": "basic-addon1"
                      }, null, 512), [
                        [_vModelText, _unref(store).state.user.phone]
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("button", {
                  onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (UpdateDetails()), ["prevent"])),
                  class: "btn-primary px-4",
                  disabled: loading.value
                }, _toDisplayString(loading.value ? "Updating..." : "Save Changes"), 9, _hoisted_27)
              ])
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_28, [
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", _hoisted_30, [
              _createVNode(_component_Iconify, {
                icon: "lets-icons:flash-light",
                style: {"font-size":"28px"}
              }),
              _cache[12] || (_cache[12] = _createStaticVNode("<h5 class=\"my-4\">You have not signed in</h5><p class=\"p-text text-gray mb-4\"> Please create an account or sign in to have access to your account </p><div class=\"desktop-screen\"><div class=\"d-flex align-items-center justify-content-center\"><button data-bs-target=\"#exampleModalToggleB\" data-bs-toggle=\"modal\" class=\"btn-primary auth-btn me-3\"> Create an Account </button><button data-bs-target=\"#exampleModalToggleC\" data-bs-toggle=\"modal\" class=\"btn-primary auth-btn\"> Login </button></div></div><div class=\"mobile-screen\"><div class=\"d-flex align-items-center justify-content-center\"><button class=\"btn-primary auth-btn me-3\" data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottom10\" aria-controls=\"offcanvasBottom10\"> Create an Account </button><button data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottom11\" aria-controls=\"offcanvasBottom11\" class=\"btn-primary auth-btn\"> Login </button></div></div>", 4))
            ])
          ]),
          _createVNode(LoginMob),
          _createVNode(NewUserLogin)
        ]))
  ]))
}
}

})
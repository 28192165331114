import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "transaction-history mt-4"
}
const _hoisted_4 = { class: "desktop-screen" }
const _hoisted_5 = { class: "search-field d-flex p-3 justify-content-between align-items-center border rounded" }
const _hoisted_6 = { class: "search-input" }
const _hoisted_7 = { class: "search-icon" }
const _hoisted_8 = {
  key: 0,
  class: "filter-inputs my-3"
}
const _hoisted_9 = { class: "row gx-8" }
const _hoisted_10 = { class: "col-lg-4" }
const _hoisted_11 = { class: "from" }
const _hoisted_12 = { class: "col-lg-4" }
const _hoisted_13 = { class: "from" }
const _hoisted_14 = { class: "col-lg-2 d-flex align-items-end" }
const _hoisted_15 = { class: "d-flex align-items-center searchbtn" }
const _hoisted_16 = { class: "table border rounded responsive mt-4" }
const _hoisted_17 = { class: "text-lowercase" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "mobile-screen" }
const _hoisted_20 = { class: "search-field d-flex align-items-center" }
const _hoisted_21 = { class: "search-input me-4" }
const _hoisted_22 = { class: "search-icon" }
const _hoisted_23 = { class: "filter d-flex align-items-center justify-content-center filter-btn" }
const _hoisted_24 = {
  key: 0,
  class: "filter-inputs my-3"
}
const _hoisted_25 = { class: "row" }
const _hoisted_26 = { class: "col-6" }
const _hoisted_27 = { class: "col-6" }
const _hoisted_28 = { class: "c-flex searchbtn mt-4" }
const _hoisted_29 = { class: "mt-4" }
const _hoisted_30 = { class: "d-flex py-4 align-items-center justify-content-between" }
const _hoisted_31 = { class: "p-text text-gray text-capitalize mb-2" }
const _hoisted_32 = { class: "text-gray p-text" }
const _hoisted_33 = { class: "d-flex align-items-center" }
const _hoisted_34 = { class: "me-3 text-end" }
const _hoisted_35 = { class: "fs-6 mb-2" }
const _hoisted_36 = { class: "text-gray p-text" }
const _hoisted_37 = ["onClick"]
const _hoisted_38 = { class: "pagination d-flex mt-5 align-items-center justify-content-between px-4 pb-4" }
const _hoisted_39 = { class: "page-numb pointer" }
const _hoisted_40 = { key: 1 }
const _hoisted_41 = { class: "d-flex justify-content-center mt-5" }
const _hoisted_42 = {
  class: "text-center",
  style: {"width":"400px"}
}
const _hoisted_43 = { class: "d-grid" }
const _hoisted_44 = ["disabled"]
const _hoisted_45 = { key: 1 }
const _hoisted_46 = { class: "d-flex justify-content-center mt-5" }
const _hoisted_47 = {
  class: "text-center",
  style: {"width":"400px"}
}

import EachTransaction from "@/components/BottomSheet/EachTransaction.vue";

import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import NewLoginMob from "@/components/BottomSheet/CreateAccount/LoginMob.vue";

import NewUserLogin from "@/components/Modal/NewUserLogin.vue";
import {
  formatDate,
  formatTime,
  formatDateTime,
  formatAmount,
} from "@/core/utils/helpers";


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionHistory',
  setup(__props) {

const store = useStore();
const transactions: any = ref([]);
const transaction: any = ref({});
const serviceProvider: any = ref();
const loadInfo = ref(false);
const loading = ref(false);
const providers : any = ref([]);
const disabled = ref(false);
const filter = ref({
  page: 1,
  limit: 10,
  from: null,
  to: null,
  name:""
});

const getProvider = async () => {
  await store
    .dispatch("get", `/purchase/provider`)
    .then((resp) => {
      providers.value = resp.data.data;
    })
    .catch(() => {
    });
};

const searchTransactions = () => {
  store.commit("setLoader", true);
  store
    .dispatch(
      "get",
      `/purchase?limit=10&from=${filter.value.from}&to=${filter.value.to}`
    )
    .then((resp) => {
      store.commit("setLoader", false);
      transactions.value = resp.data.data;
      transaction.value = transactions.value[0];

      loadInfo.value = true;
  
    })
    .catch((err) => {
      store.commit("setLoader", false);
    });
};
const getTransactions  = () => {
  store.commit("setLoader", true);
  store
    .dispatch(
      "get",
      `/purchase?limit=10&from=${filter.value.from}&to=${filter.value.to}&name=${filter.value.name}`)
    .then((resp) => {
      store.commit("setLoader", false);
      transactions.value = resp.data.data;
      transaction.value = transactions.value[0];

      loadInfo.value = true;
     
      serviceProvider.value = providers.value.find((provider: any) => provider.serviceID === transaction.value.provider);
      checkTransactionLength();
    })
    .catch((err) => {
      store.commit("setLoader", false);
    });
};

const setTransaction = (item: any, item1: any) => {
  transaction.value = item;
  serviceProvider.value = item1
};

const BuyElectricity = () => {
    window.location.reload();
};

const filterBtn = () => {
  disabled.value = !disabled.value;
};


const showMore = ref(true);

const checkTransactionLength = () => {
  if (transactions.value.length < 10) {
    showMore.value = false;
  } else {
    showMore.value = true;
  }
};

const next = () => {
  if (showMore.value) {
    filter.value.page++;
    getTransactions();
  }
};

const prev = () => {
  filter.value.page--;
  getTransactions();
};
onMounted(async () => {
  await getProvider();
  if (store.state.user) {
    getTransactions();
  }
});

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!
  const _component_iconify = _resolveComponent("iconify")!

  return (_unref(store).state.user)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (loadInfo.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (transactions.value.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(EachTransaction, {
                      transaction: transaction.value,
                      serviceProvider: serviceProvider.value
                    }, null, 8, ["transaction", "serviceProvider"]),
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("form", {
                            onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (getTransactions()), ["prevent"]))
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filter.value.name) = $event)),
                              type: "search",
                              class: "input-field form-control border-0 ps-5",
                              placeholder: "Search by name"
                            }, null, 512), [
                              [_vModelText, filter.value.name]
                            ]),
                            _createElementVNode("div", _hoisted_7, [
                              _createVNode(_component_Iconify, {
                                icon: "iconamoon:search-thin",
                                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (getTransactions()), ["prevent"]))
                              })
                            ])
                          ], 32)
                        ]),
                        _createElementVNode("div", {
                          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (filterBtn()), ["prevent"])),
                          class: "filter d-flex align-items-center justify-content-center box"
                        }, [
                          _createVNode(_component_iconify, {
                            icon: "bi:filter",
                            style: {"font-size":"24px"}
                          }),
                          _cache[17] || (_cache[17] = _createElementVNode("p", { class: "ms-2" }, "Filter", -1))
                        ])
                      ]),
                      (disabled.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("div", _hoisted_11, [
                                  _cache[18] || (_cache[18] = _createElementVNode("label", { class: "mb-2 text-gray" }, "Start Date", -1)),
                                  _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((filter.value.from) = $event)),
                                    type: "date",
                                    class: "form-control search-form text-gray",
                                    id: ""
                                  }, null, 512), [
                                    [_vModelText, filter.value.from]
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("div", _hoisted_13, [
                                  _cache[19] || (_cache[19] = _createElementVNode("label", { class: "mb-2 text-gray" }, "End Date", -1)),
                                  _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((filter.value.to) = $event)),
                                    type: "date",
                                    class: "form-control search-form text-gray",
                                    id: ""
                                  }, null, 512), [
                                    [_vModelText, filter.value.to]
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("div", _hoisted_15, [
                                  _createElementVNode("button", {
                                    onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (searchTransactions()), ["prevent"])),
                                    class: "btn-primary px-3 btns me-3"
                                  }, " Search ")
                                ])
                              ])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("table", null, [
                          _cache[21] || (_cache[21] = _createElementVNode("thead", null, [
                            _createElementVNode("tr", null, [
                              _createElementVNode("th", null, "Date"),
                              _createElementVNode("th", null, "Electricity Provider"),
                              _createElementVNode("th", null, "Meter Type"),
                              _createElementVNode("th", null, "Meter Number"),
                              _createElementVNode("th", null, "Meter Name"),
                              _createElementVNode("th", null, "Amount"),
                              _createElementVNode("th")
                            ])
                          ], -1)),
                          _createElementVNode("tbody", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(transactions.value, (transact, index) => {
                              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                _createElementVNode("td", null, _toDisplayString(_unref(formatDateTime)(transact.created_at)), 1),
                                _createElementVNode("td", null, [
                                  _createTextVNode(_toDisplayString(transact.provider), 1),
                                  _cache[20] || (_cache[20] = _createElementVNode("span", { class: "dot mx-4" }, null, -1))
                                ]),
                                _createElementVNode("td", null, _toDisplayString(transact.meter_type), 1),
                                _createElementVNode("td", null, _toDisplayString(transact.meter_no), 1),
                                _createElementVNode("td", _hoisted_17, _toDisplayString(transact.meter_name), 1),
                                _createElementVNode("td", null, "NGN " + _toDisplayString(transact.amount), 1),
                                _createElementVNode("td", null, [
                                  _createElementVNode("div", {
                                    "data-bs-toggle": "offcanvas",
                                    "data-bs-target": "#offcanvasRightA",
                                    "aria-controls": "offcanvasRightA",
                                    onClick: ($event: any) => (setTransaction(transact, serviceProvider.value))
                                  }, [
                                    _createVNode(_component_Iconify, {
                                      class: "primary",
                                      icon: "ri:more-line"
                                    })
                                  ], 8, _hoisted_18)
                                ])
                              ]))
                            }), 128))
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("div", _hoisted_21, [
                          _createElementVNode("form", {
                            onSubmit: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (getTransactions()), ["prevent"]))
                          }, [
                            _withDirectives(_createElementVNode("input", {
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((filter.value.name) = $event)),
                              type: "search",
                              class: "input-field form-control ps-5",
                              placeholder: "Search by name"
                            }, null, 512), [
                              [_vModelText, filter.value.name]
                            ]),
                            _createElementVNode("div", _hoisted_22, [
                              _createVNode(_component_Iconify, {
                                icon: "iconamoon:search-thin",
                                onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (getTransactions()), ["prevent"]))
                              })
                            ])
                          ], 32)
                        ]),
                        _createElementVNode("div", _hoisted_23, [
                          _createVNode(_component_iconify, {
                            onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (filterBtn()), ["prevent"])),
                            icon: "bi:filter",
                            style: {"font-size":"24px"}
                          }),
                          _cache[22] || (_cache[22] = _createElementVNode("p", { class: "ms-2 desktop-screen" }, "Filter", -1))
                        ])
                      ]),
                      (disabled.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                            _createElementVNode("div", _hoisted_25, [
                              _createElementVNode("div", _hoisted_26, [
                                _cache[23] || (_cache[23] = _createElementVNode("label", { class: "mb-2 text-gray" }, "Start Date", -1)),
                                _withDirectives(_createElementVNode("input", {
                                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((filter.value.from) = $event)),
                                  type: "date",
                                  class: "form-control search-form text-gray",
                                  id: ""
                                }, null, 512), [
                                  [_vModelText, filter.value.from]
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_27, [
                                _cache[24] || (_cache[24] = _createElementVNode("label", { class: "mb-2 text-gray" }, "End Date", -1)),
                                _withDirectives(_createElementVNode("input", {
                                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((filter.value.to) = $event)),
                                  type: "date",
                                  class: "form-control search-form text-gray",
                                  id: ""
                                }, null, 512), [
                                  [_vModelText, filter.value.to]
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_28, [
                              _createElementVNode("button", {
                                onClick: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (searchTransactions()), ["prevent"])),
                                class: "btn-primary px-3 btns me-3"
                              }, " Search ")
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_29, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(transactions.value, (transact, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_30, [
                                _createElementVNode("div", null, [
                                  _createElementVNode("p", _hoisted_31, _toDisplayString(transact.meter_name), 1),
                                  _createElementVNode("p", _hoisted_32, _toDisplayString(transact.provider), 1)
                                ]),
                                _createElementVNode("div", _hoisted_33, [
                                  _createElementVNode("div", _hoisted_34, [
                                    _createElementVNode("p", _hoisted_35, " NGN" + _toDisplayString(_unref(formatAmount)(transact.amount)), 1),
                                    _createElementVNode("p", _hoisted_36, _toDisplayString(_unref(formatDateTime)(transact.created_at)), 1)
                                  ]),
                                  _createElementVNode("div", {
                                    "data-bs-toggle": "offcanvas",
                                    "data-bs-target": "#offcanvasBottomC",
                                    "aria-controls": "offcanvasBottomC",
                                    onClick: ($event: any) => (setTransaction(transact, serviceProvider.value))
                                  }, [
                                    _createVNode(_component_iconify, {
                                      class: "primary",
                                      icon: "ic:sharp-more-vert",
                                      style: {"font-size":"23px"}
                                    })
                                  ], 8, _hoisted_37)
                                ])
                              ])
                            ])
                          ]))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_38, [
                      _createElementVNode("div", null, [
                        (filter.value.page > 1)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              onClick: _cache[14] || (_cache[14] = _withModifiers(($event: any) => (prev()), ["prevent"])),
                              class: "btn-disabled py-2 px-3 rounded text-primary pointer"
                            }, [
                              _createElementVNode("span", null, [
                                _createVNode(_component_Iconify, {
                                  icon: "bi:arrow-left",
                                  class: "icons"
                                })
                              ]),
                              _cache[25] || (_cache[25] = _createTextVNode(" Previous "))
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_39, _toDisplayString(filter.value.page), 1),
                      _createElementVNode("div", null, [
                        (showMore.value)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              onClick: _cache[15] || (_cache[15] = _withModifiers(($event: any) => (next()), ["prevent"])),
                              class: "btn-disabled py-2 px-3 rounded text-center text-primary pointer"
                            }, [
                              _cache[26] || (_cache[26] = _createTextVNode(" Next ")),
                              _createElementVNode("span", null, [
                                _createVNode(_component_iconify, {
                                  icon: "bi:arrow-right",
                                  style: {"font-size":"24px"}
                                })
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_40, [
                    _createElementVNode("div", _hoisted_41, [
                      _createElementVNode("div", _hoisted_42, [
                        _createVNode(_component_Iconify, { icon: "icons:flash-light" }),
                        _cache[27] || (_cache[27] = _createElementVNode("h5", { class: "my-4" }, "No Transaction history", -1)),
                        _cache[28] || (_cache[28] = _createElementVNode("p", { class: "p-text text-gray mb-4" }, " Click on the button below to purchase electricity ", -1)),
                        _createElementVNode("div", _hoisted_43, [
                          _createElementVNode("button", {
                            onClick: _cache[16] || (_cache[16] = _withModifiers(($event: any) => (BuyElectricity()), ["prevent"])),
                            disabled: loading.value,
                            class: "btn-primary"
                          }, _toDisplayString(loading.value ? "Loading..." : "Buy Electricity"), 9, _hoisted_44)
                        ])
                      ])
                    ])
                  ]))
            ]))
          : _createCommentVNode("", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_45, [
        _createElementVNode("div", _hoisted_46, [
          _createElementVNode("div", _hoisted_47, [
            _createVNode(_component_Iconify, {
              icon: "lets-icons:flash-light",
              style: {"font-size":"28px"}
            }),
            _cache[29] || (_cache[29] = _createStaticVNode("<h5 class=\"my-4\">You have not signed in</h5><p class=\"p-text text-gray mb-4\"> Please create an account or sign in to have access to your account </p><div class=\"desktop-screen\"><div class=\"d-flex align-items-center justify-content-center\"><button data-bs-target=\"#exampleModalToggleB\" data-bs-toggle=\"modal\" class=\"btn-primary auth-btn me-3\"> Create an Account </button><button data-bs-target=\"#exampleModalToggleC\" data-bs-toggle=\"modal\" class=\"btn-primary auth-btn\"> Login </button></div></div><div class=\"mobile-screen\"><div class=\"d-flex align-items-center justify-content-center\"><button class=\"btn-primary auth-btn me-3\" data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottom10\" aria-controls=\"offcanvasBottom10\"> Create an Account </button><button data-bs-toggle=\"offcanvas\" data-bs-target=\"#offcanvasBottom11\" aria-controls=\"offcanvasBottom11\" class=\"btn-primary auth-btn\"> Login </button></div></div>", 4))
          ])
        ]),
        _createVNode(NewLoginMob),
        _createVNode(NewUserLogin)
      ]))
}
}

})
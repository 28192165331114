import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "offcanvas offcanvas-end right-sheet",
  tabindex: "-1",
  id: "offcanvasRight",
  "aria-labelledby": "offcanvasRightLabel"
}
const _hoisted_2 = { class: "offcanvas-header border-bottom py-4 d-block" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = {
  type: "button",
  class: "bg-white me-2",
  "data-bs-dismiss": "offcanvas",
  "aria-label": "Close"
}
const _hoisted_5 = {
  key: 0,
  class: "offcanvas-body p-0"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "search-input m-3" }
const _hoisted_8 = { class: "search-icon" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "details d-flex justify-content-between align-items-center" }
const _hoisted_11 = { class: "fs-6 mb-2" }
const _hoisted_12 = { class: "p-text text-gray" }
const _hoisted_13 = { class: "text-end" }
const _hoisted_14 = { class: "text-gray p-text mb-2" }
const _hoisted_15 = { class: "text-gray p-text" }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = {
  class: "offcanvas offcanvas-bottom full-size",
  tabindex: "-1",
  id: "offcanvasBottom",
  "aria-labelledby": "offcanvasBottomLabel"
}
const _hoisted_18 = {
  key: 0,
  class: "offcanvas-body p-0"
}
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { class: "search-input m-3" }
const _hoisted_21 = { class: "search-icon" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "details d-flex justify-content-between align-items-center" }
const _hoisted_24 = { class: "fs-6 mb-2" }
const _hoisted_25 = { class: "p-text text-gray" }
const _hoisted_26 = { class: "text-end" }
const _hoisted_27 = { class: "text-gray p-text mb-2" }
const _hoisted_28 = { class: "text-gray p-text" }
const _hoisted_29 = { key: 1 }

import { onMounted, ref } from "vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'SaveAsBeneficiary',
  emits: ['selectBeneficiary'],
  setup(__props, { emit: __emit }) {

const store = useStore();
const loadInfo = ref(false);
const loading = ref(false);
const beneficiaryList: any = ref([]);
const emit = __emit;

const getBeneficiary = () => {
  store.commit("setLoader", true);
  store.dispatch("get", `/beneficiary`).then((resp) => {
    store.commit("setLoader", false);
    beneficiaryList.value = resp.data.data;
    loadInfo.value = true;
  }).catch((err) => {
    store.commit("setLoader", false);
  })
};

const selectBeneficiary = (beneficiary : any) => {
  emit('selectBeneficiary', beneficiary );
 
};

onMounted(() => {
  if (store.state.user) {
    getBeneficiary();
    
  }
});

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", _hoisted_4, [
            _createVNode(_component_Iconify, { icon: "ep:back" })
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("span", null, "Back", -1))
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("h5", { id: "offcanvasRightLabel" }, "Beneficiaries", -1))
      ]),
      (loadInfo.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (beneficiaryList.value.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("form", {
                      onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (getBeneficiary()), ["prevent"]))
                    }, [
                      _cache[6] || (_cache[6] = _createElementVNode("input", {
                        type: "search",
                        class: "input-field form-control ps-5",
                        placeholder: "Search history"
                      }, null, -1)),
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_Iconify, {
                          icon: "iconamoon:search-thin",
                          onSubmit: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (getBeneficiary()), ["prevent"]))
                        })
                      ])
                    ], 32)
                  ]),
                  _cache[8] || (_cache[8] = _createElementVNode("p", { class: "p-3 text-gray" }, "Select a beneficiary", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(beneficiaryList.value, (beneficiary, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "each-benefit"
                    }, [
                      _createElementVNode("div", {
                        onClick: ($event: any) => (selectBeneficiary(beneficiary)),
                        class: "d-flex p-3 align-items-center justify-content-start"
                      }, [
                        _cache[7] || (_cache[7] = _createElementVNode("div", {
                          class: "bg-primary rounded-circle me-3",
                          style: {"height":"10px","width":"10px"}
                        }, null, -1)),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("div", null, [
                            _createElementVNode("p", _hoisted_11, _toDisplayString(beneficiary.meter_no), 1),
                            _createElementVNode("p", _hoisted_12, _toDisplayString(beneficiary.provider), 1)
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("p", _hoisted_14, _toDisplayString(beneficiary.name), 1),
                            _createElementVNode("p", _hoisted_15, _toDisplayString(beneficiary.meter_type), 1)
                          ])
                        ])
                      ], 8, _hoisted_9)
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_16, _cache[9] || (_cache[9] = [
                  _createElementVNode("div", { class: "d-flex justify-content-center mt-5" }, " You have no Beneficiaries yet ", -1)
                ])))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_17, [
      _cache[14] || (_cache[14] = _createElementVNode("div", { class: "offcanvas-header" }, [
        _createElementVNode("h5", {
          class: "offcanvas-title",
          id: "offcanvasBottomLabel"
        }, " Beneficiaries "),
        _createElementVNode("button", {
          type: "button",
          class: "btn-close text-reset",
          "data-bs-dismiss": "offcanvas",
          "aria-label": "Close"
        })
      ], -1)),
      (loadInfo.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            (beneficiaryList.value.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("form", {
                      onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (getBeneficiary()), ["prevent"]))
                    }, [
                      _cache[10] || (_cache[10] = _createElementVNode("input", {
                        type: "search",
                        class: "input-field form-control ps-5",
                        placeholder: "Search history"
                      }, null, -1)),
                      _createElementVNode("div", _hoisted_21, [
                        _createVNode(_component_Iconify, {
                          icon: "iconamoon:search-thin",
                          onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (getBeneficiary()), ["prevent"]))
                        })
                      ])
                    ], 32)
                  ]),
                  _cache[12] || (_cache[12] = _createElementVNode("p", { class: "p-3 text-gray" }, "Select a beneficiary", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(beneficiaryList.value, (beneficiary, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "each-benefit"
                    }, [
                      _createElementVNode("div", {
                        onClick: ($event: any) => (selectBeneficiary(beneficiary)),
                        class: "d-flex p-3 align-items-center justify-content-start"
                      }, [
                        _cache[11] || (_cache[11] = _createElementVNode("div", {
                          class: "bg-primary rounded-circle me-3",
                          style: {"height":"10px","width":"10px"}
                        }, null, -1)),
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("div", null, [
                            _createElementVNode("p", _hoisted_24, _toDisplayString(beneficiary.meter_no), 1),
                            _createElementVNode("p", _hoisted_25, _toDisplayString(beneficiary.provider), 1)
                          ]),
                          _createElementVNode("div", _hoisted_26, [
                            _createElementVNode("p", _hoisted_27, _toDisplayString(beneficiary.name), 1),
                            _createElementVNode("p", _hoisted_28, _toDisplayString(beneficiary.meter_type), 1)
                          ])
                        ])
                      ], 8, _hoisted_22)
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_29, _cache[13] || (_cache[13] = [
                  _createElementVNode("div", { class: "d-flex justify-content-center mt-5" }, " You have no Beneficiaries yet ", -1)
                ])))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}
}

})


<template>
  <div class="page-card">
    <div class="page-content">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="track-image">
            <img src="@/assets/images/solar-roof.jpg" alt="" />
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="track-text">
            <p class="fs-14">
              Understanding your energy habits is key to maximizing the benefits
              of solar power. By tracking your solar production and energy
              consumption, you can optimize your system's efficiency and manage
              your overall energy use more effectively.
            </p>
        <br>
            <p class="fs-14">
              Please complete the questionnaire in the energy audit section to
              design the right solar system to increase your power reliability
              and reduce your energy costs, or ensure your existing solar and
              storage system operates at peak performance. This will generate a
              report providing valuable insights to help you make informed
              decisions.
            </p>
            <router-link to="/energy-audit">
              <div
                class="energy-btn px-2 mt-2 d-flex rounded align-items-center justify-content-between border-primary-light"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <Iconify
                    icon="ion:flash-outline"
                    style="color: #ff6600; font-size: 24px"
                  />
                  <p class="text-primary fs-6 ms-3">Energy Audit</p>
                </div>
                <div class="next-icon">
                  <Iconify
                    icon="ep:arrow-right"
                    style="color: #ff6600; font-size: 22px"
                  />
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

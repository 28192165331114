<script lang="ts" setup>
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
const store = useStore();
const router = useRouter();
const route = useRoute();
const toBuySolar = () => {
  localStorage.setItem("returningUser", "true");
  router.push({ name: "Solar" });
};
const toBuyElectricity = () => {
  localStorage.setItem("returningUser", "true");
  router.push({ name: "Home" });
};
</script>

<template>
   <nav>
      <div class="navb-wrapper border-bottom">
        <div class="navb-content">
          <router-link to="/landing-page">
          <div class="logo">
            
            <img src="@/assets/images/logo.svg" alt="" />
          </div>
        </router-link>
          <div v-if="route.name == 'Policy'|| route.name == 'contact' ? false : true" class="menu l-screen">
            <ul class="items p-0 m-0 d-flex align-items-center">
              <li class="item">
                <a
                  href="#home"
                  class="link d-flex align-items-center text-dark"
                >
                  <span class="m-text">Home</span>
                </a>
              </li>
              <li class="item">
                <a
                  href="#about"
                  class="link d-flex align-items-center text-dark"
                >
                  <span class="m-text">About Us </span>
                </a>
              </li>
              <li class="item">
                <a
                  href="#whatweoffer"
                  class="link d-flex align-items-center text-dark"
                >
                  <span class="m-text">What We Offer </span>
                </a>
              </li>
              <li class="item">
                <a
                  href="#whychooseus"
                  class="link d-flex align-items-center text-dark"
                >
                  <span class="m-text">Why Choose Us </span>
                </a>
              </li>
            </ul>
          </div>

          <div v-if="route.name == 'Policy'|| route.name == 'contact' ? false : true" class="m-screen">
            <div class="align-items-center d-flex dropdown">
              <div
                class="ms-2 mt-2 text-dark text-center"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <Iconify
                  style="font-size: 28px"
                  icon="solar:hamburger-menu-linear"
                ></Iconify>
              </div>

              <ul
                class="dropdown-menu text-start border-0 mt-3"
                style="width: 100vw; background-color: rgb(255, 239, 239)"
              >
                <li><a class="dropdown-item" href="#home">Home</a></li>
                <li><a class="dropdown-item" href="#about"> About Us</a></li>
                <li>
                  <a class="dropdown-item" href="#whatweoffer">What We Offer</a>
                </li>
                <li>
                  <a class="dropdown-item" href="#whychooseus">Why Choose Us</a>
                </li>
                <li class="buy-solar-btn">
                  <div  @click.prevent="toBuySolar">
                    <button class="nav-b btn-dark my-1 mx-3">Buy Solar</button>
                  </div>
                </li>
                <li class="buy-solar-btn">
                  <div  @click.prevent="toBuyElectricity">
                    <button class="nav-b btn-primary my-1 mx-3">
                      Buy Electricity
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="nav-buttons">
            <div class="d-flex align-items-center justify-content-center">
              <div  @click.prevent="toBuySolar">
                <button class="nav-b btn-dark me-3">Buy Solar</button>
              </div>
              <div @click.prevent="toBuyElectricity">
                <button class="nav-b btn-primary">Buy Electricity</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
</template>
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/logo.svg'


const _hoisted_1 = { class: "home-screen d-flex align-items-center justify-content-center" }

import { ref, onMounted } from "vue";

import {useRouter} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'home',
  setup(__props) {

const router = useRouter();

onMounted(() => {
  const isReturningUser = localStorage.getItem('returningUser');
  if(isReturningUser){
    setTimeout(() => {
    router.push({ name: 'Buy Electricity' });
  }, 1800); 
    
  }
   else if(!isReturningUser){
    router.push('/landing-page');
  }

});
  



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "home-logo" }, [
      _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      })
    ], -1)
  ])))
}
}

})
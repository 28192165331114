import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/images/logo.svg'


const _hoisted_1 = { class: "navb-wrapper border-bottom" }
const _hoisted_2 = { class: "navb-content" }
const _hoisted_3 = {
  key: 0,
  class: "menu l-screen"
}
const _hoisted_4 = {
  key: 1,
  class: "m-screen"
}
const _hoisted_5 = { class: "align-items-center d-flex dropdown" }
const _hoisted_6 = {
  class: "ms-2 mt-2 text-dark text-center",
  href: "#",
  role: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_7 = {
  class: "dropdown-menu text-start border-0 mt-3",
  style: {"width":"100vw","background-color":"rgb(255, 239, 239)"}
}
const _hoisted_8 = { class: "buy-solar-btn" }
const _hoisted_9 = { class: "buy-solar-btn" }
const _hoisted_10 = { class: "nav-buttons" }
const _hoisted_11 = { class: "d-flex align-items-center justify-content-center" }

import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'LandingPageNav',
  setup(__props) {

const store = useStore();
const router = useRouter();
const route = useRoute();
const toBuySolar = () => {
  localStorage.setItem("returningUser", "true");
  router.push({ name: "Solar" });
};
const toBuyElectricity = () => {
  localStorage.setItem("returningUser", "true");
  router.push({ name: "Home" });
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Iconify = _resolveComponent("Iconify")!

  return (_openBlock(), _createElementBlock("nav", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, { to: "/landing-page" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("div", { class: "logo" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: ""
              })
            ], -1)
          ])),
          _: 1
        }),
        (_unref(route).name == 'Policy'|| _unref(route).name == 'contact' ? false : true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[1] || (_cache[1] = [
              _createStaticVNode("<ul class=\"items p-0 m-0 d-flex align-items-center\"><li class=\"item\"><a href=\"#home\" class=\"link d-flex align-items-center text-dark\"><span class=\"m-text\">Home</span></a></li><li class=\"item\"><a href=\"#about\" class=\"link d-flex align-items-center text-dark\"><span class=\"m-text\">About Us </span></a></li><li class=\"item\"><a href=\"#whatweoffer\" class=\"link d-flex align-items-center text-dark\"><span class=\"m-text\">What We Offer </span></a></li><li class=\"item\"><a href=\"#whychooseus\" class=\"link d-flex align-items-center text-dark\"><span class=\"m-text\">Why Choose Us </span></a></li></ul>", 1)
            ])))
          : _createCommentVNode("", true),
        (_unref(route).name == 'Policy'|| _unref(route).name == 'contact' ? false : true)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_Iconify, {
                    style: {"font-size":"28px"},
                    icon: "solar:hamburger-menu-linear"
                  })
                ]),
                _createElementVNode("ul", _hoisted_7, [
                  _cache[4] || (_cache[4] = _createElementVNode("li", null, [
                    _createElementVNode("a", {
                      class: "dropdown-item",
                      href: "#home"
                    }, "Home")
                  ], -1)),
                  _cache[5] || (_cache[5] = _createElementVNode("li", null, [
                    _createElementVNode("a", {
                      class: "dropdown-item",
                      href: "#about"
                    }, " About Us")
                  ], -1)),
                  _cache[6] || (_cache[6] = _createElementVNode("li", null, [
                    _createElementVNode("a", {
                      class: "dropdown-item",
                      href: "#whatweoffer"
                    }, "What We Offer")
                  ], -1)),
                  _cache[7] || (_cache[7] = _createElementVNode("li", null, [
                    _createElementVNode("a", {
                      class: "dropdown-item",
                      href: "#whychooseus"
                    }, "Why Choose Us")
                  ], -1)),
                  _createElementVNode("li", _hoisted_8, [
                    _createElementVNode("div", {
                      onClick: _withModifiers(toBuySolar, ["prevent"])
                    }, _cache[2] || (_cache[2] = [
                      _createElementVNode("button", { class: "nav-b btn-dark my-1 mx-3" }, "Buy Solar", -1)
                    ]))
                  ]),
                  _createElementVNode("li", _hoisted_9, [
                    _createElementVNode("div", {
                      onClick: _withModifiers(toBuyElectricity, ["prevent"])
                    }, _cache[3] || (_cache[3] = [
                      _createElementVNode("button", { class: "nav-b btn-primary my-1 mx-3" }, " Buy Electricity ", -1)
                    ]))
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", {
              onClick: _withModifiers(toBuySolar, ["prevent"])
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("button", { class: "nav-b btn-dark me-3" }, "Buy Solar", -1)
            ])),
            _createElementVNode("div", {
              onClick: _withModifiers(toBuyElectricity, ["prevent"])
            }, _cache[9] || (_cache[9] = [
              _createElementVNode("button", { class: "nav-b btn-primary" }, "Buy Electricity", -1)
            ]))
          ])
        ])
      ])
    ])
  ]))
}
}

})
<template>

    <div class="layout-wrapper-desk">
      <div class="layout-page">
        <sidebar></sidebar>
        <loader v-if="loading"></loader>
        <div class="nav-page">
          <navbar></navbar>
         
          <div class="hero-page">
            <router-view></router-view>
          </div>
        </div>
      </div>
      <div >
       <layoutdown></layoutdown>
       <BottomNavMob></BottomNavMob>
      </div>
    </div>



</template>

<script setup lang="ts">

import loader from "@/components/Dashboard/loader.vue";
import sidebar from "@/components/Dashboard/Sidebar.vue";
import navbar from "@/components/Dashboard/Navbar.vue";
import layoutdown from "@/components/Dashboard/Footer.vue";
import BottomNavMob from "@/components/Dashboard/BottomNav.vue";
import { computed } from "vue";
import { useStore } from "vuex";
import { onBeforeMount } from "vue";

  const store = useStore();
 
  const loading = computed(() =>{
  return store.state.loader;
  })
  
onBeforeMount(()=>{
 store.commit("getUser");
  
});








 
</script>

<style lang="scss" scoped>
.nav-page {
  min-height: 100vh;
  width: 100%;
}
</style>

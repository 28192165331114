<template>
  <div
    class="modal fade"
    id="exampleModal"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-4">
        <div
          class="modal-header border-0 p-0 d-flex justify-content-between align-items-center"
        >
          <div class="">
            <h1 class="modal-title fs-5" id="exampleModalToggleLabel">
              Cancel Order?
            </h1>
          </div>
          <button
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            style="
              height: 27px;
              width: 27px;
              border-radius: 50%;
              background-color: black;
              color: white;
            "
          >
            <Iconify icon="iconamoon:close-duotone" style="font-size: 18px" />
          </button>
        </div>
        <p class="text-gray mt-3">
          Are you sure you want to cancel this order?
        </p>

        <div class="desktop-screen">
          <div
            class="d-flex mt-5 justify-content-center align-items-center gap-4"
          >
            <div class="d-grid">
              <router-link to="/buy-energy-app">
                <button class="btn-normal px-5 text-primary">
                  Yes, Cancel
                </button>
              </router-link>
            </div>

            <div class="d-grid">
              <button
                @click.prevent="continuePurchase()"
                class="btn-primary px-5 text-white"
              >
                No, Continue
              </button>
            </div>
          </div>
        </div>
        <div class="mobile-screen">
          <div
            class="d-flex mt-5 justify-content-center align-items-center gap-4"
          >
            <div class="d-grid">
              <router-link to="/buy-energy-app">
                <button class="btn-normal px-3 text-primary">
                  Yes, Cancel
                </button>
              </router-link>
            </div>

            <div class="d-grid">
              <button
                @click.prevent="continuePurchase()"
                class="btn-primary px-3 text-white"
              >
                No, Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

const emit = defineEmits(["purchaseHistory"]);

const continuePurchase = () => {
  emit("purchaseHistory");
};
</script>
